import { useContext } from 'react'
import { useFieldArray } from 'react-hook-form'
import { SharedSubtaskContext } from '../UseSharedSubtaskContext'
import { SubtaskFormContents } from './SubtaskFormContents'

export const SubtaskForm = ({ uuid }: { uuid: string }) => {
  const { setSubTasksToCreate, subtasksToCreate, taskControl, setStepTextFieldValues, setExpandedUuid } =
    useContext(SharedSubtaskContext)

  const myindex = subtasksToCreate.findIndex(s => s.uuid === uuid)
  const { remove } = useFieldArray({
    control: taskControl!.control,
    name: 'subtasks',
  })

  const deleteMe = () => {
    // Remove the subtask from the form array
    remove(myindex)
    // Remove the subtask from subtasksToCreate based on uuid
    setSubTasksToCreate!(prev => {
      const newSubTask = prev.filter(s => s.uuid !== uuid)

      // Update expandedUuid to the uuid of the first remaining subtask
      newSubTask.length > 0 ? setExpandedUuid(newSubTask[0].uuid) : setExpandedUuid(null)
      return newSubTask
    })
    // Update stepTextFieldValues
    setStepTextFieldValues(prev => {
      const newValues = { ...prev }
      // Remove the value for the deleted subtask
      delete newValues[uuid]
      // Adjust the indices for the remaining subtasks
      Object.keys(newValues).forEach((key, index) => {
        if (index > myindex) {
          newValues[index - 1] = newValues[index]
          delete newValues[index]
        }
      })
      return newValues
    })
  }

  return <SubtaskFormContents i={myindex} uuid={uuid} onDelete={deleteMe} />
}
