import { CreateTaskForm, PartialCreateSubtaskRequest } from 'api/dtos'
import { SetState } from 'common/types/types'
import React, { createContext, useContext } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { SelectedReinforcement } from 'store/reinforcements-slice'

export interface SharedSubtaskContextType {
  setReinforcements: (reinforcements: SelectedReinforcement[]) => void
  subtasksToCreate: PartialCreateSubtaskRequest[]
  setSubTasksToCreate: SetState<PartialCreateSubtaskRequest[]> | undefined
  taskControl: UseFormReturn<CreateTaskForm, any> | undefined
  setOpen: SetState<boolean> | undefined
  onSubmit: (data: CreateTaskForm) => void
  setReinforcementOpen: SetState<boolean> | undefined
  reinforcementOpen: boolean
  topTitle: string
  setTopTitle: SetState<string> | undefined
  setExpandedUuid: SetState<string | null>
  stepTextFieldValues: { [key: string]: string }
  setStepTextFieldValues: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>
  setActiveSubtaskUuid: React.Dispatch<React.SetStateAction<string | null>>
  activeSubtaskUuid: string | null
  validateAndSubmit: (e: React.FormEvent) => void
  expandedUuid: string | null
  resetForm: () => void
}

export const SharedSubtaskContext = createContext<SharedSubtaskContextType>({
  setReinforcements: () => {},
  subtasksToCreate: [],
  setSubTasksToCreate: () => {},
  taskControl: undefined,
  setOpen: undefined,
  onSubmit: () => {},
  setReinforcementOpen: undefined,
  reinforcementOpen: false,
  topTitle: 'משימה חדשה',
  setTopTitle: undefined,
  setExpandedUuid: () => {},
  stepTextFieldValues: {},
  setStepTextFieldValues: () => {},
  setActiveSubtaskUuid: () => {},
  activeSubtaskUuid: null,
  validateAndSubmit: () => {},
  expandedUuid: null,
  resetForm: () => {},
})

export const useSharedSubtaskContext = () => {
  const context = useContext(SharedSubtaskContext)
  if (context === undefined) {
    throw new Error('useSharedSubtaskContext must be used within a SharedSubtaskProvider')
  }
  return context
}

export const SharedSubtaskProvider: React.FC<SharedSubtaskContextType & { children: React.ReactNode }> = props => {
  return <SharedSubtaskContext.Provider value={props}>{props.children}</SharedSubtaskContext.Provider>
}
