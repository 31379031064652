import { Box, Button, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import OtpInput from 'react18-input-otp'
import { paths } from '../../routes'
import { otpValidationAsync, refreshOTPAsync, selectUserState, useAppDispatch, useAppSelector } from '../../store'

export const OnboardingOTP = () => {
  const { t } = useTranslation()
  const { user } = useAppSelector(selectUserState)
  const [otp, setOtp] = useState('')
  const [buttonTimeout, setButtonTimeout] = useState(true)
  const [timeToResend, setTimeToResend] = useState(20)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (user.otpValidated === true) {
      // go to next page if otp already validated
      navigate(paths.QUESTIONNAIRE_EFFICIENCY)
    }
    let timer: ReturnType<typeof setTimeout>
    if (timeToResend > 0) {
      timer = setInterval(() => {
        setTimeToResend(timeToResend - 1)
      }, 1000)
    } else {
      setButtonTimeout(false)
    }
    return () => clearInterval(timer)
  }, [timeToResend, navigate, user.otpValidated])

  async function otpValidate(otpSent: string) {
    const isValid = await dispatch(await otpValidationAsync({ otp: otpSent, userId: user.id }))

    return isValid.meta.requestStatus !== 'rejected'
  }

  const onboardingHandleClick = async (otpSent: string) => {
    // this means we are in the first page
    const otpValidity = await otpValidate(otpSent)
    if (otpValidity) {
      // OTP is valid - move to next page
      navigate(paths.QUESTIONNAIRE_EFFICIENCY)
    } else {
      alert(t('inviteUsers.invalidOTP'))
      setOtp('')
      return
    }
  }

  const handleOtpChange = (newOtp: string) => {
    setOtp(newOtp)
    if (newOtp.length === 4) {
      onboardingHandleClick(newOtp)
    }
  }

  const isMobile = useAppSelector(state => state.layout.isMobileView)

  return (
    <Box
      sx={{
        direction: 'rtl',
        maxWidth: isMobile ? '30em' : '70em',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        padding: '3em 2em 3em 2em',
      }}
    >
      <Box
        sx={{
          direction: 'rtl',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'baseline',
          mb: '2.5em',
        }}
      >
        <Typography variant={'h5'} style={{ fontWeight: 400, fontSize: '1.2em' }}>
          {t('onboarding.otp.beforeStarting')}
        </Typography>
      </Box>

      <Box
        sx={{
          direction: 'ltr',
          alignSelf: 'center',
          width: '100%',
        }}
      >
        <Typography
          mb={3}
          variant={'h5'}
          style={{ fontWeight: 500, fontSize: '1.4em', textAlign: 'right', width: '100%' }}
        >
          ?מה הקוד שקיבלת בוואטסאפ
        </Typography>
        <OtpInput
          inputStyle={{
            width: '15vw',
            height: '7vh',
            borderRadius: '0.5em',
            fontSize: '1.2em',
            fontWeight: 600,
            fontFamily: 'varela round',
            borderColor: 'black',
            color: '#000000',
          }}
          containerStyle={{
            justifyContent: 'center',
          }}
          numInputs={4}
          isDisabled={false}
          onChange={handleOtpChange}
          value={otp}
          separator={
            <div
              style={{
                margin: '0 1em 0 1em',
                borderRadius: '50%',
                backgroundColor: '#D9D9D9',
              }}
            />
          }
          isInputNum={true}
          shouldAutoFocus
        />
      </Box>
      <Box
        sx={{ flexGrow: 1, display: 'flex', flexDirection: 'row', alignItems: 'baseline', width: '100%', mt: '2.5em' }}
      >
        <Typography variant={'h5'} style={{ fontWeight: 400, fontSize: '1em', textAlign: 'right' }}>
          לא קיבלתי את הקוד.
        </Typography>
        <Button
          sx={{ fontWeight: 400, fontSize: '1em', color: '#6E1D93', padding: 0, mr: '0.25em' }}
          onClick={() => {
            dispatch(refreshOTPAsync(user.id))
            setTimeToResend(20)
            setButtonTimeout(true)
          }}
          disabled={buttonTimeout}
        >
          <u>{buttonTimeout ? `שלחו שוב (${timeToResend})` : 'שלחו שוב'}</u>
        </Button>
      </Box>
      <Button
        sx={{ height: '4em', width: '100%', borderRadius: '0.5em' }}
        variant={'contained'}
        onClick={() => onboardingHandleClick(otp)}
      >
        <Typography variant={'h6'} align={'center'} style={{ fontWeight: 400 }}>
          {t('onboarding.otp.continue')}
        </Typography>
      </Button>
    </Box>
  )
}
