import { Grid, ToggleButton, ToggleButtonGroup } from '@mui/material'
import MuiButton from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import { theme } from 'theme'

export const CenterAbsoluteStyledGridTop = styled(Grid)({
  position: 'absolute',
  top: '45%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
})
export const CenterAbsoluteStyledGridBottom = styled(Grid)({
  position: 'absolute',
  top: '92%',
  '@media (max-height: 700px)': {
    top: '95%',
  },
  left: '50%',
  transform: 'translate(-50%, -50%)',
})
export const CenterAbsoluteStyledGridFooter = styled(Grid)({
  position: 'absolute',
  top: '92%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
})
export const QuestionnaireButton = styled(MuiButton)(props => ({
  backgroundColor: theme.palette.primary?.main,
  color: 'black',
  boxShadow: '0px 0px 9px rgba(45, 45, 45, 0.25)',
  borderRadius: '0.6875em',
  width: '80vw',
  height: '6vh',
  '&:hover': {
    background: theme.palette.primary?.main,
  },
}))
export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(props => ({
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
  background: '#FAFAFA',
  '&.MuiToggleButtonGroup-root': {
    borderRadius: '18px',
  },
}))
export const StyledToggleButton = styled(ToggleButton)({
  background: '#FAFAFA',
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.15)',
  '&.MuiToggleButton-root': {
    borderRadius: '18px',
    height: '1em',
  },
})
