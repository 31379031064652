import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isMobileView: window.innerWidth < 768,
}

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setMobileView: (state, action) => {
      state.isMobileView = action.payload
    },
  },
})

export const { setMobileView } = layoutSlice.actions
export default layoutSlice.reducer
