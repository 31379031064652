import { CredentialResponse, GoogleLogin } from '@react-oauth/google'
import { useCallback } from 'react'
import { useAppDispatch } from 'store'
import { googleLoginAsync } from 'store/auth-slice'

export const GoogleLoginButtonPage = () => {
  const dispatch = useAppDispatch()
  const onGoogleIdentityLogin = useCallback(
    (credentialResponse: CredentialResponse) => {
      dispatch(googleLoginAsync({ idToken: credentialResponse.credential }))
    },
    [dispatch],
  )
  return (

    <GoogleLogin
      onSuccess={onGoogleIdentityLogin}
      onError={() => {
        console.log('Login to Google Failed')
      }}
      size={'large'}
      useOneTap
    />
  )
}
