import { Button, Grid, TextField } from '@mui/material'
import { RequestStatus } from 'common/types'
import { useContext, useRef, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { selectTaskListState, useAppSelector } from '../../../store'
import { SharedSubtaskContext } from '../UseSharedSubtaskContext'

export const TitleSection = ({ isFormValid, onContinue }: { isFormValid: boolean; onContinue: () => void }) => {
  const { status } = useAppSelector(selectTaskListState)
  const { t } = useTranslation()
  const { taskControl, setTopTitle } = useContext(SharedSubtaskContext)
  const [titleValue, setTitleValue] = useState('')
  const buttonRef = useRef<HTMLButtonElement>(null)

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      buttonRef.current?.click()
    }
  }

  return (
    <Grid
      container
      direction="column"
      textAlign={'center'}
      spacing={2}
      sx={{ padding: '0 1em', height: '92dvh', flexDirection: 'column', justifyContent: 'space-between' }}
    >
      <Grid item xs={9} textAlign={'right'}>
        <Controller
          name="title"
          control={taskControl!.control}
          defaultValue=""
          render={({ field, fieldState: { error } }) => (
            <TextField //TODO: remove duplicates
              placeholder="כותרת"
              variant="outlined"
              sx={{ direction: 'rtl' }}
              {...field}
              required
              fullWidth
              autoComplete="title"
              autoFocus
              margin="normal"
              error={!!error}
              helperText={error ? error.message : null}
              type="text"
              InputProps={{
                style: { fontSize: '1.3em' },
              }}
              onKeyDown={handleKeyDown}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                field.onChange(e)
                setTitleValue(e.target.value)
              }}
            />
          )}
          rules={{ required: { value: true, message: t('auth.nameIsRequired') } }}
        />
      </Grid>
      <Grid item xs={3}>
        <Button
          ref={buttonRef}
          variant="contained"
          sx={{
            position: 'relative',
            top: '50%',
            backgroundColor: '#FFC888',
            boxShadow: '0px 0px 3px rgba(45, 45, 45, 0.25)',
            borderRadius: '0.4em',
            width: '100%',
          }}
          disabled={!titleValue || status === RequestStatus.Loading}
          onClick={() => {
            onContinue()
            setTopTitle!(titleValue)
            taskControl?.reset({ title: '' })
          }}
        >
          {'הוספה'}
        </Button>
      </Grid>
    </Grid>
  )
}
