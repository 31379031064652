import { AlertColor, Grid, Typography, useTheme } from '@mui/material'
import { DeleteSubtaskRequest, OrganizedTask, Status, Subtask, Task, TaskAlert } from 'api/dtos'
import { BottomDrawer } from 'components/BottomDrawer'
import MentorNameWhatsApp from 'components/MentorNameWhatsApp'
import { ALERT_MESSAGE_TIME, ViewMode } from 'pages/Home/MobileHome/MobileHome'
import { TaskForm } from 'pages/TasksPage/TaskCreation/TaskForm'
import { createContext, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  addTaskAlert,
  clearTaskAlerts,
  deleteSubtaskAsync,
  listTaskAlerts,
  removeTaskAlert,
  selectUserState,
} from 'store'
import { listReinforcementsAsync } from 'store/reinforcements-slice'
import { genUniqueId } from 'utils/utils'
import noTasksArrow from '../../icons/no_tasks_arrow.png'
import { listTasksAsync, selectTaskListState, useAppDispatch, useAppSelector } from '../../store'
import AppSpeedDial from './SpeedDial/AppSpeedDial'
import DeleteDialogPopup from './Task/DeleteDialogPopup'
import { SubtaskSection, difficultyToValue } from './Task/SubtaskSection'
import { Task as TaskComponent } from './Task/Task'

const formatDate = (dateInput: Date | string | number) => {
  const date = new Date(dateInput)
  return date.toLocaleDateString('en-GB', { year: '2-digit', month: '2-digit', day: '2-digit' })
}

export const taskPageFormatDate = (date: Date) => {
  const now = new Date()
  now.setHours(0, 0, 0, 0)
  const tomorrow = new Date(now.getTime())
  tomorrow.setDate(tomorrow.getDate() + 1)
  const nextWeek = new Date(now.getTime())
  nextWeek.setDate(nextWeek.getDate() + 7)

  const dateObj = new Date(date.getTime())
  dateObj.setHours(0, 0, 0, 0)

  if (dateObj < now) {
    return 'באיחור'
  } else if (dateObj.toDateString() === now.toDateString()) {
    return 'היום'
  } else if (dateObj.toDateString() === tomorrow.toDateString()) {
    return 'מחר'
  } else if (dateObj.getTime() === nextWeek.getTime()) {
    return 'בעוד שבוע'
  } else if (dateObj < nextWeek) {
    const diffTime = Math.abs(dateObj.getTime() - now.getTime())
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    return `בעוד ${diffDays} ימים`
  } else {
    return formatDate(dateObj)
  }
}

const getTaskProgress = (task: Task) => {
  const { subtasks } = task
  const progressReduceFunction = (prev: number, curr: Subtask) => prev + difficultyToValue[curr.difficulty]
  const progressTotal = subtasks.reduce(progressReduceFunction, 0)
  const progressDone = subtasks.filter(subtask => subtask.status === Status.DONE).reduce(progressReduceFunction, 0)
  return (progressDone / progressTotal) * 100
}

export const taskOrganizer = (tasks: Task[] | undefined, viewMode: ViewMode): Map<Date, OrganizedTask[]> => {
  const organizedTasks = new Map<Date, OrganizedTask[]>()

  if (!tasks) return organizedTasks

  tasks.forEach(task => {
    if (task.deleted) return

    const progress = getTaskProgress(task)

    if (viewMode === ViewMode.Tasks) {
      // Filter out tasks where all subtasks are done
      if (!task.subtasks.some(subtask => subtask.status !== Status.DONE)) return

      // Find the earliest deadline of the not-done subtasks or use a default date
      let key = task.subtasks.find(subtask => subtask.status !== Status.DONE)?.deadline
        ? new Date(task.subtasks.find(subtask => subtask.status !== Status.DONE)!.deadline)
        : new Date()
      key.setHours(0, 0, 0, 0)

      // Get existing tasks for this key, or initialize if none
      const existingTasks = organizedTasks.get(key) || []

      // Add this task to the map, including all subtasks
      existingTasks.push({
        ...task,
        progress,
        subtasks: task.subtasks, // Include all subtasks, regardless of their status
      })
      organizedTasks.set(key, existingTasks)
    } else {
      // For each subtask that is not done, add the task under its deadline
      task.subtasks.forEach(subtask => {
        if (subtask.status === Status.DONE) return

        const deadline = new Date(subtask.deadline)
        deadline.setHours(0, 0, 0, 0)

        const existing = organizedTasks.get(deadline) || []
        existing.push({ ...task, progress, subtasks: [subtask] })
        organizedTasks.set(deadline, existing)
      })
    }
  })

  // Sort the map by date for Date view mode
  if (viewMode === ViewMode.Date) {
    return new Map([...organizedTasks.entries()].sort((a, b) => a[0].getTime() - b[0].getTime()))
  }
  return organizedTasks
}

export const TaskAlertContext = createContext<{
  alertSuccess: (message: string) => void
  alertDeleteTask: (message: string, taskId: number) => void
  openBottomDrawer: boolean
  setOpenBottomDrawer: (open: boolean) => void
}>({
  alertSuccess: () => null,
  alertDeleteTask: () => null,
  openBottomDrawer: false,
  setOpenBottomDrawer: () => null,
})

export const TasksPage = ({ viewMode }: { viewMode: ViewMode }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { tasks } = useAppSelector(selectTaskListState)
  const { user, mentors } = useAppSelector(selectUserState)
  const myMentor = mentors?.find(value => value.id === user.mentorId)
  const [openBottomDrawer, setOpenBottomDrawer] = useState<boolean>(false)

  const organizedTasks: Map<Date, OrganizedTask[]> = taskOrganizer(tasks, viewMode)

  const addAlert = useCallback(
    (alert: TaskAlert) => {
      dispatch(addTaskAlert(alert))
    },
    [dispatch],
  )
  const removeAlert = useCallback(
    (alert: TaskAlert) => {
      dispatch(removeTaskAlert(alert))
    },
    [dispatch],
  )

  useEffect(() => {
    dispatch(listTasksAsync(user.id))
    dispatch(listReinforcementsAsync(user.id))
    dispatch(listTaskAlerts())
    dispatch(clearTaskAlerts())
  }, [dispatch, user.id])

  const noTasksString = () => {
    const splitNoTasks = t('tasks.noTasks').split(' ')
    const coloredWord = <span style={{ color: theme.palette.primary.main }}>{splitNoTasks[1]}</span>
    return (
      <Typography variant="h6" sx={{ direction: 'rtl', fontWeight: 600 }}>
        {splitNoTasks[0]} {coloredWord} {splitNoTasks.slice(2).join(' ')}
      </Typography>
    )
  }
  const alert = async (severity: AlertColor, message: string, actionType?: string, actionPayload?: any) => {
    const uuid: string = genUniqueId()
    const newAlert = { severity, message, actionType, actionPayload, uuid }
    addAlert(newAlert)
    await new Promise(r => setTimeout(r, ALERT_MESSAGE_TIME))
    removeAlert(newAlert)
  }

  const alertSuccess = (message: string) => alert('success', message)
  const alertDeleteTask = (message: string, taskId: number) => alert('warning', message, 'delete-task', { taskId })
  const theme = useTheme()
  const isMobile = useAppSelector(state => state.layout.isMobileView)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [deleteData, setDeleteData] = useState<{ id: number; userId: number; type: 'task' | 'subtask' } | null>(null)

  const handleOpenDeleteDialog = (data: { id: number; userId: number; type: 'task' | 'subtask' }) => {
    setDeleteData(data)
    setIsDialogOpen(true)
  }

  const handleSubtaskDelete = (data: DeleteSubtaskRequest) =>
    dispatch(deleteSubtaskAsync({ id: data.id, userId: user.id })).then(() =>
      alertDeleteTask('תת משימה נמחקה בהצלחה', data.id),
    )

  const handleOpenSubtaskDeleteDialog = (subtaskId: number) => {
    handleOpenDeleteDialog({ id: subtaskId, userId: user.id, type: 'subtask' })
  }

  const confirmDelete = () => {
    if (deleteData) {
      handleSubtaskDelete(deleteData)
    }
    setIsDialogOpen(false)
  }

  return (
    <TaskAlertContext.Provider value={{ alertSuccess, alertDeleteTask, setOpenBottomDrawer, openBottomDrawer }}>
      <Grid
        container
        component="main"
        style={{
          maxWidth: '45em',
          margin: 'auto',
          paddingBottom: '15vh',
          overflowY: 'auto',
        }}
      >
        {organizedTasks &&
          Array.from(organizedTasks.keys()).map((d: Date, i: number) => (
            <Grid container key={i}>
              {viewMode === ViewMode.Date && (
                <Grid
                  item
                  xs={11}
                  style={{
                    textAlign: 'right',
                    paddingTop: '1em',
                    fontSize: '1.3em',
                    fontWeight: 500,
                    marginBottom: '0.5em',
                  }}
                >
                  {taskPageFormatDate(d)}
                </Grid>
              )}
              <Grid item xs={12}>
                {organizedTasks.get(d)!.map((t, i) => (
                  <Grid
                    item
                    key={i}
                    sx={{
                      padding: viewMode === ViewMode.Tasks ? '0em 1em 1.35em' : '0em',
                    }}
                  >
                    {viewMode === ViewMode.Date && (
                      <SubtaskSection
                        subtasks={t.subtasks}
                        onOpenDeleteDialog={handleOpenSubtaskDeleteDialog}
                        taskId={t.id}
                        userId={user.id}
                        viewMode={viewMode}
                        taskTitle={t.title}
                      />
                    )}
                    {viewMode === ViewMode.Tasks && <TaskComponent task={t} />}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))}
        {myMentor?.phoneNumber ? (
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 1100,
              backgroundColor: 'white',
              height: '10dvh',
              boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.1)',
            }}
          >
            <MentorNameWhatsApp isFixed />
            <AppSpeedDial setOpenBottomDrawer={setOpenBottomDrawer} />
          </Grid>
        ) : (
          <AppSpeedDial setOpenBottomDrawer={setOpenBottomDrawer} />
        )}
        <Grid item xs={12}>
          <BottomDrawer
            open={openBottomDrawer}
            height={100}
            handleClose={() => setOpenBottomDrawer(false)}
            Content={<TaskForm setOpen={setOpenBottomDrawer} />}
          />
        </Grid>
      </Grid>
      <div
        style={{
          position: 'fixed',
          bottom: isMobile ? 80 : 120,
          right: 60,
          zIndex: -1,
          opacity: '0.9',
        }}
      >
        <div>{organizedTasks.size === 0 ? noTasksString() : null}</div>
        <div>{organizedTasks.size === 0 ? <img height={275} alt={'arrow'} src={noTasksArrow} /> : null}</div>
      </div>
      <DeleteDialogPopup
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onConfirmDelete={confirmDelete}
        type={deleteData?.type ?? 'subtask'}
      />
    </TaskAlertContext.Provider>
  )
}
