import { Box, Button, Modal, TextField, Typography } from '@mui/material'
import MobileHeaderAdmin from 'pages/MentorHome/MobileHeaderAdmin'
import { Dispatch, SetStateAction, useCallback, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { inviteAsync, selectUserState, useAppDispatch, useAppSelector } from 'store'

interface AddNewMenteeProps {
  AddNewMenteeModalOpen: boolean
  setAddNewMenteeModal: Dispatch<SetStateAction<boolean>>
}

const AddNewMentee = (props: AddNewMenteeProps) => {
  const { handleSubmit, control, reset } = useForm<{ name: string; email: string; phone: string }>()
  const { t } = useTranslation()
  const { user } = useAppSelector(selectUserState)
  const dispatch = useAppDispatch()
  const buttonRef = useRef<HTMLButtonElement>(null)
  const isMobile = useAppSelector(state => state.layout.isMobileView)

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      buttonRef.current?.click()
    }
  }

  const onSubmit = useCallback(
    async (data: { name: string; email: string; phone: string }) => {
      const validationResult = await dispatch(
        inviteAsync({
          phoneNumber: data.phone,
          name: data.name,
          email: data.email.toLowerCase(),
          mentorId: user.id,
        }),
      )

      if (validationResult.meta.requestStatus === 'fulfilled') {
        props.setAddNewMenteeModal(false)
        reset()
        alert(t('inviteUsers.sentSuccess'))
      } else {
        alert(t('inviteUsers.sentFailure'))
      }
    },
    [dispatch, reset, t, user.id, props],
  )

  const textFieldStyles = {
    margin: '0.15em 0 1.25em 0',
    width: isMobile ? '100%' : '50%',
    '& .MuiOutlinedInput-root': {
      borderRadius: '0.5rem',
      height: isMobile ? '2.5rem' : '3.5rem',
    },
    FormHelperTextProps: {
      sx: { textAlign: 'right' },
    },
  }

  const modalStyle = {
    position: 'absolute',
    top: '0%',
    left: '0%',
    transform: 'translate(0%, 0%)',
    width: '100dvw',
    height: '100dvh',
    bgcolor: 'background.paper',
    boxShadow: 24,
  }

  const closeModal = () => {
    props.setAddNewMenteeModal(false)
  }

  const formContent = (
    <>
      {isMobile ? <MobileHeaderAdmin title={t('notifications.addNewMentee')} onClose={closeModal} /> : null}
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          padding: isMobile ? '1rem' : '5em',
          marginTop: isMobile ? 0 : '2em',
          marginRight: isMobile ? 0 : '15%',
          width: isMobile ? '100%' : '80dvw',
          height: isMobile ? '85dvh' : '80dvh',
          flexGrow: 1,
          direction: 'rtl',
        }}
        noValidate
      >
        <Box sx={{ flexGrow: isMobile ? 1 : 0, width: '100%' }}>
          <Typography variant="h5" color={'#666666'} fontSize={'1em'} alignSelf={'flex-start'}>
            שם מלא:
          </Typography>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                margin="normal"
                required
                fullWidth
                autoFocus
                autoComplete="name"
                error={!!error}
                helperText={error ? error.message : null}
                sx={textFieldStyles}
              />
            )}
            rules={{ required: { value: true, message: t('auth.fullNameIsRequired') } }}
          />
          <Typography variant="h5" color={'#666666'} fontSize={'1em'} alignSelf={'flex-start'}>
            מייל:
          </Typography>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                required
                fullWidth
                autoComplete="email"
                margin="normal"
                error={!!error}
                helperText={error ? error.message : null}
                type="email"
                onKeyDown={handleKeyDown}
                sx={textFieldStyles}
              />
            )}
            rules={{
              required: { value: true, message: t('auth.emailIsRequired') },
              pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: t('auth.emailIsInvalid') },
            }}
          />
          <Typography variant="h5" color={'#666666'} fontSize={'1em'} alignSelf={'flex-start'}>
            טלפון:
          </Typography>
          <Controller
            name="phone"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                margin="normal"
                required
                fullWidth
                autoComplete="current-password"
                error={!!error}
                helperText={error ? error.message : null}
                type="tel"
                sx={textFieldStyles}
              />
            )}
            rules={{
              required: { value: true, message: t('auth.phoneNumberIsRequired') },
              pattern: { value: /^\d{10}$/, message: t('auth.phoneNumberIsInvalid') },
            }}
          />
        </Box>
        <Button
          type="submit"
          ref={buttonRef}
          variant="contained"
          sx={{
            backgroundColor: '#FFAB48',
            boxShadow: '0px 0px 3px rgba(45, 45, 45, 0.25)',
            borderRadius: '0.4em',
            width: isMobile ? '100%' : '50%',
          }}
        >
          שליחת הזמנה
        </Button>
      </Box>
    </>
  )

  return isMobile ? (
    <Modal
      open={props.AddNewMenteeModalOpen}
      onClose={() => props.setAddNewMenteeModal(false)}
      aria-labelledby="AddNewMentee-modal"
      aria-describedby="Modal-for-adding-new-mentee"
    >
      <Box sx={modalStyle}>{formContent}</Box>
    </Modal>
  ) : (
    formContent
  )
}

export default AddNewMentee
