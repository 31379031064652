import { achievementApiRequest } from './requests/achievement'
import { notificationsApiRequest } from './requests/notifications'
import { questionApiRequest } from './requests/question'
import { questionnaireApiRequest } from './requests/questionnaire'
import { reinforcementsApiRequest } from './requests/reinforcement'
import { subtaskApiRequest } from './requests/subtasks'
import { successesApiRequest } from './requests/success'
import { taskApiRequest } from './requests/tasks'
import { userApiRequest } from './requests/user'

export const apiRequests = {
  ...taskApiRequest,
  ...subtaskApiRequest,
  ...userApiRequest,
  ...achievementApiRequest,
  ...questionApiRequest,
  ...questionnaireApiRequest,
  ...successesApiRequest,
  ...reinforcementsApiRequest,
  ...notificationsApiRequest,
} as const
