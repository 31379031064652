import {Navigate, Outlet} from 'react-router-dom'
import {selectUserState, useAppSelector} from '../store'
import {paths} from './paths'
import {Role} from "../api/dtos";

export const ProtectedAdminRoleRoute = () => {
  const user = useAppSelector(selectUserState)

  return user?.user.role === Role.Admin ? <Outlet /> : <Navigate to={paths.FORBIDDEN} replace />
}
