import { Avatar, Box, Typography } from '@mui/material'

interface SpeedDialItemProps {
  text: string
  handleClick: () => void
  icon: React.ReactNode
}

export const SpeedDialItem = ({ handleClick, text, icon }: SpeedDialItemProps) => {
  return (
    <Box
      onClick={handleClick}
      sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '0 0.3em 1em 0' }}
    >
      <Typography variant={'h5'} style={{ fontWeight: 600, marginRight: '0.5em', color: '#ffffff' }}>
        {text}
      </Typography>
      <Avatar
        sx={{
          width: '2.5em',
          height: '2.5em',
          backgroundColor: '#ffffff',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {icon}
      </Avatar>
    </Box>
  )
}
