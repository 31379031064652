import { Grid } from '@mui/material'
import { DeleteSubtaskRequest, Subtask } from 'api/dtos'
import { useContext, useState } from 'react'
import { deleteSubtaskAsync, selectUserState, useAppDispatch, useAppSelector } from 'store'
import { TaskAlertContext } from '../TasksPage'
import { SharedSubtaskContextType } from '../UseSharedSubtaskContext'
import DeleteDialogPopup from './DeleteDialogPopup'
import { TaskCardContents } from './TaskCardContents'
export const TaskCard = ({
  title,
  subtasks,
  taskId,
  taskProgress,
  sharedSubtaskContext,
}: {
  title: string
  subtasks: Subtask[]
  taskId: number
  taskProgress: number
  sharedSubtaskContext: SharedSubtaskContextType
}) => {
  const [arrowDirection, setArrowDirection] = useState<string>('up')
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [deleteData, setDeleteData] = useState<{ id: number; userId: number } | null>(null)
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(selectUserState)
  const { alertDeleteTask } = useContext(TaskAlertContext)
  const handleOpenDeleteDialog = (data: { id: number; userId: number }) => {
    setDeleteData(data)
    setIsDialogOpen(true)
  }

  const handleSubtaskDelete = (data: DeleteSubtaskRequest) =>
    dispatch(deleteSubtaskAsync({ id: data.id, userId: user.id })).then(() =>
      alertDeleteTask('תת משימה נמחקה בהצלחה', data.id),
    )

  const handleOpenSubtaskDeleteDialog = (subtaskId: number) => {
    handleOpenDeleteDialog({ id: subtaskId, userId: user.id })
  }

  const confirmDelete = () => {
    if (deleteData) {
      handleSubtaskDelete(deleteData)
    }
    setIsDialogOpen(false)
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <TaskCardContents
            taskId={taskId}
            title={title}
            subtasks={subtasks}
            arrowDirection={arrowDirection}
            setArrowDirection={setArrowDirection}
            taskProgress={taskProgress}
            onOpenDeleteDialog={handleOpenSubtaskDeleteDialog}
            userId={user.id}
            sharedSubtaskContext={sharedSubtaskContext}
          />
        </Grid>
      </Grid>
      <DeleteDialogPopup
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onConfirmDelete={confirmDelete}
        type={deleteData?.id === taskId ? 'task' : 'subtask'}
      />
    </>
  )
}
