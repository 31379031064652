import { Box, Modal, Typography } from '@mui/material'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Notification, NotificationStatus } from '../../api/dtos'
import {
  listMenteesAsync,
  listNotificationsAsync,
  listTasksOfUsersOfMentorAsync,
  selectNotificationListState,
  selectUserState,
  useAppDispatch,
  useAppSelector,
} from '../../store'
import { MentorFragments } from './MentorDashboard'
import MobileHeaderAdmin from './MobileHeaderAdmin'
import { NotificationItem } from './NotificationItem'
import NotificationItemMobile from './NotificationItemMobile'

interface NotificationItemProps {
  mentorNotificationModal: boolean
  setMentorNotificationModal: Dispatch<SetStateAction<boolean>>
  setCurrentPage: Dispatch<SetStateAction<MentorFragments>>
}

export const MentorDashBoardNotificationCenter = (props: NotificationItemProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(selectUserState)
  const { notifications } = useAppSelector(selectNotificationListState)
  const isMobile = useAppSelector(state => state.layout.isMobileView)

  const [sortedNotifications, setSortedNotifications] = useState<Notification[]>([])

  useEffect(() => {
    dispatch(listNotificationsAsync())
  }, [dispatch, user.id])

  useEffect(() => {
    dispatch(listMenteesAsync(user.id))
    dispatch(listTasksOfUsersOfMentorAsync(user.id))
  }, [user.id, dispatch])

  useEffect(() => {
    const filtered =
      notifications?.filter(notification =>
        [NotificationStatus.Created, NotificationStatus.Seen].includes(notification.status),
      ) || []
    const sorted = filtered
      .slice()
      .reverse()
      .sort((a, b) => new Date(b.updated).getTime() - new Date(a.updated).getTime())
    setSortedNotifications(sorted)
  }, [notifications])

  const closeModal = () => {
    props.setMentorNotificationModal(false)
    props.setCurrentPage(MentorFragments.Summary)
  }

  const modalStyle = {
    position: 'absolute',
    top: '0%',
    left: '0%',
    transform: 'translate(0%, 0%)',
    width: '100dvw',
    height: '100dvh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'scroll',
  }

  return (
    <>
      {isMobile && props.mentorNotificationModal ? (
        <Modal
          open={props.mentorNotificationModal}
          onClose={closeModal}
          aria-labelledby="MentorNotification-modal"
          aria-describedby="Modal-for-MentorNotification"
        >
          <Box sx={modalStyle}>
            <MobileHeaderAdmin title={t('notifications.notificationCenter')} onClose={closeModal} />
            {sortedNotifications.map((notification, index) => (
              <NotificationItemMobile
                key={index}
                mentorNotificationModal={props.mentorNotificationModal}
                setMentorNotificationModal={props.setMentorNotificationModal}
                status={notification.status}
                message={notification.message}
                notificationId={notification.id}
                updated={notification.updated}
                notifierName={notification.notifier}
              />
            ))}
          </Box>
        </Modal>
      ) : (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '80vh',
            flexGrow: 10,
            padding: '1em 2em 0 3em',
            backgroundColor: '#ECECEC',
          }}
        >
          <Typography
            variant={'h4'}
            sx={{
              alignSelf: 'end',
            }}
          >
            {t('notifications.notificationCenter')}
          </Typography>

          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'scroll',
            }}
          >
            {sortedNotifications.map((notification, index) => (
              <NotificationItem
                status={notification.status}
                message={notification.message}
                key={index}
                notifictionId={notification.id}
                updated={notification.updated}
              />
            ))}
            {!notifications && (
              <img
                height={275}
                alt={t('notifications.noNotifications')}
                src=""
                style={{ position: 'fixed', bottom: 80, right: 60, zIndex: -1, opacity: '0.9' }}
              />
            )}
          </Box>
        </Box>
      )}
    </>
  )
}
