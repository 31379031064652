import { Typography } from '@mui/material'
import React from 'react'
import achievementIconExperience from '../../icons/experience.png'
import achievementIconGrowth from '../../icons/growth.png'
import achievementIconStruggle from '../../icons/struggle.png'
import { AchievementType } from '../../api/dtos/achievement'

export const AchievementItem = ({ title }: { title: string }) => {
  const getIcon = (title: string) => {
    if (title === AchievementType.GROWTH) {
      return achievementIconGrowth
    } else if (title === AchievementType.STRUGGLE) {
      return achievementIconStruggle
    } else if (title === AchievementType.EXPERIENCE) {
      return achievementIconExperience
    }
    return achievementIconGrowth
  }

  const getDescription = (title: string) => {
    if (title === AchievementType.GROWTH) {
      return 'הזמן מאז ההפסקה האחרונה'
    } else if (title === AchievementType.STRUGGLE) {
      return 'השלמת לפחות משימה ביום, למשך 3 ימים ברצף'
    } else if (title === AchievementType.EXPERIENCE) {
      return 'התחברות לאפליקציה במשך 3 ימים ברצף'
    }
    return 'השלמת לפחות משימה ביום, למשך 3 ימים ברצף'
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          width: '8em',
          height: '8em',
          borderRadius: '50%',
          border: '0.7em solid #F9B76B',
          overflow: 'hidden',
          position: 'relative',
          margin: '0.5em',
          boxShadow: 'inset 0px 0px 2px rgba(45, 45, 45, 0.25)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img alt='item' width={'60%'} height={'60%'} src={getIcon(title)} />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant={'h6'} style={{ fontWeight: 600 }}>
          {title}
        </Typography>
        <Typography variant={'body1'} align={'center'}>
          {getDescription(title)}
        </Typography>
      </div>
    </div>
  )
}
export default AchievementItem
