import { Box, Typography } from '@mui/material'
import { UserProcrastinations } from 'api/dtos'
import ProcrastinationTypeResult from 'pages/Profile/ProcrastinationTypeResult'
import { info_list } from 'pages/Profile/Profile'

interface ProcrastinationProfileProps {
  userProcrastinations?: UserProcrastinations
  text: string
}

export const ProcrastinationProfile = (props: ProcrastinationProfileProps) => {
  return (
    <>
      <Typography variant="body1" dir="rtl" padding={1} sx={{ fontSize: '1.25em', width: '100%' }}>
        {props.text}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          gap: '0.5em',
          justifyContent: 'center',
          direction: 'rtl',
        }}
      >
        <ProcrastinationTypeResult
          name={'ציפייה'}
          progress={Math.floor((props.userProcrastinations?.expectation || 0) * 100)}
          info={info_list[0]}
        />
        <ProcrastinationTypeResult
          name={'זמן'}
          progress={Math.floor((props.userProcrastinations?.time || 0) * 100)}
          info={info_list[2]}
        />
        <ProcrastinationTypeResult
          name={'ערך'}
          progress={Math.floor((props.userProcrastinations?.value || 0) * 100)}
          info={info_list[1]}
        />
      </Box>
    </>
  )
}
