export interface Notification {
  id: string
  status: NotificationStatus
  notifier: string
  userId: number
  message: string
  created: Date
  updated: Date
}

export interface UpdateNotificationRequest {
  notificationIds: string[]
  status: NotificationStatus
}

export enum NotificationStatus {
  Created = 'Created',
  Seen = 'Seen',
  Acknowledged = 'Acknowledged',
}
