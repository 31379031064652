import { Question } from './question'

export interface Questionnaire {
  id: number
  name: string
  questions: Question[]
}

export interface CreateQuestionnaireRequest {
  name: string
  questions: Question[]
}

export interface DeleteQuestionnaireRequest {
  id: number
}

export interface UpdateQuestionnaireRequest {
  id: number
  name: string
  questions: Question[]
}

export enum UserQuestionAnswer {
  ONE = 'ONE',
  TWO = 'TWO',
  THREE = 'THREE',
  FOUR = 'FOUR',
  FIVE = 'FIVE',
  SIX = 'SIX',
}

export interface CreateUserQuestionAndAnswerDto {
  userId: number
  questionId: number
  answer: UserQuestionAnswer
}

export interface AnswerQuestionnaireRequest {
  userId: number
  answers: CreateUserQuestionAndAnswerDto[]
}
