import StarIcon from '@mui/icons-material/Star'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import { Box } from '@mui/material'
import React from 'react'

interface StarRatingProps {
  difficulty: 'EASY' | 'MEDIUM' | 'HARD'
  setDifficulty: (difficulty: 'EASY' | 'MEDIUM' | 'HARD') => void
}

const COLORS = {
  active: '#6E1D93',
  inactive: 'grey.500',
}

const StarRating: React.FC<StarRatingProps> = ({ difficulty, setDifficulty }) => {
  const renderStars = (count: number, label: 'EASY' | 'MEDIUM' | 'HARD') => (
    <Box
      role="button"
      aria-label={`Set difficulty to ${label.toLocaleLowerCase()}`}
      onClick={() => setDifficulty(label)}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '33%',
        height: '4vh',
        border: '1px solid',
        color: difficulty === label ? COLORS.active : COLORS.inactive,
        borderRadius: '0.5rem',
        cursor: 'pointer',
        '&:not(:last-child)': {
          marginRight: '8px',
        },
      }}
    >
      {Array.from({ length: count }, (_, index) =>
        difficulty === label ? <StarIcon key={index} /> : <StarBorderIcon key={index} />,
      )}
    </Box>
  )

  return (
    <Box sx={{ display: 'flex' }}>
      {renderStars(3, 'HARD')}
      {renderStars(2, 'MEDIUM')}
      {renderStars(1, 'EASY')}
    </Box>
  )
}

export default StarRating
