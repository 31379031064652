import { AboutUsModal } from 'components/AboutUsModal'
import DrawerItem from 'components/DrawerItem'
import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { logout, useAppDispatch, useAppSelector } from 'store'
import logo from '../../icons/AniAlZeLogo.png'
import { MentorFragments } from './MentorDashboard'

interface MentorSideDrawerContentProps {
  readonly setCurrentPage: Dispatch<SetStateAction<number>>
  readonly setAddNewMenteeModalOpen: Dispatch<SetStateAction<boolean>>
  setSelectedTab: Dispatch<SetStateAction<'mentees' | 'invitations'>>
}

// TODO: useAppSelector instead of props in the latest commit

export default function MentorSideDrawerContent(props: MentorSideDrawerContentProps) {
  const [modalOpen, setModalOpen] = useState(false)
  const dispatch = useAppDispatch()
  const isMobile = useAppSelector(state => state.layout.isMobileView)

  function linkToWebsite(url: string) {
    window.open(url, '_blank')
  }

  const logOut = useCallback(() => {
    dispatch(logout())
  }, [dispatch])

  const addNewMenteeDrawer = isMobile ? (
    <DrawerItem eventHandler={() => props.setAddNewMenteeModalOpen(true)} title={'הזמנת משתמש'} />
  ) : (
    <DrawerItem eventHandler={() => props.setCurrentPage(MentorFragments.InviteMentee)} title={'הזמנת משתמש'} />
  )

  return (
    <>
      <img src={logo} alt={logo} style={{ width: '7em', height: '7em' }} />

      <DrawerItem
        eventHandler={() => {
          props.setCurrentPage(MentorFragments.Summary)
          props.setSelectedTab('mentees')
        }}
        title={'מתאמנים'}
      />

      <DrawerItem eventHandler={() => props.setCurrentPage(MentorFragments.Settings)} title={'הגדרות'} />

      {addNewMenteeDrawer}

      <DrawerItem eventHandler={() => setModalOpen(true)} title={'עלינו'} />

      <DrawerItem
        eventHandler={() => linkToWebsite('https://forms.monday.com/forms/e7464e9cbe067daf6215f59e6f3f885c?r=use1')}
        title={'דיווח על באג'}
      />
      <DrawerItem
        eventHandler={() =>
          linkToWebsite('https://drive.google.com/drive/folders/1wT2HDx6idBRojBiEJMKqyi1x0WdHgXMW?usp=sharing')
        }
        title={'דפי מידע'}
      />
      <DrawerItem eventHandler={() => logOut()} title={'התנתקות'} />

      <AboutUsModal handleClose={() => setModalOpen(false)} open={modalOpen} />
    </>
  )
}
