import { Role } from 'api/dtos'
import { Buffer } from 'buffer'
import { MentorFragments } from 'pages/MentorHome/MentorDashboard'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { DEFAULT_AVATAR_BASE } from '../../../../common/consts'
import { paths } from '../../../../routes'
import { getUserAsync, selectUserState, useAppDispatch, useAppSelector } from '../../../../store'

export const Profile = ({ setCurrentPage }: { setCurrentPage?: Dispatch<SetStateAction<MentorFragments>> }) => {
  const navigate = useNavigate()
  const { user } = useAppSelector(selectUserState)

  const dispatch = useAppDispatch()

  let base64String = DEFAULT_AVATAR_BASE
  if (user && user.profilePictureBytea != null) {
    base64String = Buffer.from(user.profilePictureBytea.data).toString('base64')
  }

  useEffect(() => {
    dispatch(getUserAsync())
  }, [dispatch])

  const handleClick = () => {
    if (user.role === Role.Mentor) {
      setCurrentPage?.(MentorFragments.Settings)
    } else navigate(paths.PROFILE)
  }

  return (
    <div
      style={{
        width: '3em',
        height: '3em',
        position: 'relative',
      }}
    >
      <img
        alt={'avatar'}
        onClick={handleClick}
        src={`data:image/jpeg;base64,${base64String}`}
        width={150}
        height={150}
        style={{
          width: '100%',
          height: '100%',
          borderRadius: '50%',
          position: 'absolute',
          padding: '0.5em',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      />
    </div>
  )
}
