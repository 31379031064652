import { CreateSubtaskRequest, Subtask, UpdateSubtaskRequest } from '../dtos'
import { axiosInstance } from '../network'
import { ApiResponse } from '../types'
import { RequestConfig } from '../types/request-config'

const getSubtask = async (id: number, config?: RequestConfig): Promise<Subtask> => {
  const axiosResponse = await axiosInstance.get<never, ApiResponse<Subtask>>('/subtasks/' + id, config)
  return axiosResponse.data!
}

const listSubtasks = async (config?: RequestConfig): Promise<Subtask[]> => {
  const axiosResponse = await axiosInstance.get<never, ApiResponse<Subtask[]>>('/subtasks/list', config)
  return axiosResponse.data!
}

const deleteSubtask = async (id: number, config?: RequestConfig): Promise<Subtask[]> => {
  const axiosResponse = await axiosInstance.delete<never, ApiResponse<Subtask[]>>('/subtasks/' + id, config)
  return axiosResponse.data!
}

const completeSubtask = async (id: number, config?: RequestConfig): Promise<Subtask> => {
  const axiosResponse = await axiosInstance.put<UpdateSubtaskRequest, ApiResponse<Subtask>>(
    '/subtasks/done/' + id,
    { status: 'DONE' },
    config,
  )
  return axiosResponse.data!
}

const createSubtask = async (
  createSubtaskRequest: CreateSubtaskRequest,
  config?: RequestConfig,
): Promise<Subtask[]> => {
  const apiResponse = await axiosInstance.post<CreateSubtaskRequest, ApiResponse<Subtask[]>>(
    '/subtasks/create',
    createSubtaskRequest,
    config,
  )
  return apiResponse.data!
}

const updateSubtask = async (updateSubtaskRequest: UpdateSubtaskRequest, config?: RequestConfig): Promise<Subtask> => {
  const axiosResponse = await axiosInstance.put<UpdateSubtaskRequest, ApiResponse<Subtask>>(
    '/subtasks/' + updateSubtaskRequest.id,
    updateSubtaskRequest,
    config,
  )
  return axiosResponse.data!
}
export const subtaskApiRequest = {
  getSubtask,
  listSubtasks,
  createSubtask,
  deleteSubtask,
  completeSubtask,
  updateSubtask,
}
