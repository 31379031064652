import AchievementItem from './AchievementItem'
import {selectUserState, useAppDispatch, useAppSelector} from '../../store'
import {
  listUsersAchievementsAsync,
  selectAchievementListState,
} from '../../store/achievements-slice'
import {useEffect} from 'react'
import {Box} from '@mui/material'
import Typography from "@mui/material/Typography";

export const Achievements = () => {
  const {error, status, achievements} = useAppSelector(selectAchievementListState) // eslint-disable-line @typescript-eslint/no-unused-vars
  const {user} = useAppSelector(selectUserState) // eslint-disable-line @typescript-eslint/no-unused-vars

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(listUsersAchievementsAsync(user.id))
  }, [dispatch, user.id])

  const listItems = achievements?.map(d => <AchievementItem title={d.text}/>)

  return (
    <Box
      sx={{
        width: '100%',
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography component="h1" variant="h4">ההישגים שלי
      </Typography>
      {listItems}
    </Box>
  )
}
export default Achievements
