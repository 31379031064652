import DeleteIcon from '@mui/icons-material/Delete'
import { Box, IconButton, Typography } from '@mui/material'
import { NotificationStatus } from 'api/dtos'
import { DEFAULT_AVATAR_BASE } from 'common/consts'
import { Dispatch, SetStateAction, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { updateNotificationAsync, useAppDispatch } from 'store'

interface NotificationItemMobileProps {
  key: number
  setMentorNotificationModal: Dispatch<SetStateAction<boolean>>
  mentorNotificationModal: boolean
  status: string
  message: string
  notificationId: string
  updated: Date
  notifierName: string
}

export default function NotificationItemMobile(props: NotificationItemMobileProps) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  let base64String = DEFAULT_AVATAR_BASE

  const formatDate = useCallback(
    (date: Date) => {
      const now = new Date()
      const updatedDate = new Date(date)
      const diffMinutes = Math.abs(now.getTime() - updatedDate.getTime()) / (1000 * 60) // Difference in minutes
      if (diffMinutes < 60) return `לפני ${Math.round(diffMinutes)} דקות`
      const diffHours = diffMinutes / 60
      if (diffHours < 24) return `לפני ${Math.round(diffHours)} שעות`
      if (diffHours < 48) return t('notifications.yesterday')
      return updatedDate.toLocaleDateString()
    },
    [t],
  )

  const handleDelete = () => {
    dispatch(
      updateNotificationAsync({ notificationIds: [props.notificationId], status: NotificationStatus.Acknowledged }),
    )
  }

  return (
    <Box sx={{ padding: '0.5em 0.7em' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          alignItems: 'flex-start',
          width: '100%',
          border: '1px solid rgba(236, 236, 236, 1)',
          borderRadius: '0.5em',
          p: '0.5em',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', flexGrow: 1 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Typography variant="subtitle1" sx={{ mr: 0.5, fontSize: '0.75rem', color: 'black' }}>
              {props.notifierName}
            </Typography>
            <img alt={'avatar'} src={`data:image/jpeg;base64,${base64String}`} width={20} height={20} />
          </Box>
          <Typography variant="body2" sx={{ direction: 'rtl' }}>
            {props.message}
          </Typography>
          <Typography variant="caption" color={'#666'}>
            {formatDate(props.updated)}
          </Typography>
        </Box>
        <Box>
          <IconButton onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  )
}
