import SaveIcon from '@mui/icons-material/Save'
import { Box, Button, Modal, TextField, Typography, useMediaQuery } from '@mui/material'
import { DatePicker, LocalizationProvider, heIL } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Subtask } from 'api/dtos'
import StarRating from 'components/StarRating'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch, selectUserState, updateSubtaskAsync, useAppSelector } from 'store'
import { Xicon } from '../TaskCreation/icons/Xicon'

interface EditTaskProps {
  subtask: Subtask
  onClose: () => void
}

function EditTask({ subtask, onClose }: EditTaskProps) {
  const [description, setDescription] = useState(subtask.description)
  const [startTime, setStartTime] = useState(subtask.deadline ? new Date(subtask.deadline) : undefined)
  const [difficulty, setDifficulty] = useState(subtask.difficulty)

  const { user } = useAppSelector(selectUserState)
  const dispatch: AppDispatch = useDispatch()
  const isLargeScreen = useMediaQuery('(min-width:400px)')

  return (
    <LocalizationProvider
      localeText={heIL.components.MuiLocalizationProvider.defaultProps.localeText}
      dateAdapter={AdapterDateFns}
    >
      <Modal
        open
        onClose={onClose}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'right' }}
      >
        <Box
          sx={{
            bgcolor: 'background.paper',
            width: '100vw',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              backgroundColor: '#FAFAFA',
              height: '8vh',
              padding: '1em',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Button onClick={onClose}>
              <Xicon />
            </Button>
            <Typography variant="h6" sx={{ marginRight: '1em' }}>
              עריכת משימה
            </Typography>
          </Box>
          <Box sx={{ padding: '1.5em', flexGrow: 1, overflowY: 'auto' }}>
            <Typography variant="h6" gutterBottom color={'#666666'}>
              :משימה
            </Typography>
            <TextField
              InputProps={{ sx: { borderRadius: '0.5rem', height: '5vh' } }}
              fullWidth
              dir="rtl"
              variant="outlined"
              value={description}
              onChange={e => setDescription(e.target.value)}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  dispatch(
                    updateSubtaskAsync({
                      id: subtask.id,
                      description,
                      deadline: startTime,
                      difficulty,
                      userId: user.id,
                    }),
                  )
                  onClose()
                }
              }}
              sx={{ mb: '1.5rem' }}
            />
            <Typography variant="h6" gutterBottom color={'#666666'}>
              :לביצוע עד
            </Typography>
            <DatePicker
              value={startTime}
              disablePast
              format="dd/MM/yyyy"
              onChange={(date: Date | null) => {
                if (date) setStartTime(date)
              }}
              sx={{
                width: isLargeScreen ? '30vw' : '35vw',
                direction: 'rtl',
                borderRadius: '80%',
                mb: '1.5rem',
                '& .MuiInputBase-root': {
                  borderRadius: '0.5rem',
                  height: '5vh',
                },
              }}
            />
            <Typography variant="h6" gutterBottom color={'#666666'}>
              :רמת קושי
            </Typography>
            <StarRating difficulty={difficulty} setDifficulty={setDifficulty} />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-around', padding: '1em' }}>
            <Button
              onClick={() => {
                dispatch(
                  updateSubtaskAsync({ id: subtask.id, description, deadline: startTime, difficulty, userId: user.id }),
                )
                onClose()
              }}
              type="submit"
              variant="contained"
              sx={{ width: '40%', borderRadius: '0.5rem' }}
              endIcon={<SaveIcon />}
            >
              שמירה
            </Button>
            <Button
              onClick={onClose}
              variant="outlined"
              sx={{ width: '40%', borderRadius: '0.5rem', color: '#000', border: '1px solid #FFAB48' }}
            >
              ביטול שינויים
            </Button>
          </Box>
        </Box>
      </Modal>
    </LocalizationProvider>
  )
}

export default EditTask
