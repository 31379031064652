import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { Box, Button } from '@mui/material'
import { DataGrid, GridCellEditCommitParams, GridColDef, MuiEvent } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EfficiencyTimes, Role } from '../../api/dtos'
import {
  deleteUserAsync,
  getUserAsync,
  listAllUsersAsync,
  selectUserState,
  updateUserAsync,
  useAppDispatch,
  useAppSelector,
} from '../../store'
import { DeleteModal } from './components/DeleteModal'

export const UsersTable = () => {
  const { t } = useTranslation()
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [rowToDelete, setRowToDelete] = useState(999)

  const dispatch = useAppDispatch()
  const { user, allUsers } = useAppSelector(selectUserState)

  useEffect(() => {
    dispatch(listAllUsersAsync())
    dispatch(getUserAsync())
  }, [user.id, dispatch])

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'name',
      headerName: 'שם',
      editable: true,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'email',
      headerName: t('auth.email'),
      editable: false,
      flex: 1.2,
      align: 'center',
      headerAlign: 'center',
      // resizable: true,
    },
    {
      field: 'phoneNumber',
      headerName: 'מספר טלפון',
      type: 'string',
      editable: true,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'mentorId',
      headerName: 'מזהה מנטור',
      type: 'number',
      editable: true,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'role',
      headerName: 'תפקיד',
      type: 'singleSelect',
      editable: true,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      valueOptions: [
        { label: Role.User, value: 'User' },
        { label: Role.Mentor, value: 'Mentor' },
        { label: Role.Admin, value: 'Admin' },
      ],
    },
    {
      field: 'efficiencyTime',
      headerName: 'זמן יעילות',
      type: 'singleSelect',
      editable: true,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      valueOptions: [
        { label: EfficiencyTimes.MORNING, value: 'Morning' },
        { label: EfficiencyTimes.NOON, value: 'Noon' },
        { label: EfficiencyTimes.EVENING, value: 'Evening' },
      ],
    },
    {
      field: 'enableNotifications',
      headerName: 'מאשר הודעות',
      type: 'boolean',
      editable: true,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      headerName: 'מחיקת משתמש',
      field: 'delete',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: params => {
        return (
          <Button
            color={'inherit'}
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
            onClick={() => {
              if (params.row.id !== null) {
                setRowToDelete(params.row.id)
                setConfirmDeleteModal(true)
              }
            }}
          >
            <DeleteOutlineIcon />
          </Button>
        )
      },
    },
  ]
  function stringToRole(role: string | undefined): Role {
    switch (role) {
      case 'User':
        return Role.User
      case 'Mentor':
        return Role.Mentor
      case 'Admin':
        return Role.Admin
      default:
        return Role.User
    }
  }

  function stringToEfficiency(updateValue: string) {
    switch (updateValue) {
      case 'Morning':
        return EfficiencyTimes.MORNING
      case 'Noon':
        return EfficiencyTimes.NOON
      case 'Evening':
        return EfficiencyTimes.EVENING
      default:
        return EfficiencyTimes.MORNING
    }
  }

  async function updateUser(params: GridCellEditCommitParams, event: MuiEvent, details: any) {
    const updatedUser = allUsers?.find(user => user.id === params.id)
    const updateField = params.field.toString()
    const updateValue = params.value?.toString()

    if (updatedUser) {
      await dispatch(
        updateUserAsync({
          id: updatedUser.id,
          name: updateField === 'name' ? updateValue : updatedUser.name,
          phoneNumber: updateField === 'phoneNumber' ? updateValue : updatedUser.phoneNumber,
          role: updateField === 'role' ? stringToRole(updateValue) : updatedUser.role,
          enableNotifications:
            updateField === 'enableNotifications' ? updateValue === 'true' : updatedUser.enableNotifications,
          efficiencyTime:
            updateField === 'efficiencyTime' ? stringToEfficiency(updateValue) : updatedUser.efficiencyTime,
          mentorId: updateField === 'mentorId' ? parseInt(updateValue) : updatedUser.mentorId,
        }),
      )

      await dispatch(listAllUsersAsync())
    }
  }

  return (
    <Box
      sx={{
        flexGrow: 5,
        width: '100%',
      }}
    >
      <DataGrid
        style={{
          backgroundColor: 'white',
        }}
        rows={allUsers?.slice().sort((a, b) => (a.id > b.id ? 1 : -1)) || []}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        onCellEditCommit={(params, event, details) => updateUser(params, event, details)}
      />
      <DeleteModal
        isOpen={confirmDeleteModal}
        setIsOpen={setConfirmDeleteModal}
        text={`בטוחים שאתם רוצים למחוק את משתמש ${allUsers?.find(u => u.id === rowToDelete)?.name} ?`}
        dispatchDelete={() => dispatch(deleteUserAsync(rowToDelete))}
      />
    </Box>
  )
}
