import { Box, Typography } from '@mui/material'
import { differenceInDays, parseISO } from 'date-fns'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { HorizontalScroller } from '../../components/HorizontalScroller'
import { selectTaskListState, selectUserState, useAppDispatch, useAppSelector } from '../../store'
import { listSuccessesAsync, selectSuccessListState } from '../../store/successes-slice'
import SuccessGridItem from './SuccessGridItem'

export const SuccessesGridView = () => {
  const { t } = useTranslation()
  const { successes } = useAppSelector(selectSuccessListState) // eslint-disable-line @typescript-eslint/no-unused-vars
  const { user } = useAppSelector(selectUserState) // eslint-disable-line @typescript-eslint/no-unused-vars
  const { tasks } = useAppSelector(selectTaskListState)
  const isMobile = useAppSelector(state => state.layout.isMobileView)
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(listSuccessesAsync(user.id))
  }, [dispatch, user.id])

  const calculateTimeDifference = (startTime: string, endTime: string) => {
    const start = parseISO(startTime)
    const end = parseISO(endTime)
    const daysDifference = differenceInDays(end, start)
    return daysDifference
  }

  const getTaskTitleById = (taskId: number): string => {
    const task = tasks?.find(task => task.id === taskId)
    return task ? task.title : `Couldn't find task`
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: isMobile ? 'right' : 'center',
        gap: 2,
      }}
    >
      {successes?.hardSubtasks.length ? (
        <>
          <Typography component="h1" variant="h4" fontSize={'1.5em'} marginRight={'1em'}>
            {t('successes.hard')}
          </Typography>
          <HorizontalScroller
            itemsList={successes?.hardSubtasks?.map((item, index) => (
              <SuccessGridItem
                key={index}
                subtaskTitle={item.description}
                timeDifference={calculateTimeDifference(item.startedTime.toString(), item.endTime.toString())}
                taskTitle={getTaskTitleById(item.taskId)}
                category="hard"
                difficulty={item.difficulty.toString()}
              />
            ))}
            fetchMoreDataFunction={() => {}}
          />
        </>
      ) : null}
      {successes?.earlySubtasks.length ? (
        <>
          <Typography component="h1" variant="h4" fontSize={'1.5em'} marginRight={'1em'}>
            {t('successes.early')}
          </Typography>
          <HorizontalScroller
            itemsList={successes?.earlySubtasks?.map((item, index) => (
              <SuccessGridItem
                key={index}
                subtaskTitle={item.description}
                timeDifference={calculateTimeDifference(item.startedTime.toString(), item.endTime.toString())}
                taskTitle={getTaskTitleById(item.taskId)}
                category="early"
                difficulty={item.difficulty.toString()}
              />
            ))}
            fetchMoreDataFunction={() => {}}
          />
        </>
      ) : null}
      {successes?.onTimeSubtasks.length ? (
        <>
          <Typography component="h1" variant="h4" fontSize={'1.5em'} marginRight={'1em'}>
            {t('successes.onTime')}
          </Typography>
          <HorizontalScroller
            itemsList={successes?.onTimeSubtasks.map((item, index) => (
              <SuccessGridItem
                key={index}
                subtaskTitle={item.description}
                timeDifference={calculateTimeDifference(item.startedTime.toString(), item.endTime.toString())}
                taskTitle={getTaskTitleById(item.taskId)}
                category="onTime"
                difficulty={item.difficulty.toString()}
              />
            ))}
            fetchMoreDataFunction={() => {}}
          />
        </>
      ) : null}
    </Box>
  )
}
export default SuccessesGridView
