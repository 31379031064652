import { Box, Grid, Typography } from '@mui/material'
import { SetState } from 'common/types/types'
import { theme } from 'theme'
import { ViewMode } from '../MobileHome'
import { Page } from '../Top/MenteeTopBar'

export const AccessItem = ({
  page,
  text,
  currentPage,
  setCurrentPage,
  onClick,
  viewMode,
  expectedViewMode,
}: {
  page: Page
  text: string
  currentPage: Page
  setCurrentPage: SetState<Page>
  onClick?: () => void
  viewMode?: ViewMode
  expectedViewMode?: ViewMode
}) => {
  const isActive = page === currentPage && (!viewMode || viewMode === expectedViewMode)
  const handleClick = () => {
    if (currentPage !== page || viewMode !== expectedViewMode) {
      setCurrentPage(page)
    }
    onClick?.()
  }
  return (
    <Grid
      container
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <Typography
        variant={'h6'}
        sx={{ opacity: isActive ? '100%' : '60%', mt: '0.5em' }}
        align={'center'}
        onClick={handleClick}
      >
        {text}
      </Typography>
      {page === currentPage && viewMode === expectedViewMode && (
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            border: `0.05em solid ${theme.palette.primary.main}`,
            borderRadius: '20px',
            height: '0em',
            width: '33vw',
          }}
        />
      )}
    </Grid>
  )
}
