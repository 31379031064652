import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { config } from 'config'
import { storageUtil } from 'utils'
import { apiRequests } from '../api'
import { CreateTaskRequest, DeleteTaskRequest, Task, UndeleteTaskRequest, UpdateTaskRequest } from '../api/dtos'
import { RequestStatus } from '../common/types'
import { RootState } from './store'

export interface TasksState {
  tasks?: Task[]
  status: RequestStatus
  error?: string
}

const initialState: TasksState = {
  tasks: [],
  status: RequestStatus.Idle,
  error: undefined,
}

export const listTasksAsync = createAsyncThunk('tasks/list', async (userId: number): Promise<Task[]> => {
  const response = await apiRequests.listTasks(userId)
  storageUtil.set(config.storage.TASKS_KEY, response)
  return response
})

export const createTaskAsync = createAsyncThunk(
  'tasks/create',
  async ({ title, subtasks, userId, reinforcementIds }: CreateTaskRequest, { dispatch }) => {
    const response = await apiRequests.createTask({ title, subtasks, userId, reinforcementIds })
    if (response) {
      dispatch(listTasksAsync(userId))
    }
  },
)

export const updateTaskAsync = createAsyncThunk(
  'tasks/update',
  async ({ id, title, newSubtasks, userId }: UpdateTaskRequest, { dispatch }) => {
    const response = await apiRequests.updateTask({ id, title, newSubtasks, userId })
    if (response) {
      dispatch(listTasksAsync(userId))
    }
  },
)

export const deleteTaskAsync = createAsyncThunk(
  'tasks/delete/',
  async ({ id, userId }: DeleteTaskRequest, { dispatch }) => {
    const response = await apiRequests.deleteTask(id)
    if (response) {
      dispatch(listTasksAsync(userId))
    }
  },
)
export const undeleteTaskAsync = createAsyncThunk(
  'tasks/undelete/',
  async ({ id, userId }: UndeleteTaskRequest, { dispatch }) => {
    const response = await apiRequests.undeleteTask(id)
    if (response) {
      dispatch(listTasksAsync(userId))
    }
  },
)

export const listTasksOfUserAsync = createAsyncThunk('tasks/list/id', async (userId: number): Promise<Task[]> => {
  const response = await apiRequests.listTasksOfUser(userId)
  storageUtil.set(config.storage.TASKS_KEY, response)
  return response
})

export const listTasksOfUsersOfMentorAsync = createAsyncThunk(
  'user/usersOfMentor',
  async (mentorId: number, { dispatch }): Promise<Task[]> => {
    const response = await apiRequests.listTasksOfUsersOfMentorAsync(mentorId)
    return response
  },
)

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder

      .addCase(listTasksAsync.pending, state => {
        state.status = RequestStatus.Loading
      })
      .addCase(listTasksAsync.fulfilled, (state, action) => {
        state.status = RequestStatus.Idle
        state.tasks = action.payload
      })
      .addCase(listTasksAsync.rejected, (state, action) => {
        state.error = action.error.message
        state.status = RequestStatus.Failed
      })
  },
})

export const selectTaskListState = (state: RootState) => state.tasks
export const tasksReducer = tasksSlice.reducer
