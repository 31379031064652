import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { Box, Button } from '@mui/material'
import { DataGrid, GridCellEditCommitParams, GridColDef, MuiEvent } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { ProcrastinationClass } from '../../api/dtos'
import {
  createQuestionAsync,
  deleteQuestionAsync,
  listQuestionsAsync,
  selectQuestionListState,
  updateQuestionAsync,
  useAppDispatch,
  useAppSelector,
} from '../../store'
import { DeleteModal } from './components/DeleteModal'

export const QuestionnaireTable = () => {
  const [rowToDelete, setRowToDelete] = useState(999)
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const dispatch = useAppDispatch()
  const { questions } = useAppSelector(selectQuestionListState)

  useEffect(() => {
    dispatch(listQuestionsAsync())
  }, [dispatch])

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'statement',
      headerName: 'הצהרה',
      editable: true,
      flex: 2,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'questionnaireId',
      headerName: 'מזהה שאלון',
      editable: false,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'procrastinationClass',
      headerName: 'סוג שאלה',
      type: 'singleSelect',
      editable: true,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      valueOptions: [
        { label: ProcrastinationClass.VALUE, value: ProcrastinationClass.VALUE },
        { label: ProcrastinationClass.TIME, value: ProcrastinationClass.TIME },
        { label: ProcrastinationClass.EXPECTATION, value: ProcrastinationClass.EXPECTATION },
      ],
    },
    {
      headerName: 'מחיקת שאלה',
      field: 'delete',
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      renderCell: params => {
        return (
          <Button
            color={'inherit'}
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
            onClick={() => {
              if (params.row.id !== null) {
                setRowToDelete(params.row.id)
                setConfirmDeleteModal(true)
              }
            }}
          >
            <DeleteOutlineIcon />
          </Button>
        )
      },
    },
  ]

  const updateQuestion = async (params: GridCellEditCommitParams, event: MuiEvent, details: any) => {
    const updatedQuestion = questions?.find(q => q.id === params.id)
    const updateField = params.field.toString()
    const updateValue = params.value?.toString()

    if (updatedQuestion) {
      await dispatch(
        updateQuestionAsync({
          ...updatedQuestion,
          [updateField]: updateValue,
        }),
      )
      await dispatch(listQuestionsAsync())
    }
  }

  const deleteQuestion = async () => {
    await dispatch(deleteQuestionAsync(rowToDelete))
    await dispatch(listQuestionsAsync())
  }

  const handleAddQuestion = async () => {
    await dispatch(
      createQuestionAsync({
        statement: 'שאלה חדשה',
        questionnaireId: 1, // default questionnaire,
        procrastinationClass: ProcrastinationClass.VALUE,
      }),
    )
    await dispatch(listQuestionsAsync())
  }

  return (
    <Box
      sx={{
        flexGrow: 5,
        width: '100%',
      }}
    >
      <DataGrid
        style={{
          backgroundColor: 'white',
        }}
        rows={questions?.slice().sort((a, b) => (a.id > b.id ? 1 : -1)) || []}
        columns={columns}
        pageSize={20}
        rowsPerPageOptions={[10, 20]}
        onCellEditCommit={(params, event, details) => updateQuestion(params, event, details)}
      />
      <Button variant="outlined" color="success" onClick={handleAddQuestion}>
        הוספת שאלה
      </Button>
      <DeleteModal
        isOpen={confirmDeleteModal}
        setIsOpen={setConfirmDeleteModal}
        text={`בטוחים שאתם רוצים למחוק את שאלה ${rowToDelete}?`}
        dispatchDelete={deleteQuestion}
      />
    </Box>
  )
}
