import { StarOutline } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { Subtask } from 'api/dtos'
import { ViewMode } from 'pages/Home/MobileHome/MobileHome'
import { MentorContext } from 'pages/MentorHome/MenteeDetailedInfoNew'
import { useContext } from 'react'
import { difficultyToValue } from '../SubtaskSection'
import CalendarLabel from './CalendarLabel'
import GreyLabel from './GreyLabel'
import { SubtaskDescription } from './SubtaskDescription'

export interface CardTitleSpotProps {
  subtask: Subtask
  viewMode: ViewMode | undefined
  taskTitle: string
  formattedDate: string
}
export const CardTitleSpot = (props: CardTitleSpotProps) => {
  const { isMentor } = useContext(MentorContext)

  return (
    <Grid container textAlign={'right'} sx={{ fontWeight: 400, fontSize: '0.9em' }}>
      <SubtaskDescription subtask={props.subtask} />
      {props.subtask.difficulty && (
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '0.5em' }}>
          {!isMentor && (
            <GreyLabel>
              <>
                <span>{difficultyToValue[props.subtask.difficulty]}</span>
                <StarOutline fontSize="small" />
              </>
            </GreyLabel>
          )}
          <GreyLabel>
            {props.viewMode === ViewMode.Date ? (
              <span>{props.taskTitle}</span>
            ) : (
              <CalendarLabel formattedDate={props.formattedDate} />
            )}
          </GreyLabel>
        </Grid>
      )}
    </Grid>
  )
}
