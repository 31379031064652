import UnsupportedBrowser from 'pages/ErrorPages/UnsupportedBrowser'
import { MobileHome } from 'pages/Home/MobileHome/MobileHome'
import ReinforcementsPage from 'pages/Reinforcements/ReinforcementsPage'
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Achievements } from '../pages/Achievements/Achievements'
import { AdminPanel } from '../pages/AdminPanel/AdminPanel'
import { Forbidden } from '../pages/ErrorPages/Forbidden'
import { Home } from '../pages/Home'
import { Login } from '../pages/Login'
import { MentorDashboard } from '../pages/MentorHome/MentorDashboard'
import { AddLinkToHomePage } from '../pages/Onboarding/AddLinkToHomePage'
import { OnboardingOTP } from '../pages/Onboarding/OnboardingOTP'
import { Profile } from '../pages/Profile/Profile'
import { Questionnaire } from '../pages/Questionnaire/Questionnaire'
import { QuestionnaireEfficiency } from '../pages/Questionnaire/QuestionnaireEfficiency'
import { ProtectedAdminRoleRoute } from './ProtectedAdminRoleRoute'
import { ProtectedMentorRoleRoute } from './ProtectedMentorRoleRoute'
import { ProtectedRoute } from './ProtectedRoute'
import { ProtectedUserRoleRoute } from './ProtectedUserRoleRoute'
import { paths } from './paths'

export const RootRouter: React.FC = () => {
  function isBrowserSupported() {
    return window.Promise && window.fetch
  }

  if (!isBrowserSupported()) {
    return <UnsupportedBrowser />
  }

  return (
    <Routes>
      <Route path={paths.LOGIN} element={<Login />} />
      <Route path="/" element={<ProtectedRoute />}>
        {/* All routes that require auth should be here */}
        <Route path={'/'} element={<Home />} />
        <Route path={paths.HOME} element={<Home />} />
        <Route path="/" element={<ProtectedUserRoleRoute />}>
          {/* All routes that require User permissions should be here */}
          <Route path={paths.MOBILE_HOME} element={<MobileHome />} />
          <Route path={paths.PROFILE} element={<Profile />} />
          <Route path={paths.QUESTIONS} element={<Questionnaire />} />
          <Route path={paths.QUESTIONNAIRE_EFFICIENCY} element={<QuestionnaireEfficiency />} />
          <Route path={paths.ACHIEVEMENTS} element={<Achievements />} />
          <Route path={paths.ONBOARDING_OTP} element={<OnboardingOTP />} />
          <Route path={paths.AddLinkToHomePage} element={<AddLinkToHomePage />} />
          <Route path={paths.REINFORCEMENTS} element={<ReinforcementsPage />} />
        </Route>
        <Route path="/" element={<ProtectedMentorRoleRoute />}>
          {/* All routes that require Mentor permissions should be here */}
          <Route path={paths.MENTOR_HOME} element={<MentorDashboard />} />
        </Route>
        <Route path="/" element={<ProtectedAdminRoleRoute />}>
          {/* All routes that require Admin permissions should be here */}
          <Route path={paths.ADMIN_PANEL} element={<AdminPanel />} />
          <Route path={paths.MOBILE_HOME} element={<MobileHome />} />
          <Route path={paths.PROFILE} element={<Profile />} />
          <Route path={paths.QUESTIONS} element={<Questionnaire />} />
          <Route path={paths.ACHIEVEMENTS} element={<Achievements />} />
          <Route path={paths.MENTOR_HOME} element={<MentorDashboard />} />
        </Route>
      </Route>

      <Route path="*" element={<Forbidden />} />
    </Routes>
  )
}
