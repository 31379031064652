import { Grid } from '@mui/material'
import Box from '@mui/material/Box'
import { DifficultyToColor, DifficultyToValue, Subtask } from 'api/dtos'
import { ViewMode } from 'pages/Home/MobileHome/MobileHome'
import { SubtaskCard } from './SubtaskCard'

export const difficultyToColor: DifficultyToColor = {
  EASY: '#B6F0B1',
  MEDIUM: '#F4EAB5',
  HARD: '#E8C1F6',
}

export const difficultyToValue: DifficultyToValue = {
  EASY: 1,
  MEDIUM: 2,
  HARD: 3,
}

const SubtaskCardItem = ({
  subtask,
  onOpenDeleteDialog,
  taskId,
  userId,
  viewMode,
  taskTitle,
}: {
  subtask: Subtask
  onOpenDeleteDialog: (subtaskId: number) => void
  taskId: number
  userId: number
  viewMode?: ViewMode
  taskTitle: string
}) => (
  <Box>
    <SubtaskCard
      key={subtask.id}
      subtask={subtask}
      onOpenDeleteDialog={onOpenDeleteDialog}
      taskId={taskId}
      userId={userId}
      viewMode={viewMode}
      taskTitle={taskTitle}
    />
  </Box>
)
export const SubtaskSection = ({
  subtasks,
  onOpenDeleteDialog,
  taskId,
  userId,
  viewMode,
  taskTitle,
}: {
  subtasks: Subtask[]
  onOpenDeleteDialog: (subtaskId: number) => void
  taskId: number
  userId: number
  viewMode?: ViewMode
  taskTitle: string
}) => {
  return (
    <Grid container justifyContent={'center'} sx={{ backgroundColor: 'white' }}>
      {subtasks.map((subtask, id) => (
        <Grid
          item
          xs={11}
          key={id}
          sx={{
            border: viewMode === ViewMode.Date ? '1px solid #E5E5E5' : 'none',
            borderBottom: '1px solid #E5E5E5',
            padding: '0.3em',
            borderRadius: viewMode === ViewMode.Date ? '10px' : 'none',
          }}
        >
          <SubtaskCardItem
            subtask={subtask}
            onOpenDeleteDialog={onOpenDeleteDialog}
            taskId={taskId}
            userId={userId}
            viewMode={viewMode}
            taskTitle={taskTitle}
          />
        </Grid>
      ))}
    </Grid>
  )
}
