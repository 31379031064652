import { Box, Button, Typography } from '@mui/material'
import { UserOTPRequestDto } from 'api/dtos'
import { useTranslation } from 'react-i18next'
import { refreshOTPAsync, useAppDispatch } from 'store'

interface PendingInviteItemProps {
  invite: UserOTPRequestDto
}

export const PendingInvitesItems = (props: PendingInviteItemProps) => {
  const { invite } = props
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const formatDate = (date: string) => {
    const d = new Date(date)
    return `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${d.getFullYear()}`
  }

  return (
    <Box
      sx={{
        border: '1px solid #ccc',
        borderRadius: '0.5em',
        padding: '1em',
        margin: '1em 0',
        position: 'relative',
        width: '90dvw',
        alignSelf: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          gap: '0.2em',
          width: '100%',
        }}
      >
        <Typography
          variant="h6"
          sx={{ fontSize: '1rem', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '50%' }}
        >
          {invite.user.name}
        </Typography>
        <Typography variant="body1" color="textSecondary" sx={{ fontSize: '1rem' }}>
          {invite.user.phoneNumber ?? 'לא צוין'}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {formatDate(invite.createdAt.toLocaleString())} נשלחה בתאריך
        </Typography>
      </Box>
      <Button
        variant="outlined"
        sx={{
          position: 'absolute',
          top: '0.5em',
          left: '0.5em',
          borderColor: 'orange',
          color: 'black',
          fontSize: '1em',
          borderRadius: '0.5em',
        }}
        onClick={() => {
          if (invite.user.id !== undefined) {
            dispatch(refreshOTPAsync(invite.user.id))
          } else {
            alert('שגיאה!')
          }
        }}
      >
        {t('inviteUsers.sendAgain')}
      </Button>
    </Box>
  )
}
