import { Box, Typography } from '@mui/material'
import { MenteeDto } from 'api/dtos'
import { Buffer } from 'buffer'
import { DEFAULT_AVATAR_BASE } from 'common/consts'
import ProfilePictureCircle from 'common/icons/common/ProfilePictureCircle'
import OnABreakIndicator from 'components/OnABreakIndicator'

interface MenteeTopSectionProps {
  mentee: MenteeDto
}

export const MenteeTopSection = (props: MenteeTopSectionProps) => {
  let base64String = DEFAULT_AVATAR_BASE

  if (props.mentee.profilePictureBytea != null) {
    base64String = Buffer.from(props.mentee.profilePictureBytea).toString('base64')
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <ProfilePictureCircle imageUrl={`data:image/jpeg;base64,${base64String}`} />
      <Typography
        component="h1"
        variant="h5"
        style={{
          width: '100%',
          textAlign: 'center',
          fontWeight: '400',
          fontSize: '1.5rem',
          marginTop: '0.5em',
        }}
      >
        {props.mentee.name}
      </Typography>
      {props.mentee.stopAllTimeStamp != null && <OnABreakIndicator />}
    </Box>
  )
}
