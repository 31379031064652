import React from 'react'
import {Box} from "@mui/material";

export const YoutubeEmbed = ({ videoUrl }: { videoUrl: string | null | undefined }) => {
  return (
    <Box>
      <iframe
        src={videoUrl?.replace('watch?v=', 'embed/') || ''}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </Box>
  )
}
