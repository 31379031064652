import { Button } from '@mui/material'

interface InviteNewMenteeButtonProps {
  handleInviteMentee: () => void
  isMobile: boolean
}

export const InviteNewMenteeButton = ({ handleInviteMentee, isMobile }: InviteNewMenteeButtonProps) => {
  return (
    <Button
      onClick={handleInviteMentee}
      sx={{ width: isMobile ? '90%' : '40%', margin: '0 auto', display: 'block' }}
      variant="contained"
    >
      הזמנת מתאמנ/ת חדש/ה
    </Button>
  )
}
