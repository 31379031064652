import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { storageUtil } from 'utils'
import { apiRequests } from '../api'
import { Notification, UpdateNotificationRequest } from '../api/dtos'
import { RequestStatus } from '../common/types'
import { config } from '../config'
import { RootState } from './store'
export interface NotificationsState {
  notifications?: Notification[]
  status: RequestStatus
  error?: string
}

const initialState: NotificationsState = {
  notifications: [],
  status: RequestStatus.Idle,
  error: undefined,
}

export const listNotificationsAsync = createAsyncThunk('notifications/list', async (): Promise<Notification[]> => {
  const response = await apiRequests.listNotifications()
  storageUtil.set(config.storage.NOTIFICATIONS_KEY, response)
  return response
})

export const updateNotificationAsync = createAsyncThunk(
  'notifications/update',
  async ({ notificationIds, status }: UpdateNotificationRequest, { dispatch }) => {
    const response = await apiRequests.updateNotification({ notificationIds, status })
    if (response) {
      dispatch(listNotificationsAsync())
    }
  },
)
export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder

      .addCase(listNotificationsAsync.pending, state => {
        state.status = RequestStatus.Loading
      })
      .addCase(listNotificationsAsync.fulfilled, (state, action) => {
        state.status = RequestStatus.Idle
        state.notifications = action.payload
      })
      .addCase(listNotificationsAsync.rejected, (state, action) => {
        state.error = action.error.message
        state.status = RequestStatus.Failed
      })
  },
})

export const selectNotificationListState = (state: RootState) => state.notifications
export const notificationsReducer = notificationsSlice.reducer
