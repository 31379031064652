import { Box, Button, Card, Grid, Modal, Typography, useMediaQuery } from '@mui/material'
import { AnswerQuestionnaireRequest, CreateUserQuestionAndAnswerDto, UserQuestionAnswer } from 'api/dtos'
import ProcrastinationTypeResult from 'pages/Profile/ProcrastinationTypeResult'
import { info_list } from 'pages/Profile/Profile'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { paths } from 'routes'
import {
  answerQuestionnaireAsync,
  getProcrastinationResultsAsync,
  listQuestionnairesAsync,
  selectQuestionnaireListState,
  selectUserState,
  useAppDispatch,
  useAppSelector,
} from '../../store'
import { QuestionInteraction } from './QuestionInteraction'
import { CenterAbsoluteStyledGridBottom, QuestionnaireButton } from './tools'

export const Questionnaire = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { user, procrastinations } = useAppSelector(selectUserState)
  const questionnairesState = useAppSelector(selectQuestionnaireListState)
  const [resultsModal, setResultsModal] = useState(false)
  const modalRef = useRef<HTMLDivElement>(null)

  const [questionAnswers, setQuestionAnswers] = useState<UserQuestionAnswer[]>([])

  const questionnaires = questionnairesState.questionnaires || []
  const questionnaire = (questionnaires && questionnaires[0]) || undefined
  const questions = useMemo(() => (questionnaire && questionnaire.questions) || [], [questionnaire])
  const title = (
    <Typography variant="h5" sx={{ fontWeight: 400, direction: 'rtl', fontSize: '1.2em' }}>
      {t('questionnaire.littleAbout')}
    </Typography>
  )

  useEffect(() => setQuestionAnswers(questions.map(q => UserQuestionAnswer.THREE)), [questions])
  useEffect(() => {
    dispatch(listQuestionnairesAsync())
  }, [dispatch])

  const isMobile = useAppSelector(state => state?.layout.isMobileView)
  const isSmallMobileHeight = useMediaQuery('@media (max-height: 700px)')
  const cont = () => submitForm()
  const questionaireMobileFinished = isMobile ? t('questionnaire.canContinue') : t('questionnaire.finished')
  const nextButton = <QuestionnaireButton onClick={cont}>{questionaireMobileFinished}</QuestionnaireButton>

  const getQuestionAnswers: (userId: number) => AnswerQuestionnaireRequest = userId => {
    let answers: CreateUserQuestionAndAnswerDto[] = []
    if (questionAnswers.length !== questions.length) {
      answers = []
    } else {
      answers = questions.map((q, i) => ({
        userId: userId,
        questionId: q.id,
        answer: questionAnswers[i],
      }))
    }
    return {
      userId,
      answers,
    }
  }
  const submitForm = async () => {
    try {
      await dispatch(answerQuestionnaireAsync(getQuestionAnswers(user.id)))
      await dispatch(getProcrastinationResultsAsync(user.id))
      setResultsModal(true)
    } catch (e) {
      console.error('Error submiting form', e)
    }
  }

  const questionBody = (
    <Grid item xs={12} marginRight="-2em" marginTop={'4em'}>
      <Grid container spacing={1} direction="row">
        <Grid item xs={1} />
        <Grid item xs={10}>
          <QuestionInteraction
            questionAnswers={questionAnswers}
            setQuestionAnswers={setQuestionAnswers}
            questions={questions}
          />
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </Grid>
  )

  const handleCloseModal = () => {
    setResultsModal(false)
    isMobile ? navigate(paths.AddLinkToHomePage) : navigate(paths.MOBILE_HOME)
  }

  // Accessibility: Focus management
  useEffect(() => {
    if (resultsModal) {
      modalRef.current?.focus()
    }
  }, [resultsModal])

  return (
    <Grid
      style={{
        height: '100vh',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        padding: '1em',
        overflowX: 'hidden',
      }}
    >
      <Grid item xs={12} textAlign={'right'} marginTop={isSmallMobileHeight ? '0' : '2.5em'} paddingRight="0.5em">
        {title}
      </Grid>
      {questionBody}
      <CenterAbsoluteStyledGridBottom item xs={12}>
        {nextButton}
      </CenterAbsoluteStyledGridBottom>
      <Modal
        open={resultsModal}
        onClose={handleCloseModal}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}
        aria-labelledby="results-modal-title"
        aria-describedby="results-modal-description"
      >
        <Card
          ref={modalRef}
          tabIndex={-1}
          dir="rtl"
          style={{
            direction: 'rtl',
            padding: '1em',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: isMobile ? '95%' : '50%',
            borderRadius: '10px',
          }}
        >
          <Typography variant="h5">{t('questionnaire.results')}</Typography>
          <br />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Typography mb={1} variant="body1" dir="rtl" padding={1}>
              {t('questionnaire.finished')}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                gap: '0.5em',
                justifyContent: 'center',
              }}
            >
              <ProcrastinationTypeResult
                name={'ציפייה'}
                progress={Math.floor((procrastinations?.expectation || 0) * 100)}
                info={info_list[0]}
              />
              <ProcrastinationTypeResult
                name={'ערך'}
                progress={Math.floor((procrastinations?.value || 0) * 100)}
                info={info_list[1]}
              />
              <ProcrastinationTypeResult
                name={'זמן'}
                progress={Math.floor((procrastinations?.time || 0) * 100)}
                info={info_list[2]}
              />
            </Box>
          </Box>
          <Button variant={'contained'} onClick={handleCloseModal} size="large" sx={{ width: '40%' }}>
            {t('questionnaire.close')}
          </Button>
        </Card>
      </Modal>
    </Grid>
  )
}
