export enum Status {
  INPROGRESS = 'INPROGRESS',
  DONE = 'DONE',
}

export interface Subtask {
  id: number
  description: string
  taskId: number
  difficulty: Difficulty
  deadline: Date
  startedTime?: Date
  endTime?: Date
  status?: Status
}

export interface CreateSubtaskRequest {
  uuid?: string
  description: string
  taskId: number
  difficulty: Difficulty
  deadline: Date
}

export interface PartialCreateSubtaskRequest {
  uuid: string
  description?: string
  difficulty?: Difficulty
  deadline?: Date | null
}

export interface SubtaskActionRequest {
  id: number
  userId: number
}

export type DeleteSubtaskRequest = SubtaskActionRequest
export type CompleteSubtaskRequest = SubtaskActionRequest

export interface UpdateSubtaskRequest {
  id: number
  description?: string
  taskId?: number
  deadline?: Date
  status?: Status
  userId: number
  difficulty?: Difficulty
}

export interface DifficultyToColor {
  ['EASY']: string
  ['MEDIUM']: string
  ['HARD']: string
}

export interface DifficultyToValue {
  ['EASY']: number
  ['MEDIUM']: number
  ['HARD']: number
}

export type Difficulty = keyof DifficultyToColor & keyof DifficultyToValue
