import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { storageUtil } from 'utils'
import { apiRequests } from '../api'
import { CreateQuestionRequest, Question, UpdateQuestionRequest } from '../api/dtos'
import { RequestStatus } from '../common/types'
import { config } from '../config'
import { RootState } from './store'

export interface QuestionsState {
  questions?: Question[]
  status: RequestStatus
  error?: string
}

const initialState: QuestionsState = {
  questions: [],
  status: RequestStatus.Idle,
  error: undefined,
}

export const listQuestionsAsync = createAsyncThunk('questions/list', async (): Promise<Question[]> => {
  const response = await apiRequests.listQuestions()
  storageUtil.set(config.storage.QUESTIONNAIRE_KEY, response)
  return response
})

export const createQuestionAsync = createAsyncThunk(
  'questions/create',
  async ({ statement, procrastinationClass, questionnaireId }: CreateQuestionRequest, { dispatch }) => {
    const response = await apiRequests.createQuestion({ statement, procrastinationClass, questionnaireId })
    if (response) {
      dispatch(listQuestionsAsync())
    }
  },
)

export const updateQuestionAsync = createAsyncThunk(
  'questions/update',
  async ({ id, statement, procrastinationClass }: UpdateQuestionRequest, { dispatch }) => {
    const response = await apiRequests.updateQuestion({ id, statement, procrastinationClass })
    if (response) {
      dispatch(listQuestionsAsync())
    }
  },
)
export const deleteQuestionAsync = createAsyncThunk('questions/delete/', async (id: number, { dispatch }) => {
  const response = await apiRequests.deleteQuestion(id)
  if (response) {
    dispatch(listQuestionsAsync())
  }
})

export const questionsSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder

      .addCase(listQuestionsAsync.pending, state => {
        state.status = RequestStatus.Loading
      })
      .addCase(listQuestionsAsync.fulfilled, (state, action) => {
        state.status = RequestStatus.Idle
        state.questions = action.payload
      })
      .addCase(listQuestionsAsync.rejected, (state, action) => {
        state.error = action.error.message
        state.status = RequestStatus.Failed
      })
  },
})

export const selectQuestionListState = (state: RootState) => state.questions
export const questionsReducer = questionsSlice.reducer
