import { ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material'
import { SubtaskForm } from 'pages/TasksPage/TaskCreation/SubtaskForm'
import { SharedSubtaskContext } from 'pages/TasksPage/UseSharedSubtaskContext'
import React, { useContext, useEffect, useRef } from 'react'

export const SubtaskCreationForm: React.FC = () => {
  const { subtasksToCreate, expandedUuid, setExpandedUuid, stepTextFieldValues } = useContext(SharedSubtaskContext)
  const accordionRefs = useRef<(HTMLDivElement | null)[]>([])

  useEffect(() => {
    if (expandedUuid) {
      const index = subtasksToCreate.findIndex(s => s.uuid === expandedUuid)
      if (index !== -1 && accordionRefs.current[index]) {
        accordionRefs.current[index]?.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [expandedUuid, subtasksToCreate])

  return (
    <Grid container item xs={12} direction="column">
      {subtasksToCreate.map((s, i) => (
        <Accordion
          key={s.uuid}
          expanded={expandedUuid === s.uuid}
          onChange={() => {
            setExpandedUuid(expandedUuid === s.uuid ? null : s.uuid)
          }}
          ref={el => (accordionRefs.current[i] = el)}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel-content"
            id="panel-header"
            sx={{ textAlign: 'right', direction: 'rtl' }}
          >
            <Typography>{stepTextFieldValues[i] || ''}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SubtaskForm key={s.uuid} uuid={s.uuid} />
          </AccordionDetails>
        </Accordion>
      ))}
    </Grid>
  )
}
