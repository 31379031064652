import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { storageUtil } from 'utils'
import { apiRequests } from '../api'
import { CompleteSubtaskRequest, DeleteSubtaskRequest, Difficulty, Subtask, UpdateSubtaskRequest } from '../api/dtos'
import { RequestStatus } from '../common/types'
import { config } from '../config'
import { RootState } from './store'
import { listTasksAsync } from './tasks-slice'

export interface SubtasksState {
  subtasks?: Subtask[]
  status: RequestStatus
  error?: string
}

const initialState: SubtasksState = {
  subtasks: [],
  status: RequestStatus.Idle,
  error: undefined,
}

export const listSubtasksAsync = createAsyncThunk('subtasks/list', async (): Promise<Subtask[]> => {
  const response = await apiRequests.listSubtasks()
  storageUtil.set(config.storage.SUBTASKS_KEY, response)
  return response
})

export const createSubtaskAsync = createAsyncThunk(
  'subtasks/create',
  async (
    {
      description,
      taskId,
      difficulty,
      deadline,
    }: {
      description: string
      taskId: number
      difficulty: Difficulty
      deadline: Date
    },
    { dispatch },
  ) => {
    const response = await apiRequests.createSubtask({ description, taskId, difficulty, deadline })
    if (response) {
      dispatch(listSubtasksAsync())
    }
  },
)

export const updateSubtaskAsync = createAsyncThunk(
  'subtasks/update',
  async (request: UpdateSubtaskRequest, { dispatch }) => {
    const response = await apiRequests.updateSubtask({ ...request })
    if (response) {
      dispatch(listTasksAsync(request.userId))
    }
  },
)
export const deleteSubtaskAsync = createAsyncThunk(
  'subtasks/delete/',
  async (req: DeleteSubtaskRequest, { dispatch }) => {
    const response = await apiRequests.deleteSubtask(req.id)
    if (response) {
      dispatch(listTasksAsync(req.userId))
    }
  },
)
export const completeSubtaskAsync = createAsyncThunk(
  'subtasks/done/',
  async (req: CompleteSubtaskRequest, { dispatch }) => {
    const response = await apiRequests.completeSubtask(req.id)
    if (response) {
      dispatch(listTasksAsync(req.userId))
    }
  },
)

export const subtasksSlice = createSlice({
  name: 'subtasks',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder

      .addCase(listSubtasksAsync.pending, state => {
        state.status = RequestStatus.Loading
      })
      .addCase(listSubtasksAsync.fulfilled, (state, action) => {
        state.status = RequestStatus.Idle
        state.subtasks = action.payload
      })
      .addCase(listSubtasksAsync.rejected, (state, action) => {
        state.error = action.error.message
        state.status = RequestStatus.Failed
      })
  },
})

export const selectSubtaskListState = (state: RootState) => state.subtasks
export const subtasksReducer = subtasksSlice.reducer
