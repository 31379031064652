import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import { Box, IconButton, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { selectUserState, useAppSelector } from 'store'
import mentorProfilePic from '../icons/mentorProfilePic.svg'

interface MentorNameWhatsAppProps {
  isFixed: boolean
}

const MentorNameWhatsApp: React.FC<MentorNameWhatsAppProps> = ({ isFixed }) => {
  const { t } = useTranslation()
  const { user, mentors } = useAppSelector(selectUserState)
  const myMentor = mentors?.find(value => value.id === user.mentorId)
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '0.3em',
        backgroundColor: '#F9F9F9',
        borderRadius: '5%',
        width: isFixed ? '70%' : '90%',
        position: isFixed ? 'fixed' : 'static',
        bottom: isFixed ? '2dvh' : 'auto',
        left: isFixed ? '1.5em' : 'auto',
        marginBottom: isFixed ? '0' : '2em',
        marginLeft: isFixed ? '0' : '1em',
        height: 'fit-content',
        gap: '0.5em',
        justifyContent: 'center',
      }}
    >
      <IconButton
        aria-label="send whatsapp message"
        sx={{ color: '#000', border: '1px solid #FFAB48', borderRadius: '10%' }}
        href={`https://wa.me/${myMentor?.phoneNumber}`} // phone number has to have full phone number in international format i.e +972
        target="_blank"
        rel="noopener noreferrer"
      >
        <WhatsAppIcon />
      </IconButton>
      <Box sx={{ textAlign: 'right', margin: '0 0.5em 0 1em' }}>
        <Typography variant="body2" fontSize={'0.75rem'}>
          {t('profile.yourMentor')}
        </Typography>
        <Typography variant="h6" fontSize={'1rem'}>
          {myMentor?.name ?? t('profile.NoMentorYet')}
        </Typography>
      </Box>
      <img alt={'avatar'} src={mentorProfilePic} width={40} height={40} />
    </Box>
  )
}

export default MentorNameWhatsApp
