export interface Question {
  id: number
  statement: string
  questionnaireId: number
  procrastinationClass: ProcrastinationClass
}

export interface CreateQuestionRequest {
  statement: string
  questionnaireId: number
  procrastinationClass: ProcrastinationClass
}

export interface DeleteQuestionRequest {
  id: number
}

export interface UpdateQuestionRequest {
  id: number
  statement: string
  procrastinationClass: ProcrastinationClass
}

export enum ProcrastinationClass {
  EXPECTATION = 'EXPECTATION',
  VALUE = 'VALUE',
  TIME = 'TIME',
}
