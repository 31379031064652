import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import { Box, Button, Paper, Typography } from '@mui/material'
import { Buffer } from 'buffer'
import { MenteeDto, Status, Task } from '../../api/dtos'
import { DEFAULT_AVATAR_BASE } from '../../common/consts'

function countStatusSubtasks(tasks: Task[], status: Status) {
  let count = 0
  tasks.forEach(task => {
    count += task.subtasks.filter(subtask => subtask.status === status).length
  })
  return count
}

export const MenteeCard = ({
  mentee,
  handleMenteeSelection,
}: {
  mentee: MenteeDto
  handleMenteeSelection: (mentee: MenteeDto) => void
}) => {
  let base64String = DEFAULT_AVATAR_BASE

  if (mentee.profilePictureBytea != null) {
    base64String = Buffer.from(mentee.profilePictureBytea).toString('base64')
  }

  const menteePhone = `https://wa.me/972${mentee.phoneNumber}?text=`

  const subtasksOnTime = countStatusSubtasks(mentee.tasks, Status.DONE)
  const subtasksInProgress = countStatusSubtasks(mentee.tasks, Status.INPROGRESS)

  return (
    <Paper
      elevation={3}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '100%',
        height: '23em',
        margin: '0 0 1em',
        borderRadius: '10px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          alignItems: 'center',
          flexGrow: 0.2,
          padding: '1em 0 1em 1em',
          height: '100%',
        }}
      >
        <Button variant={'contained'} onClick={() => handleMenteeSelection(mentee)}>
          הצג יותר
        </Button>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          flexGrow: 4,
          height: '100%',
          direction: 'rtl',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '1em',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexGrow: 2,
            width: '100%',
          }}
        >
          <Typography variant={'h5'} align={'center'}>
            משימות
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              margin: '0 0 1.5em 0',
            }}
          >
            <Box
              sx={{
                height: '1em',
                width: '1em',
                backgroundColor: '#75F27A',
                margin: '0 0.5em 0 0.5em',
              }}
            />
            <Typography variant={'body1'}>בוצעו בזמן</Typography>
            <Box
              sx={{
                height: '1em',
                width: '1em',
                backgroundColor: '#DCA1F1',
                margin: '0 0.5em 0 0.5em',
              }}
            />
            <Typography variant={'body1'}>בתהליך</Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Typography variant={'h6'} align={'right'}>
              השבוע:
            </Typography>

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 1em 0 0',
              }}
            >
              <Box
                sx={{
                  flexGrow: subtasksOnTime,
                  backgroundColor: '#75F27A',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {subtasksOnTime > 0 ? subtasksOnTime : null}
              </Box>
              <Box
                sx={{
                  flexGrow: subtasksInProgress,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#DCA1F1',
                }}
              >
                {subtasksInProgress > 0 ? subtasksInProgress : null}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            padding: '1em',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '1em',
              alignItems: 'center',
              justifyContent: 'flex-start',
              flexGrow: 1,
            }}
          >
            <Typography variant={'h5'}>כניסות השבוע</Typography>
            <Typography variant={'h6'}>{mentee.weekLoginStreak || 0}</Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '1em',
              alignItems: 'center',
              justifyContent: 'flex-start',
              flexGrow: 1,
            }}
          >
            <Typography variant={'h5'}>כניסות ברצף</Typography>
            <Typography variant={'h6'}>{mentee.loginDayStreak || 0}</Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '1em',
              alignItems: 'center',
              justifyContent: 'flex-start',
              flexGrow: 1,
            }}
          >
            <Typography variant={'h5'}>הפסקות</Typography>
            <Typography variant={'h6'}>{mentee.weeklyStopAllCount}</Typography>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexGrow: 0.5,
          width: 'auto',
          height: '100%',
          padding: '1em 0 0 0',
        }}
      >
        <img alt={'avatar'} src={`data:image/jpeg;base64,${base64String}`} width={75} height={75} />

        <Typography variant={'h5'}>{mentee.name}</Typography>
        <Typography variant={'body1'}>{mentee.email}</Typography>
        <Button
          onClick={() => window.open(menteePhone, '_blank')}
          color={'inherit'}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <WhatsAppIcon />
          <Typography variant={'body1'}>צור קשר</Typography>
        </Button>
      </Box>
    </Paper>
  )
}
