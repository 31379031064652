import { Box, Typography } from '@mui/material'
import { MenteeDto } from 'api/dtos'
import { ProcrastinationProfile } from 'components/ProcrastinationProfile'

export interface MenteeProfileTabProps {
  mentee: MenteeDto
}

export const MenteeProfileTab = (props: MenteeProfileTabProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        marginBottom: '1em',
      }}
    >
      <ProcrastinationProfile userProcrastinations={props.mentee.userProcrastinations} text="פרופיל דחיינות" />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: '100%',
          direction: 'rtl',
          mt: '1.5em',
          gap: '0.5em',
          p: '0.5em',
        }}
      >
        <Typography variant="h5" sx={{ fontSize: '1.25rem' }}>
          יצירת קשר
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.3em' }}>
          <Typography variant="h5" style={{ color: '#666', fontSize: '1rem' }}>
            מייל:
          </Typography>
          <Typography variant="h5" style={{ fontSize: '1rem', color: 'black' }}>
            {props.mentee.email?.toString()}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.3em' }}>
          <Typography variant="h5" style={{ color: '#666', fontSize: '1rem' }}>
            טלפון:
          </Typography>
          <Typography variant="h5" style={{ fontSize: '1rem', color: 'black' }}>
            {props.mentee.phoneNumber || 'אין מספר במערכת'}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
