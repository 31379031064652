import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { storageUtil } from 'utils'
import { apiRequests } from '../api'
import { Achievement } from '../api/dtos/achievement'
import { RequestStatus } from '../common/types'
import { config } from '../config'
import { RootState } from './store'

export interface AchievementsState {
  achievements?: Achievement[]
  status: RequestStatus
  error?: string
}

const initialState: AchievementsState = {
  achievements: [],
  status: RequestStatus.Idle,
  error: undefined,
}

export const listNAchievementsAsync = createAsyncThunk(
  'achievements/listN',
  async (numberOfAchievements: number): Promise<Achievement[]> => {
    const response = await apiRequests.listNAchievements(numberOfAchievements)
    storageUtil.set(config.storage.ACHIEVEMENTS_KEY, response)
    return response
  },
)

export const listAchievementsAsync = createAsyncThunk('achievements/list', async (): Promise<Achievement[]> => {
  const response = await apiRequests.listAchievements()
  storageUtil.set(config.storage.ACHIEVEMENTS_KEY, response)
  return response
})

export const getAchievementsAsync = createAsyncThunk('achievements/get', async (id: number): Promise<Achievement> => {
  const response = await apiRequests.getAchievement(id)
  return response
})

export const createAchievementAsync = createAsyncThunk(
  'achievements/create',
  async (
    {
      text,
      attached,
      userId,
    }: {
      text: string
      attached: string
      userId: number
    },
    { dispatch },
  ) => {
    const response = await apiRequests.createAchievement({ text, attached, userId })
    if (response) {
      dispatch(listUsersAchievementsAsync(userId))
    }
  },
)

export const deleteAchievementAsync = createAsyncThunk(
  'achievements/delete/',
  async (
    {
      id,
      userId,
    }: {
      id: number
      userId: number
    },
    { dispatch },
  ) => {
    const response = await apiRequests.deleteAchievement(id)
    if (response) {
      dispatch(listUsersAchievementsAsync(userId))
    }
  },
)

export const listUsersAchievementsAsync = createAsyncThunk(
  'achievements/usersList',
  async (userId: number): Promise<Achievement[]> => {
    const response = await apiRequests.listUsersAchievements(userId)
    storageUtil.set(config.storage.ACHIEVEMENTS_KEY, response)
    return response
  },
)

export const achievementsSlice = createSlice({
  name: 'achievements',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder

      .addCase(listAchievementsAsync.pending, state => {
        state.status = RequestStatus.Loading
      })
      .addCase(listAchievementsAsync.fulfilled, (state, action) => {
        state.status = RequestStatus.Idle
        state.achievements = action.payload
      })
      .addCase(listAchievementsAsync.rejected, (state, action) => {
        state.error = action.error.message
        state.status = RequestStatus.Failed
      })
      .addCase(listNAchievementsAsync.pending, state => {
        state.status = RequestStatus.Loading
      })
      .addCase(listNAchievementsAsync.fulfilled, (state, action) => {
        state.status = RequestStatus.Idle
        state.achievements = action.payload
      })
      .addCase(listNAchievementsAsync.rejected, (state, action) => {
        state.error = action.error.message
        state.status = RequestStatus.Failed
      })
      .addCase(listUsersAchievementsAsync.pending, state => {
        state.status = RequestStatus.Loading
      })
      .addCase(listUsersAchievementsAsync.fulfilled, (state, action) => {
        state.status = RequestStatus.Idle
        state.achievements = action.payload
      })
      .addCase(listUsersAchievementsAsync.rejected, (state, action) => {
        state.error = action.error.message
        state.status = RequestStatus.Failed
      })
  },
})

export const selectAchievementListState = (state: RootState) => state.achievements
export const achievementsReducer = achievementsSlice.reducer
