import { Box } from '@mui/material'
import { MenteeDto, User, UserOTPRequestDto } from 'api/dtos'
import { Dispatch, SetStateAction } from 'react'
import { AllMenteesHeader } from './MentorComponents/AllMenteesHeader'
import { InviteNewMenteeButton } from './MentorComponents/InviteNewMenteeButton'
import { MentorFragments } from './MentorDashboard'
import { MentorDashBoardMenteesList } from './MentorDashBoardMenteesList'
import { PendingInvites } from './PendingInvites'

interface MentorDashboardMenteesContentProps {
  currentPage: MentorFragments
  setAddNewMenteeModal: Dispatch<SetStateAction<boolean>>
  setCurrentPage: Dispatch<SetStateAction<MentorFragments>>
  pendingInvite?: UserOTPRequestDto[]
  setSelectedMentee: Dispatch<SetStateAction<MenteeDto | undefined>>
  selectedMentee: MenteeDto | undefined
  handleMenteeSelection: (user: MenteeDto | undefined) => void
  user: User
  isMobile: boolean
  selectedTab: 'mentees' | 'invitations'
  setSelectedTab: Dispatch<SetStateAction<'mentees' | 'invitations'>>
}

export const MentorDashboardMenteesContent = (props: MentorDashboardMenteesContentProps) => {
  const {
    currentPage,
    setAddNewMenteeModal,
    setCurrentPage,
    pendingInvite,
    setSelectedMentee,
    selectedMentee,
    handleMenteeSelection,
    user,
    isMobile,
    selectedTab,
    setSelectedTab,
  } = props
  const handleInviteMentee = () => {
    setAddNewMenteeModal(true)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '88dvh',
        width: '100%',
      }}
    >
      <AllMenteesHeader
        handleMenteeSelection={handleMenteeSelection}
        pendingInvite={pendingInvite}
        setCurrentPage={setCurrentPage}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      <Box sx={{ flexGrow: 1, height: '70dvh', marginBottom: '1em' }}>
        {currentPage === MentorFragments.Summary ? (
          <MentorDashBoardMenteesList
            setSelectedMentee={setSelectedMentee}
            selectedMentee={selectedMentee}
            handleMenteeSelection={handleMenteeSelection}
            user={user}
          />
        ) : (
          <PendingInvites pendingInvite={pendingInvite} />
        )}
      </Box>
      <Box>
        <InviteNewMenteeButton handleInviteMentee={handleInviteMentee} isMobile={isMobile} />
      </Box>
    </Box>
  )
}
