import { axiosInstance } from '../network'
import { ApiResponse } from '../types'
import { RequestConfig } from '../types/request-config'
import {SuccessCategories} from "../dtos/success";

const listSuccesses =  async (userId: number, config?: RequestConfig): Promise<SuccessCategories> => {
  const axiosResponse = await axiosInstance.get<never, ApiResponse<SuccessCategories>>('users/successes/' + userId, config)
  return axiosResponse.data!
}


export const successesApiRequest = {
  listSuccesses,
}
