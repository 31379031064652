import { Grid } from '@mui/material'
import Box from '@mui/material/Box'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import { styled } from '@mui/material/styles'
import { useAppSelector } from 'store'

const BorderLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
  height: '0.3em',
  borderRadius: '2em',
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    background: '#FFFFFF',
    borderRadius: '7px',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: '7px',
    backgroundColor: theme.palette.mode === 'light' ? '#6E1D93' : '#75F27A',
    transform: 'rotate(180deg)',
  },
}))

const TaskProgressBar = ({ value }: { value: number }) => {
  const isMobile = useAppSelector(state => state.layout.isMobileView)

  return (
    <Box sx={{ flexGrow: 1, marginLeft: '1em' }}>
      <Grid container>
        <Grid item xs={12}>
          <BorderLinearProgress sx={{ width: isMobile ? '70vw' : '30vw' }} variant="determinate" value={value} />
        </Grid>
      </Grid>
    </Box>
  )
}
export default TaskProgressBar
