import { useMediaQuery, useTheme } from '@mui/material'
import { useEffect } from 'react'
import { useAppDispatch } from 'store'
import { setMobileView } from 'store/layout-slice'

const useWindowSize = () => {
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    const handleResize = () => {
      const isMobileView = window.innerWidth <= theme.breakpoints.values.sm
      dispatch(setMobileView(isMobileView))
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [dispatch, theme.breakpoints.values.sm, isMobile])
}

export default useWindowSize
