import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { storageUtil } from 'utils'
import { apiRequests } from '../api'
import {
  AnswerQuestionnaireRequest,
  CreateQuestionnaireRequest,
  Questionnaire,
  UpdateQuestionnaireRequest,
} from '../api/dtos'
import { RequestStatus } from '../common/types'
import { config } from '../config'
import { RootState } from './store'

export interface QuestionnairesState {
  questionnaires?: Questionnaire[]
  status: RequestStatus
  error?: string
}

const initialState: QuestionnairesState = {
  questionnaires: [],
  status: RequestStatus.Idle,
  error: undefined,
}

export const listQuestionnairesAsync = createAsyncThunk('questionnaires/list', async (): Promise<Questionnaire[]> => {
  const response = await apiRequests.listQuestionnaires()
  storageUtil.set(config.storage.QUESTIONNAIRE_KEY, response)
  return response
})

export const createQuestionnaireAsync = createAsyncThunk(
  'questionnaires/create',
  async ({ name, questions }: CreateQuestionnaireRequest, { dispatch }) => {
    const response = await apiRequests.createQuestionnaire({ name, questions })
    if (response) {
      dispatch(listQuestionnairesAsync())
    }
  },
)

export const updateQuestionnaireAsync = createAsyncThunk(
  'questionnaires/update',
  async ({ id, name, questions }: UpdateQuestionnaireRequest, { dispatch }) => {
    const response = await apiRequests.updateQuestionnaire({ id, name, questions })
    if (response) {
      dispatch(listQuestionnairesAsync())
    }
  },
)
export const deleteQuestionnaireAsync = createAsyncThunk('questionnaires/delete/', async (id: number, { dispatch }) => {
  const response = await apiRequests.deleteQuestionnaire(id)
  if (response) {
    dispatch(listQuestionnairesAsync())
  }
})
export const answerQuestionnaireAsync = createAsyncThunk(
  'questionnaires/answer',
  async ({ userId, answers }: AnswerQuestionnaireRequest, { dispatch }) => {
    await apiRequests.answerQuestionnaire({ userId, answers })
  },
)

export const questionnairesSlice = createSlice({
  name: 'questionnaires',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(listQuestionnairesAsync.pending, state => {
        state.status = RequestStatus.Loading
      })
      .addCase(listQuestionnairesAsync.fulfilled, (state, action) => {
        state.status = RequestStatus.Idle
        state.questionnaires = action.payload
      })
      .addCase(listQuestionnairesAsync.rejected, (state, action) => {
        state.error = action.error.message
        state.status = RequestStatus.Failed
      })
  },
})

export const selectQuestionnaireListState = (state: RootState) => state.questionnaires
export const questionnairesReducer = questionnairesSlice.reducer
