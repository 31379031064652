import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface RemoveMenteePopUpProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  menteeName: string
}

export const RemoveMenteePopUp = ({ open, onClose, onConfirm, menteeName }: RemoveMenteePopUpProps) => {
  const { t } = useTranslation()
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title-RemoveMentee"
      aria-describedby="alert-dialog-description-RemoveMentee"
      fullWidth
      maxWidth="sm"
      sx={{
        direction: 'rtl',
        backgroundColor: '#000000a6',
        '& .MuiDialog-paper': {
          borderRadius: '5%',
          minWidth: '90dvw',
        },
      }}
    >
      <DialogTitle sx={{ p: '1em 0.5em 0.5em 0.5em' }}>להסיר את {menteeName}?</DialogTitle>
      <DialogContent sx={{ p: '1em' }}>
        <Typography>אחרי ההסרה, לא תהיה לך אפשרות להציג את הפרופיל או לעקוב אחרי הפעילות של {menteeName}.</Typography>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', padding: '1em', mb: '0.5em' }}>
        <Button onClick={onClose} color="primary" sx={{ width: '50%' }}>
          {t('button.cancel')}
        </Button>
        <Button onClick={onConfirm} color="primary" variant="contained" sx={{ width: '50%' }}>
          {t('button.remove')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
