import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { storageUtil } from 'utils'
import { apiRequests } from '../api'
import { CreateReinforcementRequest, Reinforcement, UpdateReinforcementRequest } from '../api/dtos'
import { RequestStatus } from '../common/types'
import { config } from '../config'
import { RootState } from './store'

export interface SelectedReinforcement {
  selected: boolean
  reinforcement: Reinforcement
}

export const getSelectedReinforcements: () => SelectedReinforcement[] = () => {
  const answer = storageUtil.get<SelectedReinforcement[]>(config.storage.SELECTED_REINFORCEMENTS_KEY)
  if (typeof answer === 'string' || answer === null) {
    return []
  }
  return answer
}

export interface ReinforcementsState {
  reinforcements?: Reinforcement[]
  reinforcement?: Reinforcement
  selectedReinforcements?: Reinforcement[]
  status: RequestStatus
  error?: string
  selectedReinforcementsObj: SelectedReinforcement[]
  allReinforcements: Reinforcement[]
}

const initialState: ReinforcementsState = {
  reinforcements: [],
  reinforcement: undefined,
  selectedReinforcements: [],
  status: RequestStatus.Idle,
  error: undefined,
  selectedReinforcementsObj: getSelectedReinforcements(),
  allReinforcements: []
}

export const listReinforcementsAsync = createAsyncThunk(
  'reinforcement/list',
  async (userId: number): Promise<Reinforcement[]> => {
    const response = await apiRequests.listReinforcements(userId)
    storageUtil.set(config.storage.REINFORCEMENTS_KEY, response)
    return response
  },
)

export const listAllReinforcementsAsync = createAsyncThunk(
  'allReinforcements/list',
  async (): Promise<Reinforcement[]> => {
    const response = await apiRequests.listAllReinforcements()
    storageUtil.set(config.storage.REINFORCEMENTS_KEY, response)
    return response
  },
)


export const setSelectedReinforcements = (reinforcements: SelectedReinforcement[]) => {
  storageUtil.set(config.storage.SELECTED_REINFORCEMENTS_KEY, reinforcements)
}

export const createReinforcementAsync = createAsyncThunk(
  'reinforcement/create',
  async ({ userId, text, url, type }: CreateReinforcementRequest, { dispatch }) => {
    const response = await apiRequests.createReinforcement({ userId, text, url, type })
    if (!response) {
      return;
    }
    if (userId === 0) {
      // Create by Admin panel
      dispatch(listAllReinforcementsAsync())
    } else {
      // Create by user
      dispatch(listReinforcementsAsync(userId))
    }
  },
)

export const updateReinforcementAsync = createAsyncThunk(
  'reinforcement/update',
  async ({ id, text, url, type, userId }: UpdateReinforcementRequest, { dispatch }) => {
    console.log({ id, text, url, type, userId })
    const response = await apiRequests.updateReinforcement({ id, text, url, type, userId })
    if (!response) {
      return;
    }
    if (userId === 0) {
      // Create by Admin panel
      dispatch(listAllReinforcementsAsync())
    } else {
      // Create by user
      dispatch(listReinforcementsAsync(userId))
    }
  },
)
export const deleteReinforcementAsync = createAsyncThunk(
  'reinforcement/delete/',
  async ({ id, userId }: { id: number; userId: number }, { dispatch }) => {
    const response = await apiRequests.deleteReinforcement(id)
    if (response) {
      dispatch(listReinforcementsAsync(userId))
    }
  },
)

export const randomReinforcementsAsync = createAsyncThunk(
  'reinforcement/random',
  async (userId: number): Promise<Reinforcement> => {
    return await apiRequests.randomReinforcement(userId)
  },
)

export const reinforcementsSlice = createSlice({
  name: 'reinforcements',
  initialState,
  reducers: {
    updateSelectedReinforcementsObj: (state, action) => {
      state.selectedReinforcementsObj = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(listReinforcementsAsync.pending, state => {
        state.status = RequestStatus.Loading
      })
      .addCase(listReinforcementsAsync.fulfilled, (state, action) => {
        state.status = RequestStatus.Idle
        state.reinforcements = action.payload
        state.selectedReinforcementsObj = action.payload.map(r => ({ reinforcement: r, selected: false }))
      })
      .addCase(listAllReinforcementsAsync.fulfilled, (state, action) => {
        state.allReinforcements = action.payload
      })
      .addCase(listReinforcementsAsync.rejected, (state, action) => {
        state.error = action.error.message
        state.status = RequestStatus.Failed
      })
      .addCase(randomReinforcementsAsync.pending, state => {
        state.status = RequestStatus.Loading
      })
      .addCase(randomReinforcementsAsync.fulfilled, (state, action) => {
        state.status = RequestStatus.Idle
        state.reinforcement = action.payload
        state.error = undefined
      })
      .addCase(randomReinforcementsAsync.rejected, (state, action) => {
        state.error = action.error.message
        state.status = RequestStatus.Failed
      })
  },
})

export const selectReinforcementListState = (state: RootState) => state.reinforcements
export const reinforcementsReducer = reinforcementsSlice.reducer
export const { updateSelectedReinforcementsObj } = reinforcementsSlice.actions
