import { Box } from '@mui/material'
import { useEffect } from 'react'
import { pendingInvitesAsync, selectUserState, useAppDispatch, useAppSelector } from '../../store'
import { EditProfile } from '../Profile/EditProfile'

export const MentorDashBoardSettings = () => {
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(selectUserState)

  useEffect(() => {
    if (user?.id) dispatch(pendingInvitesAsync(user.id))
  }, [dispatch, user?.id])

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '80vh',
        flexGrow: 10,
        padding: '1em',
        alignItems: 'center',
        overflowY: 'auto',
        backgroundColor: '#ECECEC',
      }}
    >
      <EditProfile />
    </Box>
  )
}
