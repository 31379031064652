import { Grid } from '@mui/material'
import { Subtask } from 'api/dtos'
import { SetState } from 'common/types/types'
import { createContext, useMemo } from 'react'
import { selectUserState, useAppSelector } from 'store'
import { SharedSubtaskContext, SharedSubtaskContextType } from '../UseSharedSubtaskContext'
import { SubtaskTopHalf } from './Card/SubtaskTopHalf'
import { SubtaskSection } from './SubtaskSection'

export const EditTaskContext = createContext<{
  title: string
  subtasks: Subtask[]
  taskId: number
  userId: number
}>({
  title: '',
  subtasks: [],
  taskId: 0,
  userId: 0,
})

const cardGridItem = (children: JSX.Element) => (
  <Grid item xs={10.5} marginRight="1.5em">
    {children}
  </Grid>
)

export const TaskCardContents = ({
  title,
  arrowDirection,
  setArrowDirection,
  subtasks,
  taskId,
  taskProgress,
  onOpenDeleteDialog,
  userId,
  sharedSubtaskContext,
}: {
  title: string
  arrowDirection: string
  setArrowDirection: SetState<string>
  subtasks: Subtask[]
  taskId: number
  taskProgress: number
  onOpenDeleteDialog: (subtaskId: number) => void
  userId: number
  sharedSubtaskContext: SharedSubtaskContextType
}) => {
  const { user } = useAppSelector(selectUserState)

  const taskContextValue = useMemo(() => ({ title, subtasks, taskId, userId }), [title, subtasks, taskId, userId])

  return (
    <EditTaskContext.Provider value={taskContextValue}>
      <SharedSubtaskContext.Provider value={sharedSubtaskContext}>
        <Grid
          sx={{
            background: '#F9F9F9',
            border: '2px solid #F1F1F1',
            borderRadius: '0.5em',
          }}
          container
          justifyContent={'center'}
        >
          {cardGridItem(
            <SubtaskTopHalf
              progressBarValue={taskProgress}
              arrowDirection={arrowDirection}
              setArrowDirection={setArrowDirection}
            />,
          )}
          {arrowDirection === 'down' && (
            <SubtaskSection
              subtasks={subtasks}
              onOpenDeleteDialog={onOpenDeleteDialog}
              taskId={taskId}
              userId={user.id}
              taskTitle={title}
            />
          )}
        </Grid>
      </SharedSubtaskContext.Provider>
    </EditTaskContext.Provider>
  )
}
