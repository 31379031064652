const ProfilePictureCircle = ({ imageUrl }: { imageUrl: string }) => {
  return (
    <div
      style={{
        width: '10em',
        height: '10em',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <img
        src={imageUrl}
        alt="round profile"
        style={{
          width: '100%',
          height: '100%',
          borderRadius: '50%',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      />
    </div>
  )
}

export default ProfilePictureCircle
