import NotificationsIcon from '@mui/icons-material/Notifications'
import { Badge, Box, Button } from '@mui/material'
import { NotificationStatus, Role } from 'api/dtos'
import { MentorFragments } from 'pages/MentorHome/MentorDashboard'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { listNotificationsAsync, selectNotificationListState, useAppDispatch, useAppSelector } from 'store'
import { Banner } from './Banner'
import Hamburger, { HamburgerProps } from './Hamburger'
import { Profile } from './Profile'

interface TopMenueProps extends HamburgerProps {
  setCurrentPage?: Dispatch<SetStateAction<MentorFragments>>
  setMentorNotificationModal?: Dispatch<SetStateAction<boolean>>
  userRole: Role
  setSelectedTab?: Dispatch<SetStateAction<'mentees' | 'invitations'>>
}

export const TopMenu = ({
  setCurrentPage,
  setMentorNotificationModal,
  userRole,
  children,
  setSelectedTab,
}: TopMenueProps) => {
  const { notifications } = useAppSelector(selectNotificationListState)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (userRole === Role.Mentor) {
      dispatch(listNotificationsAsync())
    }
  }, [dispatch, userRole])

  const handleNotificationModalPage = () => {
    if (userRole === Role.Mentor) {
      setCurrentPage?.(MentorFragments.Notifications)
      setMentorNotificationModal?.(true)
    }
  }

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '1em 1em 1.5em 1em',
        background: '#FAFAFA',
        height: '8vh',
        width: '100%',
      }}
    >
      <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
        <Profile setCurrentPage={setCurrentPage} />
        {userRole === Role.Mentor && (
          <Button color={'inherit'} onClick={handleNotificationModalPage}>
            <Badge
              badgeContent={
                notifications?.filter(notification => NotificationStatus.Created === notification.status).length
              }
              color="primary"
              max={99}
            >
              <NotificationsIcon fontSize={'medium'} />
            </Badge>
          </Button>
        )}
      </Box>

      <Banner userRole={userRole} setCurrentPage={setCurrentPage} setSelectedTab={setSelectedTab} />

      <Hamburger>{children}</Hamburger>
    </Box>
  )
}
