import Box from '@mui/material/Box'
import { useCallback, useEffect } from 'react'
// import { useNavigate } from 'react-router-dom'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { Button, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { getProcrastinationResultsAsync, logout, selectUserState, useAppDispatch, useAppSelector } from '../../store'
import { listUsersAchievementsAsync, selectAchievementListState } from '../../store/achievements-slice'
import { EditProfile } from './EditProfile'

export const info_list = [
  'אני דוחה דברים כי אני מניח מראש שאכשל בהם',
  'אני דוחה כל דבר שלא באמת מעניין אותי',
  'אני דוחה דברים לרגע האחרון, עד שלא מגיע הדד ליין לא אעשה מה שהתחייבתי',
]

export const Profile = () => {
  const { error, status, user, procrastinations } = useAppSelector(selectUserState) // eslint-disable-line @typescript-eslint/no-unused-vars
  const { achievements } = useAppSelector(selectAchievementListState) // eslint-disable-line @typescript-eslint/no-unused-vars
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(listUsersAchievementsAsync(user.id))
    dispatch(getProcrastinationResultsAsync(user.id))
  }, [user.id, dispatch])

  const navigate = useNavigate()
  const logOut = useCallback(() => {
    dispatch(logout())
  }, [dispatch])

  return (
    <div>
      <Grid
        container
        direction="column"
        textAlign={'right'}
        alignItems="center"
        justifyContent="center"
        sx={{ background: '#F1F1F1', padding: '1em' }}
      >
        <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
          <Grid item xs={5} sx={{ textAlign: 'center' }}>
            <Button
              aria-label="Logout"
              endIcon={<ExitToAppIcon />}
              onClick={logOut}
              variant="outlined"
              size="large"
              sx={{ color: 'black', border: '1px solid #FFAB48', borderRadius: '0.5em' }}
            >
              התנתקות
            </Button>
          </Grid>
          <Grid
            container
            item
            xs={5}
            justifyContent={'flex-end'}
            onClick={() => {
              navigate(-1)
            }}
          >
            <Typography variant="body1" component="span">
              הפרופיל שלך
            </Typography>
            <ArrowForwardIcon sx={{ marginLeft: '0.5em' }} />
          </Grid>
        </Grid>
      </Grid>

      <Box
        sx={{
          width: '95%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: '0.5em',
        }}
      >
        <EditProfile />
      </Box>
    </div>
  )
}
