import { Box, Button, Grid } from '@mui/material'
import { resumeUserActivityAsync, selectUserState, useAppDispatch, useAppSelector } from 'store'
import OnABreakIcon from './OnABreak/OnABreakIcon'

const OnABreak = () => {
  const { user } = useAppSelector(selectUserState)
  const dispatch = useAppDispatch()
  const goBackToNormal = () => {
    dispatch(resumeUserActivityAsync(user.id))
  }
  return (
    <Box
      style={{
        position: 'absolute',
        background: '#FFFFFF',
        borderRadius: '1em',
        boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.08)',
        textAlign: 'right',
        left: '4.72%',
        right: '4.44%',
        top: '19.88%',
        bottom: '22.62%',
      }}
    >
      <Grid container padding="1.5em">
        <Grid item xs={12}>
          <Box style={{ fontSize: '2em' }}>{'אנחנו בהפסקה'}</Box>
        </Grid>
        <Grid item>
          <Box style={{ fontSize: '1em' }}>
            {'כל המשימות מושהות כרגע. כשאתם מרגישים מוכנים, לחצו למטה על מנת לחזור לפעילות רגילה.'}
          </Box>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <OnABreakIcon />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center' }} padding="2em">
          <Button
            variant="contained"
            size="large"
            style={{ boxShadow: '0px 0px 9px rgba(45, 45, 45, 0.25)', borderRadius: '0.69em' }}
          >
            <Box
              onClick={goBackToNormal}
              style={{ opacity: '0.75', textShadow: '0px 0px 9px rgba(45, 45, 45, 0.25)', lineHeight: '2em' }}
            >
              {'חזרה לפעילות'}
            </Box>
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default OnABreak
