import '@fontsource/varela-round'
import { createTheme } from '@mui/material/styles'

const orange = {
  palette: {
    primary: {
      main: '#FFAB48',
    },
  },
  typography: {
    fontFamily: 'Varela Round',
    fontStyle: 'normal',
    fontWeight: '400',
  },
}

export const theme = createTheme(orange)
