import { Box } from '@mui/material'
import { Role } from 'api/dtos'
import { MentorFragments } from 'pages/MentorHome/MentorDashboard'
import { Dispatch, SetStateAction } from 'react'
import { useNavigate } from 'react-router-dom'
import logo from '../../../../icons/AniAlZeLogoNewUI.png'
import { paths } from '../../../../routes'

export const Banner = ({
  userRole,
  setCurrentPage,
  setSelectedTab,
}: {
  userRole: Role
  setCurrentPage?: Dispatch<SetStateAction<MentorFragments>>
  setSelectedTab?: Dispatch<SetStateAction<'mentees' | 'invitations'>>
}) => {
  const navigate = useNavigate()

  const handleInteraction = () => {
    if (userRole === Role.User) {
      navigate(paths.MOBILE_HOME, { replace: true })
    } else {
      setCurrentPage?.(MentorFragments.Summary)
      setSelectedTab?.('mentees')
    }
  }

  return (
    <Box
      component="button"
      onClick={handleInteraction}
      sx={{
        background: 'none',
        border: 'none',
        padding: 0,
        cursor: 'pointer',
        '&:focus': {
          outline: 'none',
        },
      }}
      aria-label="Navigate to home"
    >
      <img src={logo} alt="AniAlZe Logo" style={{ width: '8em', height: '8em' }} />
    </Box>
  )
}
