import { Alert, Box, Button, Grid, Modal } from '@mui/material'
import { TaskAlert } from 'api/dtos'
import { TasksPage } from 'pages/TasksPage/TasksPage'
import { useState } from 'react'
import { selectAlertListState, selectUserState, undeleteTaskAsync, useAppDispatch, useAppSelector } from 'store'
import Successes from '../../Successes/Successes'
import OnABreak from './OnABreak'
import { MenteeTopBar, Page } from './Top/MenteeTopBar'

export const ALERT_MESSAGE_TIME = 4000
export enum ViewMode {
  Date = 'date',
  Tasks = 'tasks',
}

export const MobileHome = () => {
  const dispatch = useAppDispatch()
  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.Date)
  const pages = [<TasksPage viewMode={viewMode} />, <Successes />]
  const [currentPage, setCurrentPage] = useState(Page.Tasks)
  const { taskAlerts } = useAppSelector(selectAlertListState)
  const { user } = useAppSelector(selectUserState)

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <MenteeTopBar
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            userRole={user.role}
            setViewMode={setViewMode}
            viewMode={viewMode}
          />
        </Grid>
        <Grid item xs={12} sx={{ marginTop: viewMode === ViewMode.Tasks ? '1em' : '0em' }}>
          {taskAlerts.map((taskAlert: TaskAlert, i: number) => {
            const action = taskAlert.actionType! === 'delete-task' && (
              <Button
                onClick={() => dispatch(undeleteTaskAsync({ id: taskAlert.actionPayload.taskId, userId: user.id }))}
                color="inherit"
                size="small"
              >
                {'ביטול'}
              </Button>
            )
            return (
              <Box key={i} style={{ padding: '1em 1em 0em' }}>
                <Alert style={{ borderRadius: '1em' }} severity={taskAlert.severity} action={action}>
                  {taskAlert.message}
                </Alert>
              </Box>
            )
          })}
        </Grid>
        <Grid item xs={12}>
          {pages[currentPage]}
        </Grid>
      </Grid>
      {user.stopAllTimeStamp ? (
        <Modal open={true} keepMounted>
          <Box>
            <OnABreak />
          </Box>
        </Modal>
      ) : (
        <></>
      )}
    </>
  )
}
