import { Box, Button, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import {
  getUserOtpForAdminAsync,
  useAppDispatch,
  useAppSelector,
} from '../../store'
interface OtpResponse {
  otp: string
}

export const GetUserOtp = () => {
  const [otpResponseMsg, setOtpResponseMsg] = useState('')

  const dispatch = useAppDispatch()
  const isMobile = useAppSelector(state => state.layout.isMobileView)

  const [userIdForOtpGet, setUserIdForOtpGet] = useState(0)

  const handleGetOtp = async () => {
    try {
      setOtpResponseMsg('')
      const response = await dispatch(getUserOtpForAdminAsync(userIdForOtpGet))
      const otpResponse = response.payload as OtpResponse
      if (otpResponse?.otp) {
        setOtpResponseMsg(otpResponse.otp)
      } else {
        setOtpResponseMsg('OTP not found')
      }
    } catch (error) {
      setOtpResponseMsg(`Error fetching OTP: ${error}`);
    }
  }

  return (
    <Box
      sx ={{
        direction: 'rtl',
        display: 'flex',
        flexDirection: 'column'
    }}>
      <TextField
        size="small"
        label="Enter userId"
        onChange={e => setUserIdForOtpGet(parseInt(e.target.value))}
        sx={{ backgroundColor: 'white', width: isMobile ? '35vw' : '15vw' }}
       />
      <Button
        size="small"
        variant="outlined"
        sx={{ height: '3vh', padding: '1em', marginBottom: '1em' }}
        color={'inherit'}
        onClick={handleGetOtp}
      >
        <Typography>Get otp for dev</Typography>
      </Button>
      {otpResponseMsg !== '' && otpResponseMsg}
    </Box>
  )
}
