import { SwipeableDrawer } from '@mui/material'

interface ComponentProps {
  openDrawer: boolean
  toggleDrawer: (newOpen: boolean) => () => void
  children: JSX.Element
}

export const SideDrawer = (props: ComponentProps) => {
  return (
    <SwipeableDrawer
      anchor={'right'}
      open={props.openDrawer}
      onClose={props.toggleDrawer(false)}
      onOpen={props.toggleDrawer(true)}
      disableSwipeToOpen={true}
      onClick={props.toggleDrawer(false)}
    >
      {props.children}
    </SwipeableDrawer>
  )
}
