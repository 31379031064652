import CheckIcon from '@mui/icons-material/Check'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Grid, IconButton } from '@mui/material'
import Box from '@mui/material/Box'
import { CompleteSubtaskRequest, Subtask } from 'api/dtos'
import TaskActionPopover from 'components/TaskActionPopover'
import { ViewMode } from 'pages/Home/MobileHome/MobileHome'
import { useCallback, useContext, useState } from 'react'
import { completeSubtaskAsync, selectUserState, useAppDispatch, useAppSelector } from 'store'
import { MentorContext } from '../../MentorHome/MenteeDetailedInfoNew'
import { taskPageFormatDate } from '../TasksPage'
import { CardTitleSpot } from './Card/CardTitleSpot'
import { SubtaskCheckbox } from './Card/SubtaskCheckbox'
import EditTask from './EditTask'

export const SubtaskCard = ({
  subtask,
  onOpenDeleteDialog,
  taskId,
  userId,
  viewMode,
  taskTitle,
}: {
  subtask: Subtask
  onOpenDeleteDialog: (subtaskId: number) => void
  taskId: number
  userId: number
  viewMode?: ViewMode
  taskTitle: string
}) => {
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(selectUserState)
  const [isEditTaskModalOpen, setIsEditTaskModalOpen] = useState(false)
  const formattedDate = taskPageFormatDate(new Date(subtask.deadline))

  const onSubtaskComplete = (data: CompleteSubtaskRequest) => {
    dispatch(completeSubtaskAsync({ id: data.id, userId: user.id }))
  }
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handlePopoverOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      const newAnchorEl = anchorEl ? null : event.currentTarget
      setAnchorEl(newAnchorEl)
    },
    [anchorEl],
  )

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const handleEditClick = () => {
    setIsEditTaskModalOpen(true)
  }

  const open = Boolean(anchorEl)
  const { isMentor } = useContext(MentorContext)

  return (
    <Box
      mt={1}
      sx={{
        width: '100%',
        minHeight: '8vh',
        maxHeight: '40vh',
        background: '#ffffff',
        borderRadius: '0.5em',
      }}
    >
      <Grid container columnSpacing={1} justifyContent="space-evenly" alignItems={'center'} sx={{ height: '100%' }}>
        <Grid item xs={1}>
          {isMentor ? (
            subtask.status === 'DONE' ? (
              <CheckIcon />
            ) : null
          ) : (
            <IconButton onClick={handlePopoverOpen}>
              <MoreVertIcon />
            </IconButton>
          )}
        </Grid>
        <Grid item xs={9} mb={1} sx={{ alignSelf: 'center', display: 'flex', justifyContent: 'end' }}>
          <CardTitleSpot subtask={subtask} viewMode={viewMode} taskTitle={taskTitle} formattedDate={formattedDate} />
        </Grid>
        {!isMentor && (
          <SubtaskCheckbox
            checked={subtask.status === 'DONE'}
            onSubtaskComplete={() => {
              onSubtaskComplete({ id: subtask.id, userId: user.id })
            }}
          />
        )}
      </Grid>
      <TaskActionPopover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        onEdit={handleEditClick}
        onDelete={() => onOpenDeleteDialog(subtask.id)}
      />
      {isEditTaskModalOpen && <EditTask subtask={subtask} onClose={() => setIsEditTaskModalOpen(false)} />}
    </Box>
  )
}
