import { Box, Grid } from '@mui/material'
import { SetState } from 'common/types/types'
import { ViewMode } from '../MobileHome'
import { Page } from '../Top/MenteeTopBar'
import { AccessItem } from './AccessItem'

export const QuickAccessBar = ({
  currentPage,
  setCurrentPage,
  setViewMode,
  viewMode,
}: {
  currentPage: Page
  setCurrentPage: SetState<Page>
  setViewMode: SetState<ViewMode>
  viewMode: ViewMode
}) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box
          sx={{
            boxShadow: '0px 2px 7px rgba(0, 0, 0, 0.1)',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Grid container sx={{ height: '3.5rem' }}>
            <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
              <AccessItem
                page={Page.Successes}
                text={'הצלחות'}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </Grid>
            <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
              <AccessItem
                page={Page.Tasks}
                text={'מטרות'}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                onClick={() => setViewMode(ViewMode.Tasks)}
                viewMode={viewMode}
                expectedViewMode={ViewMode.Tasks}
              />
            </Grid>
            <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
              <AccessItem
                page={Page.Tasks}
                text={'משימות'}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                onClick={() => setViewMode(ViewMode.Date)}
                viewMode={viewMode}
                expectedViewMode={ViewMode.Date}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )
}
