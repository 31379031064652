import { Box, Button, Divider, Grid } from '@mui/material'

const ReinforcementSection = ({
  title,
  reinforcementType,
  Component,
  addItem,
  selectAll,
}: {
  title: string
  reinforcementType: string
  Component: () => JSX.Element
  addItem: any | undefined
  selectAll: any | undefined
}) => (
  <>
    <Grid item xs={12}>
      <Divider />
    </Grid>
    <Grid container>
      <Grid item xs={3.5} paddingTop="0.7em">
        <Button sx={{ height: '1.5em' }} variant="contained" onClick={selectAll}>
          {'בחר הכל'}
        </Button>
      </Grid>
      <Grid item xs={8.5} padding="0.5em 1.5em 1em" fontSize="1.1em">
        {title}
      </Grid>
      <Grid item xs={12}>
        <Grid container dir="rtl">
          <Grid item xs={12}>
            <Box>
              <Component />
            </Box>
          </Grid>
          <Grid item>
            <Grid container onClick={() => addItem && addItem()}>
              <Grid item>
                <div style={{ fontSize: '1.5em', color: 'black', marginRight: '0.8em' }}>{'+'}</div>
              </Grid>
              <Grid item paddingTop="0.4em" paddingRight="0.6em" marginTop="0.2em" style={{ opacity: '75%' }}>
                <Box>{`הוסף ${reinforcementType}`}</Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </>
)

export default ReinforcementSection
