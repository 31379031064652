import { Button, Grid } from '@mui/material'
import { SharedSubtaskContext } from 'pages/TasksPage/UseSharedSubtaskContext'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { SelectedReinforcement } from 'store/reinforcements-slice'
import ReinforcementsSentences from './ReinforcementsSentences'

export const changeSelectedReinforcement =
  (
    selectedReinforcements: SelectedReinforcement[],
    setSelectedReinforcements: (reinforcements: SelectedReinforcement[]) => void,
  ) =>
  (index: number, selected: boolean, callback?: (updatedReinforcements: SelectedReinforcement[]) => void) => {
    const updatedReinforcements = selectedReinforcements.map((selectedReinforcement, i) =>
      i === index ? { reinforcement: selectedReinforcement.reinforcement, selected } : selectedReinforcement,
    )
    setSelectedReinforcements(updatedReinforcements)

    // Call the callback with the updated state
    if (callback) {
      callback(updatedReinforcements)
    }
  }

const ReinforcementsPage = () => {
  const { setReinforcementOpen } = useContext(SharedSubtaskContext)
  const navigate = useNavigate()

  return (
    <Grid container textAlign={'right'} alignItems="center" sx={{ paddingTop: '5em' }}>
      <Grid
        container
        sx={{
          backgroundColor: '#F1F1F1',
          paddingTop: '1em',
          paddingBottom: '0.5em',
          position: 'fixed',
          top: '0',
          zIndex: 9,
        }}
      >
        <Grid item xs={1} padding="0.5em">
          <Button
            sx={{ height: '1.5em' }}
            variant="contained"
            onClick={() => (setReinforcementOpen ? setReinforcementOpen!(false) : navigate(-1))}
          >
            {'שמור'}
          </Button>
        </Grid>
        <Grid item xs={11} padding="0.5em 1.5em 1em" fontSize="1.1em">
          {'בחירת חיזוק'}
        </Grid>
      </Grid>
      <ReinforcementsSentences />
      <Grid item xs={1} padding="0.5em">
        <Button
          sx={{ height: '1.5em' }}
          variant="contained"
          onClick={() => (setReinforcementOpen ? setReinforcementOpen!(false) : navigate(-1))}
        >
          {'שמור'}
        </Button>
      </Grid>
    </Grid>
  )
}

export default ReinforcementsPage
