import { Box, IconButton, Typography } from '@mui/material'
import { Xicon } from 'pages/TasksPage/TaskCreation/icons/Xicon'

export default function MobileHeaderAdmin({ title, onClose }: Readonly<{ title: string; onClose: () => void }>) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row-reverse',
        alignItems: 'center',
        width: '100%',
        height: '4rem',
        backgroundColor: '#FAFAFA',
        mb: '1rem',
        textAlign: 'right',
        padding: '1rem',
      }}
    >
      <Typography variant="h6" sx={{ flexGrow: 1, fontSize: '1.1rem' }}>
        {title}
      </Typography>
      <IconButton onClick={onClose}>
        <Xicon />
      </IconButton>
    </Box>
  )
}
