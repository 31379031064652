import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Stack, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { useState } from 'react'
import thoughtImg from '../Questionnaire/OnboardingImgs/thought.png'
import timerImg from '../Questionnaire/OnboardingImgs/timer.png'
import valueImg from '../Questionnaire/OnboardingImgs/value.png'

export const ProcrastinationTypeResult = ({
  name,
  progress,
  info,
}: {
  name: string
  progress: number
  info: string
}) => {
  const [open, setOpen] = useState(false)
  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  const isLargeScreen = useMediaQuery('(min-width:400px)')
  const imageSize = isLargeScreen ? '5em' : '4em'

  const getImage = (name: string) => {
    switch (name) {
      case 'ציפייה':
        return thoughtImg
      case 'זמן':
        return timerImg
      case 'ערך':
        return valueImg
    }
  }

  return (
    <Tooltip
      title={info}
      arrow
      placement="top-end"
      open={open}
      onOpen={handleTooltipOpen}
      onClose={handleTooltipClose}
      leaveTouchDelay={5000}
    >
      <Stack
        direction="column"
        alignItems="center"
        sx={{ backgroundColor: '#FAFAFA', padding: '1em', borderRadius: '10px', height: '100%' }}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          onClick={handleTooltipOpen}
          display={'flex'}
          justifyContent={'space-between'}
          width={'100%'}
          flexDirection={'row-reverse'}
        >
          <InfoOutlinedIcon fontSize="small" />
          <h4 style={{ fontSize: '1em', fontWeight: 500 }}>{name}</h4>
        </Stack>
        <Stack direction="row" alignItems="center" mt={1}>
          <img src={getImage(name)} alt={name} style={{ width: imageSize, height: imageSize, marginRight: '0.2em' }} />
        </Stack>
        <Stack direction="row" alignItems={'center'}>
          <Typography variant="h2" style={{ fontSize: '1.5em', fontWeight: 500 }}>
            %
          </Typography>
          <Typography variant="h3" style={{ fontWeight: 500, fontSize: '1.5em' }}>
            {progress}
          </Typography>
        </Stack>
      </Stack>
    </Tooltip>
  )
}
export default ProcrastinationTypeResult
