export enum AchievementType {
    GROWTH = 'GROWTH',
    STRUGGLE = 'STRUGGLE',
    EXPERIENCE = 'EXPERIENCE',
}

export interface Achievement {
    id: number
    text: string
    attached: string
    userId: number
}

export interface CreateAchievementRequest {
    text: string
    attached: string
    userId: number
}
