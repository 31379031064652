import { Box } from '@mui/material'
import { SideDrawer } from 'components/SideDrawer'
import { useState } from 'react'
import { Menu } from './Menu'

export interface HamburgerProps {
  children: JSX.Element
}

export default function Hamburger(props: HamburgerProps) {
  const [openDrawer, setOpenDrawer] = useState(false)
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpenDrawer(newOpen)
  }
  return (
    <>
      <Box
        onClick={toggleDrawer(true)}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '2em 1em 1em 1em',
        }}
      >
        <Menu />
      </Box>
      <SideDrawer openDrawer={openDrawer} toggleDrawer={toggleDrawer}>
        <div
          style={{
            width: '60dvw',
            height: '100dvh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '1em',
          }}
        >
          {props.children}
        </div>
      </SideDrawer>
    </>
  )
}
