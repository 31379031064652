import { Notification, UpdateNotificationRequest } from '../dtos'
import { axiosInstance } from '../network'
import { ApiResponse } from '../types'
import { RequestConfig } from '../types/request-config'

const listNotifications = async (config?: RequestConfig): Promise<Notification[]> => {
  const axiosResponse = await axiosInstance.get<never, ApiResponse<Notification[]>>('/notifications/list', config)
  return axiosResponse.data!
}

const updateNotification = async (
  updateNotificationRequest: UpdateNotificationRequest,
  config?: RequestConfig,
): Promise<Notification> => {
  const axiosResponse = await axiosInstance.put<UpdateNotificationRequest, ApiResponse<Notification>>(
    '/notifications/' + updateNotificationRequest.notificationIds,
    updateNotificationRequest,
    config,
  )
  return axiosResponse.data!
}
export const notificationsApiRequest = {
  listNotifications,
  updateNotification,
}
