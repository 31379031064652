import { Box, Typography } from '@mui/material'
import { MenteeDto, UserOTPRequestDto } from 'api/dtos'
import { Dispatch, SetStateAction } from 'react'
import { theme } from 'theme'
import { MentorFragments } from '../MentorDashboard'

interface AllMenteesHeaderProps {
  handleMenteeSelection: (user: MenteeDto | undefined) => void
  pendingInvite?: UserOTPRequestDto[]
  setCurrentPage: Dispatch<SetStateAction<MentorFragments>>
  selectedTab: 'mentees' | 'invitations'
  setSelectedTab: Dispatch<SetStateAction<'mentees' | 'invitations'>>
}

export const AllMenteesHeader = (props: AllMenteesHeaderProps) => {
  const handleTabClick = (tab: 'mentees' | 'invitations') => {
    props.setSelectedTab(tab)
    props.handleMenteeSelection(undefined)
    tab === 'mentees'
      ? props.setCurrentPage(MentorFragments.Summary)
      : props.setCurrentPage(MentorFragments.PendingInvites)
  }

  return (
    <Box
      sx={{
        direction: 'rtl',
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: 'white',
        width: '100%',
        padding: '0.7em 0 0 0',
      }}
    >
      <Typography
        variant={'h6'}
        width={'50%'}
        textAlign={'center'}
        onClick={() => handleTabClick('mentees')}
        sx={{
          cursor: 'pointer',
          borderBottom: props.selectedTab === 'mentees' ? `3px solid ${theme.palette.primary.main}` : 'none',
          paddingBottom: '0.5em',
        }}
      >
        מתאמנים
      </Typography>
      <Typography
        variant={'h6'}
        width={'50%'}
        textAlign={'center'}
        onClick={() => handleTabClick('invitations')}
        sx={{
          cursor: 'pointer',
          borderBottom: props.selectedTab === 'invitations' ? `3px solid ${theme.palette.primary.main}` : 'none',
          paddingBottom: '0.5em',
        }}
      >
        הזמנות ממתינות ({props.pendingInvite?.length})
      </Typography>
    </Box>
  )
}
