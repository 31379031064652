import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiRequests } from '../api'
import {
  InviteUserRequestDto,
  MenteesDto,
  Mentor,
  OTPValidationRequestDto,
  SimpleMenteeDto,
  UpdateUserAvatarRequest,
  UpdateUserRequest,
  User,
  UserOTPRequestDto,
  UserProcrastinations,
} from '../api/dtos'
import { RequestStatus } from '../common/types'
import { config } from '../config'
import { storageUtil } from '../utils'
import { RootState } from './store'
import { listTasksAsync } from './tasks-slice'

export interface UserState {
  user: User
  mentors?: Mentor[]
  procrastinations?: UserProcrastinations
  error?: string
  registerError?: string
  status: RequestStatus
  mentees?: MenteesDto
  allMentees: SimpleMenteeDto[]
  allUsers?: User[]
  pendingInvite?: UserOTPRequestDto[]
}

const initialState: UserState = {
  user: (storageUtil.get<User>(config.storage.USER_KEY) as User) ?? undefined,
  status: RequestStatus.Idle,
  allMentees: [],
  pendingInvite: [],
}

export const getUserAsync = createAsyncThunk('user/getUser', async (): Promise<User> => {
  // The value we return becomes the `fulfilled` action payload
  const response = await apiRequests.getMyUser()
  storageUtil.set(config.storage.USER_KEY, response)
  return response
})

export const stopUserActivityAsync = createAsyncThunk(
  'user/stopActivity',
  async (id: number, { dispatch }): Promise<User> => {
    const response = await apiRequests.stopUserActivity(id)
    dispatch(getUserAsync())
    return response
  },
)

export const resumeUserActivityAsync = createAsyncThunk(
  'user/resumeActivity',
  async (id: number, { dispatch }): Promise<User> => {
    const response = await apiRequests.resumeUserActivity(id)
    dispatch(getUserAsync())
    dispatch(listTasksAsync(id))
    return response
  },
)

export const updateUserAsync = createAsyncThunk(
  'user/update',
  async (
    {
      id,
      name,
      role,
      mentorId,
      efficiencyTime,
      profilePictureURL,
      phoneNumber,
      stopAllTimeStamp,
      enableNotifications,
      mentees,
    }: UpdateUserRequest,
    { dispatch },
  ) => {
    const response = await apiRequests.updateUser({
      id,
      name,
      role,
      mentorId,
      efficiencyTime,
      profilePictureURL,
      phoneNumber,
      stopAllTimeStamp,
      enableNotifications,
      mentees,
    })
    if (response) {
      dispatch(getUserAsync())
      return response
    }
  },
)

export const updateUserAvatarAsync = createAsyncThunk(
  'user/updateAvatar',
  async ({ id, profilePictureBytea }: UpdateUserAvatarRequest, { dispatch }) => {
    const response = await apiRequests.updateUserAvatar({
      id,
      profilePictureBytea,
    })
    if (response) {
      dispatch(getUserAsync())
      return response
    }
  },
)

export const listMentorsAsync = createAsyncThunk('user/mentors', async (): Promise<Mentor[]> => {
  const response = await apiRequests.listMentors()
  storageUtil.set(config.storage.MENTORS_KEY, response)
  return response
})

export const listMenteesAsync = createAsyncThunk('user/mentees', async (mentorId: number): Promise<MenteesDto> => {
  const response = await apiRequests.listMentees(mentorId)
  return response
})

export const listAllMenteesAsync = createAsyncThunk('user/allMentees', async (): Promise<SimpleMenteeDto[]> => {
  const response = await apiRequests.listAllMentees()
  return response
})

export const getProcrastinationResultsAsync = createAsyncThunk(
  'user/procrastination',
  async (id: number): Promise<UserProcrastinations> => {
    const response = await apiRequests.getProcrastinationResults(id)
    return response
  },
)

export const listAllUsersAsync = createAsyncThunk('user/allUsers', async (): Promise<User[]> => {
  const response = await apiRequests.listAllUsers()
  return response
})

export const deleteUserAsync = createAsyncThunk('user/delete/', async (userId: number, { dispatch }) => {
  const response = await apiRequests.deleteUser(userId)
  if (response) {
    dispatch(listAllUsersAsync())
  }
})

export const inviteAsync = createAsyncThunk(
  'user/invite',
  async ({ email, name, phoneNumber, mentorId }: InviteUserRequestDto, { dispatch }) => {
    return await apiRequests.inviteUser({ email, name, phoneNumber, mentorId })
  },
)

export const pendingInvitesAsync = createAsyncThunk(
  'user/pendingInvites',
  async (mentorId: number): Promise<UserOTPRequestDto[]> => {
    return await apiRequests.getPendingInvitesByMentor(mentorId)
  },
)

export const otpValidationAsync = createAsyncThunk(
  'user/otpValidation',
  async ({ otp, userId }: OTPValidationRequestDto, { dispatch }) => {
    return await apiRequests.otpValidation({ otp, userId })
  },
)

export const refreshOTPAsync = createAsyncThunk('user/refreshOTP', async (userId: number, { dispatch }) => {
  const response = await apiRequests.refreshOTP(userId)
  if (response) {
    dispatch(getUserAsync())
    return response
  }
})

export const getUserOtpForAdminAsync = createAsyncThunk('users/otp/:userId', async (userId: number) => {
  const response = await apiRequests.getUserOtpForAdmin(userId)
  if (response) {
    console.warn(response)
    return response
  }
})

export const getAnalyticsForAdmin = createAsyncThunk('users/analytics/:timeFilter', async (timeFilter: number) => {
  const response = await apiRequests.getAnalyticsForAdmin(timeFilter)
  if (response) {
    return response
  }
})

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder

      .addCase(getUserAsync.pending, state => {
        state.status = RequestStatus.Loading
      })
      .addCase(getUserAsync.fulfilled, (state, action) => {
        state.status = RequestStatus.Idle
        state.user = action.payload
      })
      .addCase(getUserAsync.rejected, (state, action) => {
        state.error = action.error.message
        state.status = RequestStatus.Failed
      })
      .addCase(updateUserAsync.pending, state => {
        state.status = RequestStatus.Loading
      })
      .addCase(updateUserAsync.fulfilled, (state, action) => {
        state.status = RequestStatus.Idle
      })
      .addCase(updateUserAsync.rejected, (state, action) => {
        state.error = action.error.message
        state.status = RequestStatus.Failed
      })
      .addCase(listMentorsAsync.fulfilled, (state, action) => {
        state.status = RequestStatus.Idle
        state.mentors = action.payload
      })
      .addCase(getProcrastinationResultsAsync.fulfilled, (state, action) => {
        state.status = RequestStatus.Idle
        state.procrastinations = action.payload
      })
      .addCase(listMenteesAsync.fulfilled, (state, action) => {
        state.status = RequestStatus.Idle
        state.mentees = action.payload
      })
      .addCase(listAllMenteesAsync.fulfilled, (state, action) => {
        state.status = RequestStatus.Idle
        state.allMentees = action.payload
      })
      .addCase(listAllUsersAsync.fulfilled, (state, action) => {
        state.status = RequestStatus.Idle
        state.allUsers = action.payload
      })
      .addCase(pendingInvitesAsync.pending, state => {
        state.status = RequestStatus.Loading
      })
      .addCase(pendingInvitesAsync.fulfilled, (state, action) => {
        state.status = RequestStatus.Idle
        state.pendingInvite = action.payload
      })
      .addCase(pendingInvitesAsync.rejected, (state, action) => {
        state.error = action.error.message
        state.status = RequestStatus.Failed
      })
      .addCase(otpValidationAsync.fulfilled, state => {
        state.user.otpValidated = true
      })
  },
})

export const selectUserState = (state: RootState) => state.user
export const userReducer = userSlice.reducer
