export interface Reinforcement {
  id: number
  text?: string
  url?: string
  type: ReinforcementType
  userId: number
}

export interface CreateReinforcementSentence {
  sentence: string
}
export enum ReinforcementType {
  Text = 'Text',
  Image = 'Image',
  Video = 'Video',
  Gif = 'Gif',
}
export interface CreateReinforcementRequest {
  text?: string
  url?: string
  type: ReinforcementType
  userId: number
}

export interface UpdateReinforcementRequest {
  id: number
  text?: string
  url?: string
  type?: ReinforcementType
  userId: number
}

export interface DeleteReinforcementRequest {
  id: number
}
