import { Button, Typography } from '@mui/material'

interface DrawerItemProps {
  eventHandler: () => void
  title: string
}

export default function DrawerItem(props: DrawerItemProps) {
  return (
    <Button style={{ flexGrow: 1 }} color={'inherit'}>
      <Typography variant={'h5'} onClick={() => props.eventHandler()}>
        {props.title}
      </Typography>
    </Button>
  )
}
