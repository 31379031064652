import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { storageUtil } from 'utils'
import { TaskAlert } from '../api/dtos'
import { config } from '../config'
import { RootState } from './store'

export interface AlertsState {
  taskAlerts: TaskAlert[]
}

const initialState: AlertsState = {
  taskAlerts: [],
}

export const addTaskAlert = createAsyncThunk('tasks/alert', async (newAlert: TaskAlert, { dispatch }) => {
  const currentAlerts = (storageUtil.get<TaskAlert[]>(config.storage.TASKS_ALERTS) as TaskAlert[]) ?? []
  if (currentAlerts) {
    const updatedAlerts = [...currentAlerts, newAlert]
    storageUtil.set(config.storage.TASKS_ALERTS, updatedAlerts)
    dispatch(listTaskAlerts())
    return updatedAlerts
  }
})

export const removeTaskAlert = createAsyncThunk('tasks/unalert', async (alert: TaskAlert, { dispatch }) => {
  const currentAlerts = (storageUtil.get<TaskAlert[]>(config.storage.TASKS_ALERTS) as TaskAlert[]) ?? []
  const updatedAlerts = currentAlerts.filter(a => a.uuid !== alert.uuid)
  storageUtil.set(config.storage.TASKS_ALERTS, updatedAlerts)
  dispatch(listTaskAlerts())
  return updatedAlerts
})

export const clearTaskAlerts = createAsyncThunk('tasks/list', async (): Promise<TaskAlert[]> => {
  storageUtil.set(config.storage.TASKS_ALERTS, [])
  return []
})

export const listTaskAlerts = createAsyncThunk('tasks/getalerts', async () => {
  return (storageUtil.get<TaskAlert[]>(config.storage.TASKS_ALERTS) as TaskAlert[]) ?? []
})

export const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(listTaskAlerts.fulfilled, (state, action) => {
      state.taskAlerts = action.payload
    })
  },
})

export const selectAlertListState = (state: RootState) => state.alerts
export const alertsReducer = alertsSlice.reducer
