import MenuIcon from '@mui/icons-material/Menu'
import { Grid } from '@mui/material'

export const Menu = () => {
  return (
    <Grid
      container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Grid item xs={12}>
        <MenuIcon sx={{ fontSize: '1.7em' }} />
      </Grid>
    </Grid>
  )
}
