import { Box } from '@mui/material'
import { Subtask } from 'api/dtos'
import { MentorContext } from 'pages/MentorHome/MenteeDetailedInfoNew'
import { useContext } from 'react'

export const SubtaskDescription = ({ subtask }: { subtask: Subtask }) => {
  const { isMentor } = useContext(MentorContext)

  return (
    <Box
      sx={{
        width: '100%',
        xs: 12,
        textAlign: 'right',
        direction: 'rtl',
        fontSize: '1em',
        textDecoration: isMentor ? null : subtask.status === 'DONE' ? 'line-through' : 'none',
        marginBottom: '0.5em',
      }}
    >
      {subtask.description}
    </Box>
  )
}
