import { Box, Button, Typography } from '@mui/material'
import { ReactNode, useCallback, useState } from 'react'
import { logout, useAppDispatch } from '../../store'
import { AdminAnalytics } from './AdminAnalytics'
import { GetUserOtp } from './GetUserOtp'
import { QuestionnaireTable } from './QuestionnaireTable'
import { ReinforcementTable } from './ReinforcementTable'
import { UsersTable } from './UsersTable'

interface TabProps {
  label: string
  content: ReactNode
}
export const AdminPanel = () => {
  const dispatch = useAppDispatch()
  const [activeTab, setActiveTab] = useState<number>(0)

  const tabs: TabProps[] = [
    { label: 'משתמשים', content: <UsersTable /> },
    { label: 'שאלות', content: <QuestionnaireTable /> },
    { label: 'חיזוקים', content: <ReinforcementTable /> },
    { label: 'OTP', content: <GetUserOtp /> },
    { label: 'Analytics', content: <AdminAnalytics /> },
  ]

  const logOut = useCallback(() => {
    dispatch(logout())
  }, [dispatch])

  return (
    <Box
      sx={{
        direction: 'rtl',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        padding: '2em',
        background: 'linear-gradient(78.15deg, #F9B76B 1.64%, rgba(255, 171, 72, 0.25) 71.26%)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            flexGrow: 1,
            margin: '1em 0 0.3em 0',
          }}
          variant={'h4'}
        >
          פאנל ניהול - אני על זה
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '0.2em' }}>
          {tabs.map((tab, index) => (
            <Button
              variant="outlined"
              color="secondary"
              key={index}
              disabled={activeTab === index}
              onClick={() => setActiveTab(index)}
              sx={{ mb: 1 }}
            >
              {tab.label}
            </Button>
          ))}
        </Box>
      </Box>
      {tabs[activeTab].content}

      <Button size="small" sx={{ height: '5vh', padding: '1em', margin: '2em 0' }} color={'inherit'}>
        <Typography variant={'h5'} onClick={logOut}>
          התנתקות
        </Typography>
      </Button>
    </Box>
  )
}
