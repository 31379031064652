import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'store'
import { selectSuccessListState } from 'store/successes-slice'
import SuccessesGridView from './SuccessesGridView'

export const Successes = () => {
  const { t } = useTranslation()
  const { successes } = useAppSelector(selectSuccessListState) // eslint-disable-line @typescript-eslint/no-unused-vars
  if (
    successes?.onTimeSubtasks.length === 0 &&
    successes?.earlySubtasks.length === 0 &&
    successes?.hardSubtasks.length === 0
  ) {
    return (
      <Typography variant="h4" sx={{ direction: 'rtl', fontWeight: 600, paddingRight: '1em' }}>
        {t('successes.noneYet')}
      </Typography>
    )
  } else {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1.5em',
        }}
      >
        <SuccessesGridView />
      </div>
    )
  }
}
export default Successes
