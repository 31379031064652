import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { Box, Paper, Typography } from '@mui/material'
import { useState } from 'react'
import { useAppSelector } from 'store'
import earlyMedal1Star from '../../icons/medals/earlyMedal-1star.png'
import earlyMedal2Star from '../../icons/medals/earlyMedal-2star.png'
import earlyMedal3Star from '../../icons/medals/earlyMedal-3star.png'
import hardMedal1Star from '../../icons/medals/hardMedal-1star.png'
import hardMedal2Star from '../../icons/medals/hardMedal-2star.png'
import hardMedal3Star from '../../icons/medals/hardMedal-3star.png'
import onTimeMedal1Star from '../../icons/medals/onTimeMedal-1star.png'
import onTimeMedal2Star from '../../icons/medals/onTimeMedal-2star.png'
import onTimeMedal3Star from '../../icons/medals/onTimeMedal-3star.png'

type MedalImages = {
  [category: string]: {
    [difficulty: string]: string
  }
}
const getMedalImage = (category: keyof MedalImages, difficulty: string) => {
  const medalImages: MedalImages = {
    hard: {
      EASY: hardMedal1Star,
      MEDIUM: hardMedal2Star,
      HARD: hardMedal3Star,
    },
    early: {
      EASY: earlyMedal1Star,
      MEDIUM: earlyMedal2Star,
      HARD: earlyMedal3Star,
    },
    onTime: {
      EASY: onTimeMedal1Star,
      MEDIUM: onTimeMedal2Star,
      HARD: onTimeMedal3Star,
    },
  }
  return medalImages[category][difficulty]
}

export const SuccessGridItem = ({
  subtaskTitle,
  timeDifference,
  taskTitle,
  category,
  difficulty,
}: {
  subtaskTitle: string
  timeDifference: number
  taskTitle: string | null
  category: string
  difficulty: string
}) => {
  const [openDialog, setOpenInfo] = useState(false)
  const medalImage = getMedalImage(category, difficulty)
  const isMobile = useAppSelector(state => state.layout.isMobileView)

  return (
    <Paper
      onClick={() => {
        setOpenInfo(!openDialog)
      }}
      elevation={3}
      sx={{
        minHeight: '12vh',
        width: isMobile ? '90%' : '40vw',
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'start',
        alignItems: 'center',
        alignContent: 'center',
        margin: 0.5,
        borderRadius: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          p: 1,
          width: '100%',
        }}
      >
        <Typography variant="h6">{subtaskTitle}</Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            gap: 1,
            mt: 1,
          }}
        >
          {timeDifference !== null && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#F9F9F9',
                borderRadius: 1,
                padding: '2px',
              }}
            >
              <CalendarTodayIcon sx={{ fontSize: '1em', marginLeft: 0.5 }} />
              <Typography variant="caption">{`${timeDifference} ימים`}</Typography>
            </Box>
          )}
          <Typography variant="caption" sx={{ backgroundColor: '#F9F9F9', borderRadius: 1 }}>
            {taskTitle}
          </Typography>
        </Box>
      </Box>
      <img src={medalImage} style={{ width: isMobile ? '35%' : '20%' }} alt="medal" />
    </Paper>
  )
}
export default SuccessGridItem
