import { Box, Collapse, Drawer, Grid } from '@mui/material'
import { MenteeDto, MenteesDto, User } from 'api/dtos'
import { ViewMode } from 'pages/Home/MobileHome/MobileHome'
import { taskOrganizer } from 'pages/TasksPage/TasksPage'
import { createContext, useEffect, useRef, useState } from 'react'
import { Task as TaskComponent } from '../TasksPage/Task/Task'
import { ContactButtons } from './MentorComponents/ContactButtons'
import { MenteeDetailsHeader } from './MentorComponents/MenteeDetailsHeader'
import { MenteeProfileTab } from './MentorComponents/MenteeProfileTab'
import { MenteeTopSection } from './MentorComponents/MenteeTopSection'
import { MentorSelectHeader } from './MentorComponents/MentorSelectHeader'

export enum MenteeDetailedFragments {
  Tasks,
  Profile,
}

export const MentorContext = createContext({
  isMentor: false,
})

export const MenteeDetailedInfoNew = ({
  mentee,
  handleMenteeSelection,
  mentees,
  user,
  onMenteeRemoved,
}: {
  mentee: MenteeDto
  handleMenteeSelection: (mentee: MenteeDto | undefined) => void
  mentees: MenteesDto | undefined
  user: User
  onMenteeRemoved: (removedMenteeId: number) => void
}) => {
  const [currentTab, setCurrentTab] = useState(MenteeDetailedFragments.Tasks)
  const [isOpen, setIsOpen] = useState(true)
  const organizedTasks = taskOrganizer(mentee.tasks, ViewMode.Tasks)

  const handleClick = (tab: MenteeDetailedFragments) => {
    setCurrentTab(tab)
  }

  const [isTopCollapsed, setIsTopCollapsed] = useState(false)
  const scrollRef = useRef<HTMLDivElement>(null)

  const handleScroll = () => {
    const scrollTop = scrollRef.current?.scrollTop ?? 0
    setIsTopCollapsed(scrollTop > 1)
  }

  useEffect(() => {
    const div = scrollRef.current
    div?.addEventListener('scroll', handleScroll)

    return () => {
      div?.removeEventListener('scroll', handleScroll)
    }
  })

  return (
    <MentorContext.Provider value={{ isMentor: true }}>
      <Drawer anchor="bottom" open={isOpen} onClose={() => setIsOpen(false)}>
        <Box sx={{ height: '100dvh', display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: 'white' }}>
            <MenteeDetailsHeader
              mentee={mentee}
              handleMenteeSelection={handleMenteeSelection}
              menteesList={mentees}
              user={user}
              onMenteeRemoved={onMenteeRemoved}
            />
          </Box>
          <Box
            ref={scrollRef}
            onScroll={handleScroll}
            sx={{
              flexGrow: 1,
              overflow: 'auto',
              backgroundColor: 'white',
            }}
          >
            <Collapse in={!isTopCollapsed} sx={{ mb: '1em' }} timeout={'auto'}>
              <Box sx={{ overflowY: 'auto' }}>
                <MenteeTopSection mentee={mentee} />
                <ContactButtons phoneNumber={mentee.phoneNumber} email={mentee.email} />
              </Box>
            </Collapse>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', mb: '2em' }}>
              <MentorSelectHeader
                tabType={MenteeDetailedFragments.Profile}
                currentTab={currentTab}
                handleClick={handleClick}
                tabText="פרופיל"
              />
              <MentorSelectHeader
                tabType={MenteeDetailedFragments.Tasks}
                currentTab={currentTab}
                handleClick={handleClick}
                tabText="משימות"
              />
            </Box>
            <Box sx={{ height: '90dvh' }}>
              {currentTab === MenteeDetailedFragments.Profile && <MenteeProfileTab mentee={mentee} />}
              {currentTab === MenteeDetailedFragments.Tasks && (
                <Grid container sx={{}}>
                  {organizedTasks &&
                    Array.from(organizedTasks.keys()).map((d: Date, i: number) => (
                      <Grid container key={i}>
                        <Grid item xs={12}>
                          {organizedTasks.get(d)?.map((t, index) => (
                            <Grid item key={index} sx={{ padding: '0em 1em 1.35em' }}>
                              <TaskComponent task={t} />
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
              )}
            </Box>
          </Box>
        </Box>
      </Drawer>
    </MentorContext.Provider>
  )
}
