import { MailOutline, WhatsApp } from '@mui/icons-material'
import { Button, Grid } from '@mui/material'

interface ContactButtonsProps {
  phoneNumber: string | null | undefined
  email: string
}

export const ContactButtons = (props: ContactButtonsProps) => {
  return (
    <Grid container justifyContent="space-between" sx={{ marginTop: '2em', direction: 'rtl' }}>
      <Grid item xs={6} textAlign="center">
        <Button
          variant="contained"
          startIcon={<WhatsApp />}
          href={`https://wa.me/${props.phoneNumber}`} // Ensure the phone number is in international format
          sx={{
            backgroundColor: '#FFAB48',
            boxShadow: '0px 0px 3px rgba(45, 45, 45, 0.25)',
            borderRadius: '0.4em',
            width: '80%',
            height: '5.5dvh',
            gap: '1em',
            fontSize: '1em',
          }}
        >
          וואטסאפ
        </Button>
      </Grid>
      <Grid item xs={6} textAlign="center">
        <Button
          variant="outlined"
          startIcon={<MailOutline />}
          onClick={() => window.open(`mailto:${props.email}`)}
          sx={{
            boxShadow: '0px 0px 3px rgba(45, 45, 45, 0.25)',
            borderRadius: '0.4em',
            borderColor: '#FFAB48',
            width: '80%',
            height: '5.5dvh',
            color: 'black',
            gap: '1em',
            fontSize: '1em',
          }}
        >
          מייל
        </Button>
      </Grid>
    </Grid>
  )
}
