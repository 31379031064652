import { Role, User } from '../../api/dtos'
import { paths } from '../../routes'

export const getNewListIndex = (list: any[]) => {
  if (list.length === 0) {
    return 1
  }
  return list.slice(-1)[0].index + 1
}

export const getPermissions = (user: User) => {
  switch (user?.role) {
    case Role.Admin:
      return paths.ADMIN_PANEL
    case Role.Mentor:
      return paths.MENTOR_HOME
    case Role.User:
      // user's first time use
      if (user.UserQuestionAndAnswer) {
        return paths.ONBOARDING_OTP
      }
      return paths.MOBILE_HOME
    default:
      return paths.LOGIN
  }
}
