import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { MenteeDto, MenteesDto, SimpleMenteeDto, User } from 'api/dtos'
import { useState } from 'react'
import { updateUserAsync, useAppDispatch } from 'store'
import { RemoveMenteePopUp } from './RemoveMenteePopUp'

interface MenteeDetailsHeaderProps {
  mentee: MenteeDto
  handleMenteeSelection: (mentee: MenteeDto | undefined) => void
  menteesList: MenteesDto | undefined
  user: User
  onMenteeRemoved: (removedMenteeId: number) => void
}

export const MenteeDetailsHeader = (props: MenteeDetailsHeaderProps) => {
  const dispatch = useAppDispatch()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [isRemovePopupOpen, setIsRemovePopupOpen] = useState(false)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleRemoveClick = () => {
    handleClose()
    setIsRemovePopupOpen(true)
  }

  const handleRemoveMentee = async () => {
    if (!props.menteesList) return

    const updatedMenteesList = props.menteesList?.Mentees.filter(m => m.id !== props.mentee.id)

    // Transform the updatedMenteesList to SimpleMenteeDto[]
    const simpleMentees: SimpleMenteeDto[] = updatedMenteesList
      .filter(mentee => mentee.id !== undefined)
      .map(mentee => ({
        id: mentee.id as number,
        name: mentee.name || null,
      }))

    try {
      await dispatch(
        updateUserAsync({
          id: props.user.id,
          name: props.user.name,
          role: props.user.role,
          mentorId: props.user.mentorId,
          efficiencyTime: props.user.efficiencyTime,
          profilePictureURL: props.user.profilePictureURL,
          enableNotifications: props.user.enableNotifications,
          phoneNumber: props.user.phoneNumber,
          mentees: simpleMentees,
        }),
      ).unwrap()

      props.onMenteeRemoved(props.mentee.id as number)
      props.handleMenteeSelection(undefined)
      handleClose()
    } catch (error) {
      console.error('Failed to update mentees:', error)
    }
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1em' }}>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Typography variant="h6">{props.mentee.name}</Typography>
      <IconButton onClick={() => props.handleMenteeSelection(undefined)}>
        <ArrowForwardIcon />
      </IconButton>
      <Menu
        sx={{
          '& .MuiPaper-root': { border: '1px solid #F9F9F9', borderRadius: '0.5em' },
          '& .css-6hp17o-MuiList-root-MuiMenu-list': { padding: '0em' },
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleRemoveClick}>הסרה מהמתאמנים שלי</MenuItem>
      </Menu>
      <RemoveMenteePopUp
        open={isRemovePopupOpen}
        onClose={() => setIsRemovePopupOpen(false)}
        onConfirm={handleRemoveMentee}
        menteeName={props.mentee.name ?? ''}
      />
    </Box>
  )
}
