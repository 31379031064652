import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditIcon from '@mui/icons-material/Edit'
import { Box, Grid, Popover, Typography } from '@mui/material'
import React from 'react'

interface TaskActionPopoverProps {
  anchorEl: HTMLElement | null
  open: boolean
  onClose: () => void
  onEdit: () => void
  onDelete: () => void
}

const TaskActionPopover: React.FC<TaskActionPopoverProps> = ({ anchorEl, open, onClose, onEdit, onDelete }) => {
  const id = open ? 'task-action-popover' : undefined

  const handleEdit = () => {
    onEdit()
    onClose()
  }

  const handleDelete = () => {
    onDelete()
    onClose()
  }

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '0.5em',
        },
      }}
    >
      <Box p={2} width={'35vw'}>
        <Grid container direction="column" spacing={2} alignContent={'flex-end'}>
          <Grid item>
            <Box display="flex" alignItems="right" onClick={handleEdit}>
              <Typography variant="body1" marginRight={1}>
                עריכה
              </Typography>
              <EditIcon sx={{ color: 'black' }} />
            </Box>
          </Grid>
          <Grid item>
            <Box display="flex" alignItems="right" onClick={handleDelete}>
              <Typography variant="body1" marginRight={1}>
                מחיקה
              </Typography>
              <DeleteOutlineIcon sx={{ color: 'black' }} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Popover>
  )
}

export default TaskActionPopover
