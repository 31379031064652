import { Box, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import logo from '../../icons/AniAlZeLogo.png'
import ChromeIcon from '../../icons/Chrome_Icon.svg'
import SafariIcon from '../../icons/Safari_Icon.svg'

export default function UnsupportedBrowser() {
  const { t } = useTranslation()
  return (
    <Grid
      container
      justifyContent="center"
      textAlign={'center'}
      flexDirection={'column'}
      alignItems={'center'}
      mt={7}
      sx={{ direction: 'rtl', padding: '1em' }}
    >
      <img src={logo} alt={logo} style={{ width: '10em', height: '10em', margin: '0 0 2em 0' }}></img>
      <Box margin={'2em 0 2em 0'}>
        <Typography fontSize={'1.5em'}>{t('unsupportedBrowser.sorry')}</Typography>
        <Typography fontSize={'1.5em'}>{t('unsupportedBrowser.notSupported')}</Typography>
      </Box>
      <Box margin={'2em 0 2em 0'}>
        <Typography fontSize={'1em'}>{t('unsupportedBrowser.recommend')}</Typography>
      </Box>
      <Box display={'flex'} flexDirection={'row'} gap={'3em'}>
        <Box>
          <a href="https://www.google.com/chrome/" target="_blank" rel="noopener noreferrer">
            <img src={ChromeIcon} alt="Chrome" style={{ width: '3em', height: '3em' }} />
          </a>
          <Typography fontSize={'0.75em'} color={'#666'}>
            Chrome
          </Typography>
        </Box>
        <Box>
          <a href="https://www.apple.com/safari/" target="_blank" rel="noopener noreferrer">
            <img src={SafariIcon} alt="Safari" style={{ width: '3em', height: '3em' }} />
          </a>
          <Typography fontSize={'0.75em'} color={'#666'}>
            Safari
          </Typography>
        </Box>
      </Box>
    </Grid>
  )
}
