import { Box, InputLabel, MenuItem, Select } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { AnalyticsDto } from '../../api/dtos'
import { getAnalyticsForAdmin, useAppDispatch } from '../../store'

export const AdminAnalytics = () => {
  const dispatch = useAppDispatch()
  const [analyticsData, setAnalyticsData] = useState<AnalyticsDto[]>([])
  const [timeFilter, setTimeFilter] = useState(30)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await dispatch(getAnalyticsForAdmin(timeFilter)).unwrap()
        if (data) {
          const dataWithId = data?.map(item => ({ ...item, id: item.menteeId }))
          setAnalyticsData(dataWithId)
        }
      } catch (error) {
        console.error('Failed to fetch analytics data: ', error)
      }
    }
    fetchData()
  }, [dispatch, timeFilter])

  const formatDate = (dateStr: string | null): string => {
    if (!dateStr) return ''
    const date = new Date(dateStr)
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${date.getFullYear()}`
  }

  const columns: GridColDef[] = [
    {
      field: 'menteeId',
      headerName: 'מספר מתאמן',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'menteeName',
      headerName: 'שם מתאמן',
      editable: false,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'mentorName',
      headerName: 'שם מנטור',
      editable: false,
      flex: 1.2,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'numOfSessions',
      headerName: 'מספר סשנים',
      type: 'string',
      editable: false,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'lastLoginDate',
      headerName: 'התחברות אחרונה',
      type: 'Date',
      editable: false,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      valueFormatter: ({ value }) => formatDate(value),
    },
    {
      field: 'numOfTasksCreated',
      headerName: 'משימות שנפתחו',
      type: 'number',
      editable: false,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'numOfTasksClosed',
      headerName: 'משימות שנסגרו',
      type: 'singleSelect',
      editable: false,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
  ]

  return (
    <Box
      sx={{
        flexGrow: 5,
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          mb: '0.5em',
        }}
      >
        <InputLabel id="filter-by-time">סינון לפי ימים</InputLabel>
        <Select
          labelId="filter-by-time"
          id="filter-by-time"
          value={timeFilter}
          onChange={e => setTimeFilter(Number(e.target.value))}
          color="secondary"
          sx={{
            borderColor: '#9c27b0',
          }}
          size="small"
        >
          <MenuItem dir="rtl" value={1}>
            יום אחד
          </MenuItem>
          <MenuItem dir="rtl" value={7}>
            7 ימים
          </MenuItem>
          <MenuItem dir="rtl" value={30}>
            30 ימים
          </MenuItem>
        </Select>
      </Box>
      <DataGrid
        style={{
          backgroundColor: 'white',
          height: '70dvh',
        }}
        rows={analyticsData}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
      />
    </Box>
  )
}
