import { Box, CircularProgress, Typography } from '@mui/material'
import { UserOTPRequestDto } from 'api/dtos'
import { RequestStatus } from 'common/types'
import { useTranslation } from 'react-i18next'
import { selectUserState, useAppSelector } from 'store'
import { PendingInvitesItems } from './MentorComponents/PendingInvitesItems'

interface PendingInvitesProps {
  pendingInvite?: UserOTPRequestDto[]
}

export const PendingInvites = (props: PendingInvitesProps) => {
  const { status, error } = useAppSelector(selectUserState)
  const { t } = useTranslation()

  const renderPendingInvites = () => {
    if (status === RequestStatus.Loading) {
      return <CircularProgress />
    }

    if (status === RequestStatus.Failed) {
      return <Typography color="error">Error: {error}</Typography>
    }

    if (!props.pendingInvite || props.pendingInvite?.length === 0) {
      return (
        <Typography variant="h6" color={'GrayText'} sx={{ marginTop: '3em', textAlign: 'center', fontSize: '1rem' }}>
          {t('inviteUsers.noPendingInvites')}
        </Typography>
      )
    }

    return props.pendingInvite?.map(invite => <PendingInvitesItems key={invite.id} invite={invite} />)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '84vh',
        justifyContent: 'space-between',
        padding: '0 1em 1em 1em',
      }}
    >
      <Box flexGrow={1} overflow="auto" mb={5}>
        {renderPendingInvites()}
      </Box>
    </Box>
  )
}
