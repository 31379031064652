import {Box, Button, Card, Modal, Typography} from '@mui/material'

export interface AboutUsModalProps {
  handleClose: () => void
  open: boolean
}

export const AboutUsModal: React.FC<AboutUsModalProps> = ({ handleClose, open }) => {
  const aboutUsData = {
    name: 'אני על זה',
    description: [
      'אפליקציה לניהול משימות תוך התמודדות עם דחיינות עבור מי שמתמודד עם פוסט טראומה על רקע לאומי.',
      'פותחה עבור נט"ל על ידי צוות מתנדבים במסגרת Code for Israel.',
      'הצוות הורכב ממפתחים מחברת zadara יחד עם מעצבים, אנשי מוצר ומטופלים מנט"ל, שחברו יחד ועבדו ימים ולילות על מנת למצוא אפליקציה שתיטיב את חיי היומיום וההספקים.',
      'מקווים שהמוצר ייענה לצרכיך ומאחלים לך המון הצלחה בההגשמה האישית שלך.',
      'וכמובן נשמח מאוד לקבל פידבק 😄',
      'אתה על זה!',
    ],
  }

  return (
    <Modal open={open} keepMounted style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Card
        dir="rtl"
        style={{
          margin: '0.5em',
          paddingBottom: '2em',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            padding: '2em',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5">{aboutUsData.name}</Typography>
          <br />

          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'right',
            width: '100%',
          }}>
            <Typography variant="body1" dir="rtl">
            {aboutUsData.description[0]}
          </Typography>
            <br/>
            <Typography variant="body1" dir="rtl">
              {aboutUsData.description[1]}
            </Typography>

            <Typography variant="body1" dir="rtl">
              {aboutUsData.description[2]}
            </Typography>
            <br/>
            <Typography variant="body1" dir="rtl">
              {aboutUsData.description[3]}
            </Typography>
            <br/>
            <Typography variant="body1" dir="rtl">
              {aboutUsData.description[4]}
            </Typography>
            <br/>
            </Box>
            <Typography variant="h6" dir="rtl">
              {aboutUsData.description[5]}
            </Typography>

        </div>
        <Button variant={'contained'} onClick={() => handleClose()}>
          סגור
        </Button>
      </Card>
    </Modal>
  )
}
