import { Box } from '@mui/material'

export interface GreyLabelProps {
  children: JSX.Element
}

export default function GreyLabel(props: GreyLabelProps) {
  return (
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: 'flex-end',
        backgroundColor: '#E5E5E5',
        borderRadius: '10px',
        padding: '0.2em 0.4em',
        fontSize: '0.85em',
        opacity: '0.7',
      }}
    >
      {props.children}
    </Box>
  )
}
