import { Grid, Radio } from '@mui/material'
import { UserQuestionAnswer } from 'api/dtos'

const answerMapping = new Map([
  [1, UserQuestionAnswer.ONE],
  [2, UserQuestionAnswer.TWO],
  [3, UserQuestionAnswer.THREE],
  [4, UserQuestionAnswer.FOUR],
  [5, UserQuestionAnswer.FIVE],
  [6, UserQuestionAnswer.SIX],
])

export const RadioGroup = ({
  answer,
  setAnswer,
}: {
  answer: UserQuestionAnswer
  setAnswer: (newValue: UserQuestionAnswer) => void
}) => {
  const evalUserQuestionAnswer = (a: string) => answerMapping.get(parseInt(a)) || UserQuestionAnswer.THREE

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newAnswer = evalUserQuestionAnswer(e.target.value)
    setAnswer(newAnswer)
  }

  const controlProps = (item: number) => ({
    checked: answer === answerMapping.get(item),
    onChange: handleChange,
    value: item.toString(),
  })
  const radioButton = (label: number) => (
    <Grid width="2.5em" container margin="0">
      <Grid item width="2.5em" justifyContent={'center'} alignContent="center" alignItems="center" display="flex">
        <Radio {...controlProps(label)} />
      </Grid>
    </Grid>
  )
  return (
    <Grid container sx={{ backgroundColor: '#F9F9F9', width: '100%', borderRadius: '0.25em' }}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ padding: '0 0.5em' }}>
        {radioButton(1)}
        {radioButton(2)}
        {radioButton(3)}
        {radioButton(4)}
        {radioButton(5)}
        {radioButton(6)}
      </Grid>
    </Grid>
  )
}
