import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { achievementsReducer } from './achievements-slice'
import { alertsReducer } from './alerts-slice'
import { authReducer } from './auth-slice'
import layoutReducer from './layout-slice'
import { notificationsReducer } from './notifications-slice'
import { questionnairesReducer } from './questionnaires-slice'
import { questionsReducer } from './questions-slice'
import { reinforcementsReducer } from './reinforcements-slice'
import { subtasksReducer } from './subtasks-slice'
import { successesReducer } from './successes-slice'
import { tasksReducer } from './tasks-slice'
import { userReducer } from './user-slice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    tasks: tasksReducer,
    subtasks: subtasksReducer,
    achievements: achievementsReducer,
    questions: questionsReducer,
    questionnaires: questionnairesReducer,
    alerts: alertsReducer,
    successes: successesReducer,
    reinforcements: reinforcementsReducer,
    notifications: notificationsReducer,
    layout: layoutReducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
