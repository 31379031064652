import { Box } from '@mui/material'
import { useRef } from 'react'

export interface HorizontalScrollerProps {
  itemsList: JSX.Element[] | undefined
  fetchMoreDataFunction: () => void
}

export const HorizontalScroller: React.FC<HorizontalScrollerProps> = ({ itemsList, fetchMoreDataFunction }) => {
  const scrollerRef = useRef(null)

  const handleScroll = () => {
    const { scrollLeft, clientWidth, scrollWidth }: any = scrollerRef.current
    if (scrollWidth - scrollLeft === clientWidth) {
      fetchMoreDataFunction()
    }
  }

  return (
    <Box
      style={{ display: 'flex', width: '100%', direction: 'rtl', flexDirection: 'column', alignItems: 'center' }}
      onScroll={() => handleScroll()}
      ref={scrollerRef}
    >
      {itemsList}
    </Box>
  )
}
