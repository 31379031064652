import { Subtask } from 'api/dtos'

export const genUniqueId = (): string => {
  const dateStr = Date.now().toString(36) // convert num to base 36 and stringify

  const randomStr = Math.random().toString(36).substring(2, 8) // start at index 2 to skip decimal point

  return `${dateStr}-${randomStr}`
}

export const formatDate = (date: Date) => {
  const dateObj = new Date(date)
  return `${dateObj.getDate()}.${dateObj.getMonth() + 1}.${dateObj.getFullYear()}`
}

const getDaysUntil = (date: Date) => {
  const today = new Date()
  const dateToCheck = new Date(date)
  const difference = dateToCheck.getTime() - today.getTime()
  const TotalDays = Math.ceil(difference / (1000 * 3600 * 24))
  return TotalDays
}

export const getSubtaskLength = (subtasks: Subtask[]) => subtasks.length
export const getNearestSubtaskDeadline = (subtasks: Subtask[]) =>
  subtasks.length
    ? subtasks.reduce((prev: number | undefined, curr: Subtask) => {
        const currentDays = getDaysUntil(curr.deadline)
        return prev ? Math.min(prev, currentDays) : currentDays
      }, undefined)
    : undefined
