import i18n, { Resource } from 'i18next'
import detector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import en from './en.json'
import he from './he.json'

const resources: Resource = {
  en: {
    translation: en,
  },
  he: {
    translation: he,
  },
}

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    supportedLngs: ['he'],
    fallbackLng: 'he',
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
  })

export { i18n }
