import CssBaseline from '@mui/material/CssBaseline'
import useWindowSize from 'hooks/useWindowSize'
import './AppStyle.css'
import { RootRouter } from './routes'

function App() {
  useWindowSize()
  return (
    <div className="App" style={{ height: '100%' }}>
      <CssBaseline />
      <RootRouter />
    </div>
  )
}

export default App
