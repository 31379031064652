import {
  AnswerQuestionnaireRequest,
  CreateQuestionnaireRequest,
  Questionnaire,
  UpdateQuestionnaireRequest,
} from '../dtos'
import { axiosInstance } from '../network'
import { ApiResponse } from '../types'
import { RequestConfig } from '../types/request-config'

const getQuestionnaire = async (id: number, config?: RequestConfig): Promise<Questionnaire> => {
  const axiosResponse = await axiosInstance.get<never, ApiResponse<Questionnaire>>('/questionnaires/' + id, config)
  return axiosResponse.data!
}

const listQuestionnaires = async (config?: RequestConfig): Promise<Questionnaire[]> => {
  const axiosResponse = await axiosInstance.get<never, ApiResponse<Questionnaire[]>>('/questionnaires/list', config)
  return axiosResponse.data!
}

const deleteQuestionnaire = async (id: number, config?: RequestConfig): Promise<Questionnaire[]> => {
  const axiosResponse = await axiosInstance.delete<never, ApiResponse<Questionnaire[]>>('/questionnaires/' + id, config)
  return axiosResponse.data!
}

const createQuestionnaire = async (
  createQuestionnaireRequest: CreateQuestionnaireRequest,
  config?: RequestConfig,
): Promise<Questionnaire[]> => {
  const apiResponse = await axiosInstance.post<CreateQuestionnaireRequest, ApiResponse<Questionnaire[]>>(
    '/questionnaires/create',
    createQuestionnaireRequest,
    config,
  )
  return apiResponse.data!
}
const answerQuestionnaire = async (
  answerQuestionnaireRequest: AnswerQuestionnaireRequest,
  config?: RequestConfig,
): Promise<Questionnaire[]> => {
  const apiResponse = await axiosInstance.post<AnswerQuestionnaireRequest, ApiResponse<Questionnaire[]>>(
    '/questionnaires/answer',
    answerQuestionnaireRequest,
    config,
  )
  return apiResponse.data!
}

const updateQuestionnaire = async (
  updateQuestionnaireRequest: UpdateQuestionnaireRequest,
  config?: RequestConfig,
): Promise<Questionnaire> => {
  const axiosResponse = await axiosInstance.put<UpdateQuestionnaireRequest, ApiResponse<Questionnaire>>(
    '/questionnaires/' + updateQuestionnaireRequest.id,
    updateQuestionnaireRequest,
    config,
  )
  return axiosResponse.data!
}
export const questionnaireApiRequest = {
  getQuestionnaire,
  listQuestionnaires,
  createQuestionnaire,
  deleteQuestionnaire,
  updateQuestionnaire,
  answerQuestionnaire,
}
