import { Box, Grid, Typography } from '@mui/material'
import { theme } from 'theme'
import { MenteeDetailedFragments } from '../MenteeDetailedInfoNew'

interface MentorSelectHeaderProps {
  currentTab: MenteeDetailedFragments
  handleClick: (tab: MenteeDetailedFragments) => void
  tabType: MenteeDetailedFragments
  tabText: string
}

export const MentorSelectHeader = (props: MentorSelectHeaderProps) => {
  return (
    <Grid
      container
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
      }}
      onClick={() => props.handleClick(props.tabType)}
    >
      <Typography
        variant={'h6'}
        sx={{
          opacity: props.currentTab === props.tabType ? '100%' : '60%',
          mt: '0.5em',
          margin: '0.5em 0',
        }}
        align={'center'}
      >
        {props.tabText}
      </Typography>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          border:
            props.currentTab === props.tabType
              ? `0.08em solid ${theme.palette.primary.main}`
              : '0.08em solid #b3b3b369',
          borderRadius: '20px',
          height: '0em',
          width: '50vw',
          boxShadow: '0px 2px 5px #b3b3b369',
        }}
      />
    </Grid>
  )
}
