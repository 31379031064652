import { Task } from './task'

export enum Role {
  User = 'User',
  Mentor = 'Mentor',
  Admin = 'Admin',
}

export enum EfficiencyTimes {
  MORNING = 'MORNING',
  NOON = 'NOON',
  EVENING = 'EVENING',
}

export interface User {
  id: number
  email: string
  name?: string
  role: Role
  mentorId?: number
  efficiencyTime?: EfficiencyTimes
  profilePictureURL?: string
  profilePictureBytea?: any
  questionAnswers: UserQuestionAndAnswer[]
  enableNotifications?: boolean
  phoneNumber?: string
  stopAllTimeStamp?: Date | null
  mentees?: SimpleMenteeDto[]
  UserQuestionAndAnswer?: boolean | null
  otpValidated?: boolean
}

export interface UpdateUserRequest {
  id: number
  name?: string
  role?: Role
  mentorId?: number
  efficiencyTime?: EfficiencyTimes
  profilePictureURL?: string
  profilePictureBytea?: string
  phoneNumber?: string
  stopAllTimeStamp?: Date | null
  enableNotifications?: boolean
  mentees?: SimpleMenteeDto[]
}

export interface UpdateUserAvatarRequest {
  id: number
  profilePictureBytea?: any
}

export interface Mentor {
  id: number
  name: string
  phoneNumber?: string
}

export interface UserQuestionAndAnswer {
  id: number
  title: string
  userID: number
  questionID: number
  answer: number
}

export interface UserProcrastinations {
  userId: number
  expectation: number
  value: number
  time: number
}

export interface MenteesDto {
  Mentees: MenteeDto[]
}

export interface MenteeDto {
  name?: string | null
  phoneNumber?: string | null
  email: string
  profilePictureBytea?: Buffer | null
  tasks: Task[]
  loginDayStreak?: number | null
  weekLoginStreak?: number | null
  weeklyStopAllCount?: number | null
  userProcrastinations?: UserProcrastinations
  stopAllTimeStamp?: Date | null
  id?: number
}

export interface SimpleMenteeDto {
  id: number
  name: string | null
}

export interface InviteUserRequestDto {
  id?: number
  email: string
  name: string | null
  phoneNumber: string | null
  mentorId: number | null
}

export interface UserOTPRequestDto {
  id: number
  createdAt: Date
  validUntil: Date
  tokenUsed: boolean
  otp?: string
  user: InviteUserRequestDto
}

export interface OTPValidationRequestDto {
  otp: string
  userId: number
}

export interface AnalyticsDto {
  menteeId: number
  menteeName: string
  mentorName: string
  numOfSessions: number
  lastLoginDate: Date | null
  numOfTasksCreated: number
  numOfTasksClosed: number
}
