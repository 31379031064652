import DeleteIcon from '@mui/icons-material/Delete'
import { Button, Dialog, DialogActions, DialogTitle, IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Xicon } from '../TaskCreation/icons/Xicon'

interface DeleteDialogPopupProps {
  isOpen: boolean
  onClose: () => void
  onConfirmDelete: () => void
  type: 'task' | 'subtask'
}

export default function DeleteDialogPopup({ isOpen, onClose, onConfirmDelete, type }: DeleteDialogPopupProps) {
  const typeToDelete: string = type === 'task' ? 'המטרה' : 'המשימה'
  const { t } = useTranslation()

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="alert-dialog-title-deleteTask"
        aria-describedby="alert-dialog-description-deleteTask"
        dir="rtl"
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: '5%',
            width: '85%',
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title-deleteTask"
          sx={{ textAlign: 'center', mt: '0.5em', fontWeight: 500, textAlignLast: 'center' }}
        >
          <IconButton sx={{ position: 'absolute', right: '0.2em', top: '0.2em' }} onClick={() => onClose()}>
            <Xicon />
          </IconButton>
          {t('popUp.delete', { typeToDelete: typeToDelete })}
        </DialogTitle>
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', padding: '1em', mb: '0.5em' }}>
          <Button
            onClick={() => onClose()}
            color="primary"
            variant="outlined"
            sx={{
              color: '#000000',
              boxShadow: '0px 0px 3px rgba(45, 45, 45, 0.25)',
              border: '1px solid #FFC888',
              borderRadius: '0.4em',
              width: '20%',
              fontSize: '1rem',
            }}
          >
            {t('button.cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            autoFocus
            dir="ltr"
            endIcon={<DeleteIcon />}
            sx={{
              backgroundColor: '#FFAB48',
              boxShadow: '0px 0px 3px rgba(45, 45, 45, 0.25)',
              borderRadius: '0.4em',
              width: '45%',
              justifyContent: 'center',
              fontSize: '1rem',
            }}
            onClick={() => {
              onConfirmDelete()
              onClose()
            }}
          >
            {t('button.delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
