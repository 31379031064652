import CalendarTodayIcon from '@mui/icons-material/CalendarToday'

export interface CalenderLabelProps {
  formattedDate: String
}

export default function CalendarLabel(props: CalenderLabelProps) {
  return (
    <>
      {props.formattedDate}
      <CalendarTodayIcon fontSize="small" sx={{ marginLeft: '0.2em' }} />
    </>
  )
}
