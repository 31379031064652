import { Box, Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { paths } from 'routes'
import Android_Step1 from '../Onboarding/addLinkImages/Android_Step1.jpg'
import Android_Step2 from '../Onboarding/addLinkImages/Android_Step2.jpg'
import Android_Step3 from '../Onboarding/addLinkImages/Android_Step3.jpg'
import iOS_Step1 from '../Onboarding/addLinkImages/iOS_Step1.jpg'
import iOS_Step2 from '../Onboarding/addLinkImages/iOS_Step2.jpg'
import iOS_Step3 from '../Onboarding/addLinkImages/iOS_Step3.jpg'

export const AddLinkToHomePage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const stepImages = {
    iOS: [
      { src: iOS_Step1, width: '90%', height: '100%' },
      { src: iOS_Step2, width: '90%', height: '100%' },
      { src: iOS_Step3, width: '90%', height: '100%' },
    ],
    Android: [
      { src: Android_Step1, width: '90%', height: '100%' },
      { src: Android_Step2, width: '50%', height: '100%' },
      { src: Android_Step3, width: '80%', height: '100%' },
    ],
  }
  const steps = ['addLinkToHomePage.stepOne', 'addLinkToHomePage.stepTwo', 'addLinkToHomePage.stepThree']
  const detectMobileOS = () => {
    let userAgent = navigator.userAgent

    if (/android/i.test(userAgent)) {
      return 'Android'
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return 'iOS'
    }

    return 'Unknown'
  }
  const images = detectMobileOS() === 'iOS' ? stepImages.iOS : stepImages.Android

  const handleClick = () => {
    navigate(paths.MOBILE_HOME)
  }

  return (
    <Box
      sx={{
        padding: '2em 1em',
        direction: 'rtl',
        height: '100dvh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ marginBottom: -2 }}>
        <Typography variant="h2" sx={{ fontSize: '1.3em', fontWeight: 600, marginBottom: '1.5em' }}>
          {t('addLinkToHomePage.title')}
        </Typography>
        <Typography variant="body1" sx={{ marginTop: 1, fontSize: '1em', fontWeight: 550 }}>
          {t('addLinkToHomePage.howToInstall')}
        </Typography>
      </Box>
      {images.map((image, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'right',
            backgroundColor: '#F9F9F9',
            p: '1em',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '1em', width: '100%' }}>
            <Box
              sx={{
                width: 36,
                height: 36,
                borderRadius: '50%',
                border: '2px solid #6E1D93',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: '1em',
                flexShrink: 0,
              }}
            >
              <Typography variant="h6" component="span">
                {index + 1}
              </Typography>
            </Box>
            <Typography variant="h6" sx={{ fontSize: '1em', fontWeight: 500 }}>
              {t(steps[index])}
            </Typography>
          </Box>
          <img
            src={image.src}
            alt={`Step ${index + 1}`}
            style={{ maxWidth: '100%', borderRadius: '8px', width: image.width, height: image.height }}
          />
        </Box>
      ))}
      <Button variant={'contained'} onClick={handleClick} size="large" sx={{ width: '100%' }}>
        {t('addLinkToHomePage.finished')}
      </Button>
    </Box>
  )
}
