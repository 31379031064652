import { Checkbox, Grid } from '@mui/material'

interface SubtaskCheckboxProps {
  checked: boolean
  onSubtaskComplete: () => void
}

export const SubtaskCheckbox: React.FC<SubtaskCheckboxProps> = ({ checked, onSubtaskComplete }) => {
  return (
    <Grid item xs={1} sx={{ alignSelf: 'self-start' }}>
      <Checkbox
        checked={checked}
        onChange={onSubtaskComplete}
        color="default"
        sx={{
          width: '100%',
          color: 'black',
          marginTop: '-0.5em',
        }}
      />
    </Grid>
  )
}
