import { Box, Divider, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import { ReinforcementType } from 'api/dtos'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { RootState } from 'store'
import { updateSelectedReinforcementsObj } from 'store/reinforcements-slice'
import ReinforcementSection from './ReinforcementSection'
import ReinforcementsSentenceForm from './ReinforcementsSentenceForm'

const ReinforcementsSentences = () => {
  const [sentencesToCreate, setSentencesToCreate] = useState(0)

  const selectedReinforcementsObj = useSelector((state: RootState) => state.reinforcements.selectedReinforcementsObj)
  const [allSelected, setAllSelected] = useState(false)
  const dispatch = useDispatch()
  const location = useLocation()
  const fromTaskPage = location.state?.fromTaskPage

  const selectAll = () => {
    if (fromTaskPage) return
    const updatedReinforcements = selectedReinforcementsObj.map(reinforcement =>
      reinforcement.reinforcement.type === ReinforcementType.Text
        ? { ...reinforcement, selected: !allSelected }
        : reinforcement,
    )
    dispatch(updateSelectedReinforcementsObj(updatedReinforcements))
    // Toggle the allSelected state
    setAllSelected(!allSelected)
  }

  const handleCheckboxChange = (i: number, checked: boolean) => {
    const updatedReinforcements = selectedReinforcementsObj.map((reinforcement, index) => {
      return index === i ? { ...reinforcement, selected: checked } : reinforcement
    })
    // Dispatch an action to update selectedReinforcementsObj in the Redux store
    dispatch(updateSelectedReinforcementsObj(updatedReinforcements))
  }

  const sentenceList = (
    <Box>
      {selectedReinforcementsObj?.map((reinforcement, i) => {
        const isChecked = reinforcement.selected
        return (
          reinforcement.reinforcement.type === ReinforcementType.Text && (
            <Grid container key={i}>
              <Grid item>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{ textAlign: 'right', direction: 'rtl' }}
                        autoFocus
                        checked={isChecked}
                        onChange={e => handleCheckboxChange(i, e.target.checked)}
                        disabled={fromTaskPage} // Disable the checkbox if navigated from TaskPage
                      />
                    }
                    label={<Typography sx={{ padding: '0.5em 1.5em' }}>{reinforcement.reinforcement.text}</Typography>}
                  />
                  <Divider sx={{ width: '80%', alignSelf: 'center', margin: '0.5em 0' }} />
                </FormGroup>
              </Grid>
            </Grid>
          )
        )
      })}
      {[...Array(sentencesToCreate)].map((sentence, i) => {
        return (
          <Box key={i}>
            <ReinforcementsSentenceForm />
          </Box>
        )
      })}
    </Box>
  )
  return (
    <ReinforcementSection
      reinforcementType={'משפט'}
      Component={() => sentenceList}
      title={'משפטי חיזוק'}
      addItem={() => setSentencesToCreate(1)} //only allow 1 creation
      selectAll={selectAll}
    />
  )
}

export default ReinforcementsSentences
