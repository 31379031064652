import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getPermissions } from '../../common/utils/utils'
import { paths } from '../../routes'
import { selectUserState, useAppSelector } from '../../store'

export const Home = () => {
  const { user } = useAppSelector(selectUserState)
  const navigate = useNavigate()

  useEffect(() => {
    if (!user) {
      navigate(paths.LOGIN)
    } else {
      navigate(getPermissions(user), { replace: true })
    }
  }, [user, navigate])

  return <div />
}
