import { axiosInstance } from '../network'
import { ApiResponse } from '../types'
import { RequestConfig } from '../types/request-config'
import { Achievement, CreateAchievementRequest } from '../dtos/achievement'

const getAchievement = async (id: number, config?: RequestConfig): Promise<Achievement> => {
  const axiosResponse = await axiosInstance.get<never, ApiResponse<Achievement>>('/achievements/' + id, config)
  return axiosResponse.data!
}

const listAchievements = async (config?: RequestConfig): Promise<Achievement[]> => {
  const axiosResponse = await axiosInstance.get<never, ApiResponse<Achievement[]>>('/achievements/', config)
  return axiosResponse.data!
}

const listNAchievements = async (numberOfAchievements: number, config?: RequestConfig): Promise<Achievement[]> => {
  const axiosResponse = await axiosInstance.get<never, ApiResponse<Achievement[]>>(
    '/achievements/list/' + numberOfAchievements,
    config,
  )
  return axiosResponse.data!
}

const deleteAchievement = async (id: number, config?: RequestConfig): Promise<Achievement[]> => {
  const axiosResponse = await axiosInstance.delete<never, ApiResponse<Achievement[]>>('/achievements/' + id, config)
  return axiosResponse.data!
}

const createAchievement = async (
  createAchievementRequest: CreateAchievementRequest,
  config?: RequestConfig,
): Promise<Achievement[]> => {
  const apiResponse = await axiosInstance.post<CreateAchievementRequest, ApiResponse<Achievement[]>>(
    '/achievements/create',
    createAchievementRequest,
    config,
  )
  return apiResponse.data!
}

const listUsersAchievements = async (userId: number, config?: RequestConfig): Promise<Achievement[]> => {
  const axiosResponse = await axiosInstance.get<never, ApiResponse<Achievement[]>>('/achievements/list/user/' + userId, config)
  return axiosResponse.data!
}

export const achievementApiRequest = {
  getAchievement,
  listAchievements,
  listNAchievements,
  deleteAchievement,
  createAchievement,
  listUsersAchievements,
}
