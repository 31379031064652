import { Grid } from '@mui/material'
import { useContext } from 'react'
import { SharedSubtaskContext } from '../UseSharedSubtaskContext'
import { Xicon } from './icons/Xicon'

type TopSectionProps = {
  onClose: () => void
  setShowTitleOnly: (value: boolean) => void
  resetForm: () => void
}

export const TopSection = ({ onClose, setShowTitleOnly, resetForm }: TopSectionProps) => {
  const { setOpen, topTitle } = useContext(SharedSubtaskContext)

  return (
    <Grid
      container
      direction="column"
      textAlign={'right'}
      alignItems="center"
      justifyContent="center"
      sx={{ background: '#F1F1F1', pt: '1em' }}
    >
      <Grid container>
        <Grid item xs={1} padding="0.5em 0em 0em">
          <div
            onClick={() => {
              setOpen!(false)
              setShowTitleOnly(true)
              resetForm()
            }}
          >
            <Xicon />
          </div>
        </Grid>
        <Grid
          item
          xs={11}
          padding="0.5em 1.5em 1em"
          fontSize="1.3em"
          sx={{ textAlign: 'center', paddingRight: '2.5em' }}
        >
          {topTitle}
        </Grid>
      </Grid>
    </Grid>
  )
}
