import { Box, Button, Card, Modal, Typography } from '@mui/material'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

interface DeleteModalProps {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  text: string
  dispatchDelete: () => {}
}
export const DeleteModal = ({ isOpen, setIsOpen, text, dispatchDelete }: DeleteModalProps) => {
  const { t } = useTranslation()
  return (
    <Modal
      open={isOpen}
      keepMounted
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0.5em' }}
    >
      <Card
        dir="rtl"
        style={{
          padding: '2em',
          paddingBottom: '2em',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" style={{ fontWeight: 600, marginBottom: '1em' }}>
            {text}
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
            }}
          >
            <Button
              sx={{
                margin: '1em',
              }}
              variant="text"
              color="inherit"
              onClick={() => {
                dispatchDelete()
                setIsOpen(false)
              }}
            >
              {t('button.delete')}
            </Button>
            <Button
              sx={{
                margin: '1em',
              }}
              variant="contained"
              onClick={() => setIsOpen(false)}
            >
              {t('button.cancel')}
            </Button>
          </Box>
        </Box>
      </Card>
    </Modal>
  )
}
