import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined'
import ChromeReaderModeOutlinedIcon from '@mui/icons-material/ChromeReaderModeOutlined'
import Diversity1Icon from '@mui/icons-material/Diversity1'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import SettingsIcon from '@mui/icons-material/Settings'
import { Button, Typography } from '@mui/material'
import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { AboutUsModal } from '../../components/AboutUsModal'
import { logout, useAppDispatch } from '../../store'
import { MentorFragments } from './MentorDashboard'

export const MentorDashBoardSideMenu = ({
  style,
  setCurrentPage,
}: {
  style: React.CSSProperties
  setCurrentPage: Dispatch<SetStateAction<number>>
}) => {
  const dispatch = useAppDispatch()

  const [modalOpen, setModalOpen] = useState(false)

  const logOut = useCallback(() => {
    dispatch(logout())
  }, [dispatch])

  function linkToWebsite(url: string) {
    window.open(url, '_blank')
  }

  return (
    <div style={style}>
      <Button
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'row',
          margin: '0 0 1em 0',
          width: '100%',
          justifyContent: 'flex-end',
        }}
        variant={'text'}
        color={'inherit'}
        onClick={() => setCurrentPage(MentorFragments.Summary)}
      >
        <Typography variant={'h5'} sx={{ margin: '0 0.7em 0 0' }}>
          מתאמנים
        </Typography>
        <Diversity1Icon />
      </Button>

      <Button
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'row',
          margin: '0 0 1em 0',
          width: '100%',
          justifyContent: 'flex-end',
        }}
        color={'inherit'}
        onClick={() => setCurrentPage(MentorFragments.Settings)}
      >
        <Typography variant={'h5'} sx={{ margin: '0 0.7em 0 0' }}>
          הגדרות
        </Typography>
        <SettingsIcon />
      </Button>

      <Button
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'row',
          margin: '0 0 1em 0',
          width: '100%',
          justifyContent: 'flex-end',
        }}
        color={'inherit'}
        onClick={() => setCurrentPage(MentorFragments.InviteMentee)}
      >
        <Typography variant={'h5'} sx={{ margin: '0 0.7em 0 0' }}>
          הזמנת משתמש
        </Typography>
        <PersonAddIcon />
      </Button>

      <Button
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'row',
          margin: '0 0 1em 0',
          width: '100%',
          justifyContent: 'flex-end',
        }}
        color={'inherit'}
        onClick={() => setModalOpen(true)}
      >
        <Typography variant={'h5'} sx={{ margin: '0 0.7em 0 0' }}>
          עלינו
        </Typography>
        <InfoOutlinedIcon />
      </Button>

      <Button
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'row',
          margin: '0 0 1em 0',
          width: '100%',
          justifyContent: 'flex-end',
        }}
        color={'inherit'}
        onClick={() => linkToWebsite('https://forms.monday.com/forms/e7464e9cbe067daf6215f59e6f3f885c?r=use1')}
      >
        <Typography variant={'h5'} sx={{ margin: '0 0.7em 0 0' }}>
          דיווח על באג
        </Typography>
        <BugReportOutlinedIcon />
      </Button>

      <Button
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'row',
          margin: '0 0 1em 0',
          width: '100%',
          justifyContent: 'flex-end',
        }}
        color={'inherit'}
        onClick={() =>
          linkToWebsite('https://drive.google.com/drive/folders/1wT2HDx6idBRojBiEJMKqyi1x0WdHgXMW?usp=sharing')
        }
      >
        <Typography variant={'h5'} sx={{ margin: '0 0.7em 0 0' }}>
          דפי מידע
        </Typography>
        <ChromeReaderModeOutlinedIcon />
      </Button>

      <Button
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'row',
          margin: '0 0 1em 0',
          width: '100%',
          justifyContent: 'flex-end',
        }}
        color={'inherit'}
        onClick={() => logOut()}
      >
        <Typography variant={'h5'} sx={{ margin: '0 0.7em 0 0' }}>
          התנתקות
        </Typography>
        <ExitToAppIcon />
      </Button>

      <AboutUsModal handleClose={() => setModalOpen(false)} open={modalOpen} />

      <div style={{ flexGrow: 7, width: '100%' }} />
    </div>
  )
}
