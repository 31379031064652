import { Drawer } from '@mui/material'
import { Box } from '@mui/system'

export interface BottomDrawerProps {
  Content: React.ReactNode
  handleClose: () => void
  open: boolean
  height: number | undefined
}

export const BottomDrawer: React.FC<BottomDrawerProps> = ({ handleClose, open, Content, height }) => {
  return (
    <Drawer
      sx={{
        '& .MuiDrawer-paper': {
          height: `${height!}%`,
          backgroundColor: 'white !important',
        },
      }}
      PaperProps={{ elevation: 0, style: { backgroundColor: 'transparent' } }}
      ModalProps={{ onBackdropClick: handleClose }}
      anchor="bottom"
      open={open}
      elevation={300}
    >
      <Box
        style={{
          backgroundColor: 'white',
          borderRadius: '7px 7px 0px 0px',
          boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
        }}
      >
        {Content}
      </Box>
    </Drawer>
  )
}
