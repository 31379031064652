import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { Box, Button } from '@mui/material'
import { DataGrid, GridCellEditCommitParams, GridColDef, MuiEvent } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { ReinforcementType } from '../../api/dtos'
import { useAppDispatch, useAppSelector } from '../../store'
import {
  createReinforcementAsync,
  deleteReinforcementAsync,
  listAllReinforcementsAsync,
  selectReinforcementListState,
  updateReinforcementAsync,
} from '../../store/reinforcements-slice'
import { DeleteModal } from './components/DeleteModal'

export const ReinforcementTable = () => {
  const [rowToDelete, setRowToDelete] = useState(999)
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const dispatch = useAppDispatch()
  const { allReinforcements } = useAppSelector(selectReinforcementListState)

  useEffect(() => {
    dispatch(listAllReinforcementsAsync())
  }, [dispatch])

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'text',
      headerName: 'תוכן',
      editable: true,
      flex: 2,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'userId',
      headerName: 'user id',
      editable: true,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'url',
      headerName: 'url',
      editable: true,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      headerName: 'מחיקת חיזוק',
      field: 'delete',
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      renderCell: params => {
        return (
          <Button
            color={'inherit'}
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
            onClick={() => {
              if (params.row.id !== null) {
                setRowToDelete(params.row.id)
                setConfirmDeleteModal(true)
              }
            }}
          >
            <DeleteOutlineIcon />
          </Button>
        )
      },
    },
  ]

  const updateReinforcement = async (params: GridCellEditCommitParams, event: MuiEvent, details: any) => {
    const updatedReinforcement = allReinforcements?.find(q => q.id === params.id)
    const updateField = params.field.toString()
    const updateValue = params.value?.toString()
    console.log({ updateField, updateValue })
    if (updatedReinforcement) {
      // dispatch api request
      await dispatch(
        updateReinforcementAsync({
          ...updatedReinforcement,
          [updateField]: updateValue,
        }),
      )
      await dispatch(listAllReinforcementsAsync())
    }
  }

  const deleteReinforcement = async () => {
    const userId = allReinforcements.find(r => r.id === rowToDelete)?.userId
    if (userId === undefined) {
      return
    }
    await dispatch(deleteReinforcementAsync({ id: rowToDelete, userId }))
    await dispatch(listAllReinforcementsAsync())
  }

  const handleAddRow = async () => {
    await dispatch(
      createReinforcementAsync({
        userId: 0,
        text: 'חיזוק חדש',
        type: ReinforcementType.Text,
      }),
    )
    await dispatch(listAllReinforcementsAsync())
  }

  return (
    <Box
      sx={{
        flexGrow: 5,
        width: '100%',
      }}
    >
      <DataGrid
        getRowHeight={() => 'auto'}
        style={{
          backgroundColor: 'white',
        }}
        rows={allReinforcements?.slice().sort((a, b) => (a.id < b.id ? 1 : -1)) || []}
        columns={columns}
        pageSize={100}
        rowsPerPageOptions={[10, 20, 100]}
        onCellEditCommit={(params, event, details) => updateReinforcement(params, event, details)}
      />
      <Button variant="outlined" color="success" onClick={handleAddRow}>
        הוספת חיזוק
      </Button>
      <DeleteModal
        isOpen={confirmDeleteModal}
        setIsOpen={setConfirmDeleteModal}
        text={`בטוחים שאתם רוצים למחוק את החיזוק ${rowToDelete}?`}
        dispatchDelete={deleteReinforcement}
      />
    </Box>
  )
}
