import { Button, Card, CircularProgress } from '@mui/material'
import Alert from '@mui/material/Alert'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import { Dispatch, SetStateAction } from 'react'
import { ReinforcementType } from '../api/dtos/reinforcement'
import { RequestStatus } from '../common/types'
import { selectUserState, useAppSelector } from '../store'
import { selectReinforcementListState } from '../store/reinforcements-slice'
import { YoutubeEmbed } from './YoutubeEmbad'

export function ShowReinforcementModal({
  openReinforcementModal,
  setOpenReinforcementModal,
}: {
  openReinforcementModal: boolean
  setOpenReinforcementModal: Dispatch<SetStateAction<boolean>>
}) {
  const { user } = useAppSelector(selectUserState) // eslint-disable-line @typescript-eslint/no-unused-vars
  const { reinforcement, status, error } = useAppSelector(selectReinforcementListState)

  return (
    <Modal
      open={openReinforcementModal}
      keepMounted
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0.5em',
        textAlign: 'center',
        padding: '0.5em',
      }}
    >
      {status === RequestStatus.Loading ? (
        <CircularProgress />
      ) : (
        <Card
          dir="rtl"
          style={{
            width: '100%',
            padding: '2em 1em 1em 1em',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {reinforcement?.type === ReinforcementType.Text && (
            <Typography variant="h6" style={{ fontWeight: 500, marginBottom: '1em', flexGrow: 1 }}>
              {reinforcement?.text}
            </Typography>
          )}

          {reinforcement?.type === ReinforcementType.Video && !error ? (
            <YoutubeEmbed videoUrl={reinforcement?.url} />
          ) : null}

          {error && <Alert severity="error">{error}</Alert>}

          <Button
            variant="contained"
            onClick={() => {
              setOpenReinforcementModal(false)
            }}
          >
            חוזקתי 🙏
          </Button>
        </Card>
      )}
    </Modal>
  )
}
