import {
  AnalyticsDto,
  GoogleLoginRequest,
  InviteUserRequestDto,
  LoginRequest,
  MenteesDto,
  Mentor,
  OTPValidationRequestDto,
  SimpleMenteeDto,
  TokenResponse,
  UpdateUserAvatarRequest,
  UpdateUserRequest,
  User,
  UserOTPRequestDto,
  UserProcrastinations,
} from '../dtos'
import { axiosInstance } from '../network'
import { ApiResponse } from '../types'
import { RequestConfig } from '../types/request-config'

const login = async (loginRequest: LoginRequest, config?: RequestConfig): Promise<TokenResponse> => {
  const apiResponse = await axiosInstance.post<LoginRequest, ApiResponse<TokenResponse>>(
    '/auth/login',
    loginRequest,
    config,
  )
  return apiResponse.data!
}

const googleLogin = async (googleLoginRequest: GoogleLoginRequest, config?: RequestConfig): Promise<TokenResponse> => {
  const apiResponse = await axiosInstance.post<GoogleLoginRequest, ApiResponse<TokenResponse>>(
    '/auth/google-login',
    googleLoginRequest,
    config,
  )
  return apiResponse.data!
}

const getMyUser = async (config?: RequestConfig): Promise<User> => {
  const axiosResponse = await axiosInstance.get<never, ApiResponse<User>>('/users/me', config)
  return axiosResponse.data!
}

const updateUser = async (updateUserRequest: UpdateUserRequest, config?: RequestConfig): Promise<User> => {
  const axiosResponse = await axiosInstance.put<UpdateUserRequest, ApiResponse<User>>(
    '/users/' + updateUserRequest.id,
    updateUserRequest,
    config,
  )
  return axiosResponse.data!
}
const stopUserActivity = async (id: number, config?: RequestConfig): Promise<User> => {
  const axiosResponse = await axiosInstance.put<never, ApiResponse<User>>('/users/' + id + '/stop', config)
  return axiosResponse.data!
}
const resumeUserActivity = async (id: number, config?: RequestConfig): Promise<User> => {
  const axiosResponse = await axiosInstance.put<never, ApiResponse<User>>('/users/' + id + '/resume', config)
  return axiosResponse.data!
}

const updateUserAvatar = async (
  updateUserAvatarRequest: UpdateUserAvatarRequest,
  config?: RequestConfig,
): Promise<User> => {
  const axiosResponse = await axiosInstance.put<UpdateUserAvatarRequest, ApiResponse<User>>(
    '/users/avatar/' + updateUserAvatarRequest.id,
    updateUserAvatarRequest,
    config,
  )
  return axiosResponse.data!
}

const listMentors = async (config?: RequestConfig): Promise<Mentor[]> => {
  const axiosResponse = await axiosInstance.get<never, ApiResponse<Mentor[]>>('/users/mentors', config)
  return axiosResponse.data!
}

const listMentees = async (mentorId: number, config?: RequestConfig): Promise<MenteesDto> => {
  const axiosResponse = await axiosInstance.get<never, ApiResponse<MenteesDto>>('/users/mentees/' + mentorId, config)
  return axiosResponse.data!
}

const listAllMentees = async (config?: RequestConfig): Promise<SimpleMenteeDto[]> => {
  const axiosResponse = await axiosInstance.get<never, ApiResponse<SimpleMenteeDto[]>>('/users/allMentees/', config)
  return axiosResponse.data!
}

const getProcrastinationResults = async (id: number, config?: RequestConfig): Promise<UserProcrastinations> => {
  const axiosResponse = await axiosInstance.get<never, ApiResponse<UserProcrastinations>>(
    '/users/procrastination/' + id,
    config,
  )
  return axiosResponse.data!
}

const listAllUsers = async (config?: RequestConfig): Promise<User[]> => {
  const axiosResponse = await axiosInstance.get<never, ApiResponse<User[]>>('/users/allUsers/', config)
  return axiosResponse.data!
}

const deleteUser = async (id: number, config?: RequestConfig): Promise<User> => {
  const axiosResponse = await axiosInstance.delete<never, ApiResponse<User>>('/users/' + id, config)
  return axiosResponse.data!
}

const inviteUser = async (inviteRequest: InviteUserRequestDto, config?: RequestConfig): Promise<User> => {
  const apiResponse = await axiosInstance.post<InviteUserRequestDto, ApiResponse<User>>(
    '/users/invite',
    inviteRequest,
    config,
  )
  return apiResponse.data!
}

const getPendingInvitesByMentor = async (mentorId: number, config?: RequestConfig): Promise<UserOTPRequestDto[]> => {
  const axiosResponse = await axiosInstance.get<never, ApiResponse<UserOTPRequestDto[]>>(
    `/users/pendingInvites/${mentorId}`,
    config,
  )
  return axiosResponse.data!
}

const otpValidation = async (otpValidationReq: OTPValidationRequestDto, config?: RequestConfig): Promise<boolean> => {
  const apiResponse = await axiosInstance.post<InviteUserRequestDto, ApiResponse<boolean>>(
    '/users/otpValidation',
    otpValidationReq,
    config,
  )
  return apiResponse.data!
}

const refreshOTP = async (requestId: number, config?: RequestConfig): Promise<User> => {
  const axiosResponse = await axiosInstance.put<number, ApiResponse<User>>('/users/' + requestId + '/refresh', config)
  return axiosResponse.data!
}

const getUserOtpForAdmin = async (id: number, config?: RequestConfig): Promise<OTPValidationRequestDto> => {
  const apiResponse = await axiosInstance.get<never, ApiResponse<OTPValidationRequestDto>>('/users/otp/' + id, config)
  return apiResponse.data!
}

const getAnalyticsForAdmin = async (timeFilter: number, config?: RequestConfig): Promise<AnalyticsDto[]> => {
  const apiResponse = await axiosInstance.get<never, ApiResponse<AnalyticsDto[]>>('/users/analytics/' + timeFilter)
  return apiResponse.data!
}

export const userApiRequest = {
  login,
  googleLogin,
  getMyUser,
  updateUser,
  updateUserAvatar,
  listMentors,
  getProcrastinationResults,
  stopUserActivity,
  resumeUserActivity,
  listMentees,
  listAllMentees,
  listAllUsers,
  deleteUser,
  inviteUser,
  getPendingInvitesByMentor,
  otpValidation,
  refreshOTP,
  getUserOtpForAdmin,
  getAnalyticsForAdmin,
}
