import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiRequests } from '../api'
import { SuccessCategories } from '../api/dtos/success'
import { RequestStatus } from '../common/types'
import { RootState } from './store'

export interface SuccessesState {
  successes?: SuccessCategories
  status: RequestStatus
  error?: string
}

const initialState: SuccessesState = {
  successes: undefined,
  status: RequestStatus.Idle,
  error: undefined,
}

export const listSuccessesAsync = createAsyncThunk(
  'successes/list',
  async (userId: number): Promise<SuccessCategories> => {
    return await apiRequests.listSuccesses(userId)
  },
)

export const achievementsSlice = createSlice({
  name: 'successes',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder

      .addCase(listSuccessesAsync.pending, state => {
        state.status = RequestStatus.Loading
      })
      .addCase(listSuccessesAsync.fulfilled, (state, action) => {
        state.status = RequestStatus.Idle
        state.successes = action.payload
      })
      .addCase(listSuccessesAsync.rejected, (state, action) => {
        state.error = action.error.message
        state.status = RequestStatus.Failed
      })
  },
})

export const selectSuccessListState = (state: RootState) => state.successes
export const successesReducer = achievementsSlice.reducer
