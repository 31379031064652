const config = {
  storage: {
    REFRESH_TOKEN_KEY: 'AUTH_REFRESH_TOKEN',
    TOKEN_KEY: 'AUTH_TOKEN',
    USER_KEY: 'AUTH_USER',
    TASKS_KEY: 'TASKS_LIST',
    SUBTASKS_KEY: 'SUBTASKS_LIST',
    QUESTIONNAIRE_KEY: 'QUESTIONNAIRE_LIST',
    NOTIFICATIONS_KEY: 'NOTIFICATIONS_LIST',
    TASKS_ALERTS: 'TASKS_ALERTS',
    ACHIEVEMENTS_KEY: 'ACHIEVEMENTS_LIST',
    MENTORS_KEY: 'MENTORS_USER',
    REINFORCEMENTS_KEY: 'REINFORCEMENTS_KEY',
    ALL_REINFORCEMENTS_KEY: 'ALL_REINFORCEMENTS_KEY',
    SELECTED_REINFORCEMENTS_KEY: 'SELECTED_REINFORCEMENTS_KEY',
  },
  api: {
    API_URL: process.env.REACT_APP_API_URL!,
    TIMEOUT: parseInt(process.env.REACT_APP_TIMEOUT!),
  },
  auth: {
    GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID!,
  },
} as const

export { config }
