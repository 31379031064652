import { Grid } from '@mui/material'
import { UserQuestionAnswer } from 'api/dtos'
import { Dispatch, SetStateAction } from 'react'
import { Question } from '../../api/dtos/question'
import { RadioGroup } from './RadioGroup'

export const QuestionInteraction = ({
  questions,
  questionAnswers,
  setQuestionAnswers,
}: {
  questions: Question[]
  questionAnswers: UserQuestionAnswer[]
  setQuestionAnswers: Dispatch<SetStateAction<UserQuestionAnswer[]>>
}) => {
  const setQuestionAnswer = (index: number, newValue: UserQuestionAnswer) => {
    setQuestionAnswers(prevAnswers => prevAnswers.map((answer, i) => (i === index ? newValue : answer)))
  }
  return (
    <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
      {questions.map((q, i) => (
        <Grid container key={i} marginBottom={'3em'}>
          <Grid item xs={12} style={{ fontSize: '1.25em', textAlign: 'right' }}>
            {q.statement}
          </Grid>
          <Grid item xs={12} style={{ fontSize: '0.7em', marginTop: '1em' }}>
            <RadioGroup answer={questionAnswers[i]} setAnswer={newValue => setQuestionAnswer(i, newValue)} />
          </Grid>
          <Grid item container xs={12} justifyContent="space-between" style={{ fontSize: '1em', marginTop: '1em' }}>
            <Grid item xs={6} style={{ textAlign: 'left' }}>
              {'בכלל לא אני'}
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              {'מאוד אני'}
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}
