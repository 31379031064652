import {Box, Typography} from "@mui/material";
import logo from '../../icons/AniAlZeLogo.png'


export const Forbidden = () => {

  return (
    <Box
    sx={{
      direction: 'rtl',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    >
       <img src={logo} alt={logo} style={{ width: '15em', height: '15em', margin: '0 0 2em 0' }} />
      <Typography variant={'h3'} style={{fontWeight: 600}} align={'center'}>
      בטוחים שאתם צריכים להיות פה? 🤔
      </Typography>
      <Typography variant={'h5'} style={{fontWeight: 400}}>
      אין לכם גישה לדף זה
      </Typography>
      <Typography variant={'h5'} style={{fontWeight: 400}}>
      Error 403 - Forbidden
      </Typography>
    </Box>
  )
}
