import CloseIcon from '@mui/icons-material/Close'
import DarkModeIcon from '@mui/icons-material/DarkMode'
import LightModeIcon from '@mui/icons-material/LightMode'
import WbTwilightIcon from '@mui/icons-material/WbTwilight'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Modal,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { SetStateAction, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { paths } from 'routes'
import { selectUserState, useAppSelector } from 'store'
import OnBoardingText from './QuestionnaireCreate/OnBoardingText'
import { CenterAbsoluteStyledGridBottom, QuestionnaireButton } from './tools'

export const QuestionnaireEfficiency = () => {
  const { t } = useTranslation()
  const { user } = useAppSelector(selectUserState)
  const navigate = useNavigate()
  const [timeSelected, setTimeSelected] = useState('11:00')
  const [openTermsOfUsModal, setOpenTermsOfUsModal] = useState(false)
  const [agreedToTermsOfUse, setAgreedToTermsOfUse] = useState(false)
  const [scrollProgress, setScrollProgress] = useState(0)
  const contentRef = useRef<HTMLDivElement>(null)
  const [forceRender, setForceRender] = useState(false)

  const title = (
    <Typography variant="h5" sx={{ fontWeight: 500, fontSize: '1.5em' }}>
      {t('questionnaire.efficiency.oneLast')}
    </Typography>
  )

  const finish = () => submitForm()
  const finishButton = (
    <QuestionnaireButton
      disabled={!agreedToTermsOfUse}
      sx={{ backgroundColor: !agreedToTermsOfUse ? '#CCC' : '#FFAB48' }}
      onClick={finish}
    >
      {t('questionnaire.canContinue')}
    </QuestionnaireButton>
  )
  const [selectedTime, setSelectedTime] = useState('11:00')
  const handleTimeChange = (event: { target: { value: SetStateAction<string> } }) => {
    setSelectedTime(event.target.value)
    setTimeSelected(event.target.value)
  }

  const submitForm = async () => {
    navigate(paths.QUESTIONS)
  }

  const handleOpenTermsOfUsModal = () => {
    setOpenTermsOfUsModal(true)
    setForceRender(!forceRender)
  }

  const handleCloseTermsOfUsModal = () => {
    setScrollProgress(0)
    setOpenTermsOfUsModal(false)
  }

  const formControlLabelStyle = (value: string) => ({
    border: `1px solid ${timeSelected === value ? '#FFAB48' : '#CCC'}`,
    borderRadius: '10px',
    padding: '0.5em',
    margin: '0.5em',
    flexDirection: 'row-reverse',
    '& .MuiFormControlLabel-label': {
      width: '100%',
    },
  })

  const labelContentStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '1em',
  }

  const handleScroll = (event: any) => {
    const element = event.target
    const totalScrollHeight = element.scrollHeight - element.clientHeight
    const currentScroll = element.scrollTop
    const progress = (currentScroll / totalScrollHeight) * 100
    setScrollProgress(progress)
  }

  useEffect(() => {
    // user already answered - go to home page
    if (!user.UserQuestionAndAnswer) {
      navigate(paths.MOBILE_HOME)
    }
    const element = contentRef.current
    if (element && openTermsOfUsModal) {
      element.addEventListener('scroll', handleScroll)
      setScrollProgress(0)
    }
    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll)
      }
    }
  }, [openTermsOfUsModal, user.UserQuestionAndAnswer, navigate])

  const isMobile = useAppSelector(state => state?.layout.isMobileView)

  return (
    <Grid
      style={{
        height: '100vh',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        padding: '1em',
      }}
    >
      <Grid item xs={12} textAlign={'right'} marginRight="-1em"></Grid>
      <Grid item xs={12} textAlign={'right'} style={{ direction: 'rtl', paddingRight: '0.5em' }} marginTop="15%">
        {title}
      </Grid>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          marginTop: '1.5em',
          textAlign: 'right',
          gap: '2em',
          flexGrow: 1,
        }}
      >
        <FormControl component="fieldset" sx={{ width: '100%' }}>
          <RadioGroup sx={{ width: '100%' }} value={selectedTime} onChange={handleTimeChange}>
            <FormControlLabel
              value="11:00"
              control={<Radio />}
              label={
                <Box sx={labelContentStyle}>
                  <WbTwilightIcon fontSize="large" sx={{ color: timeSelected === '11:00' ? '#FFAB48' : '#CCC' }} />
                  <span dir="rtl">11:00 בבוקר</span>
                </Box>
              }
              sx={formControlLabelStyle('11:00')}
            />
            <FormControlLabel
              value="15:00"
              control={<Radio />}
              label={
                <Box sx={labelContentStyle}>
                  <LightModeIcon fontSize="large" sx={{ color: timeSelected === '15:00' ? '#FFAB48' : '#CCC' }} />
                  <span dir="rtl">15:00 בצהריים</span>
                </Box>
              }
              sx={formControlLabelStyle('15:00')}
            />
            <FormControlLabel
              value="20:00"
              control={<Radio />}
              label={
                <Box sx={labelContentStyle}>
                  <DarkModeIcon fontSize="large" sx={{ color: timeSelected === '20:00' ? '#FFAB48' : '#CCC' }} />
                  <span dir="rtl">20:00 בערב</span>
                </Box>
              }
              sx={formControlLabelStyle('20:00')}
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box display={'flex'} mt={2} sx={{ minHeight: '10vh', justifyContent: 'right', flexGrow: 1 }}>
        <Typography variant="body1" sx={{ marginTop: '1em', direction: 'rtl', paddingRight: '0.5em' }}>
          {t('questionnaire.onceAday')}
          <br />
          {t('questionnaire.canAlwaysCancel')}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          flexGrow: 1,
          width: '100%',
          direction: 'rtl',
          minHeight: '15vh',
          mt: '5vh',
          '@media (max-height: 740px)': {
            mt: 0,
          },
        }}
      >
        <Typography variant={'h6'} align={'right'} style={{ fontWeight: 400, fontSize: '1em', paddingRight: '0.5em' }}>
          {t('onboarding.otp.TOS')}
        </Typography>
        <Link
          color={'secondary'}
          onClick={handleOpenTermsOfUsModal}
          underline={'always'}
          sx={{ paddingRight: '0.5em' }}
        >
          תנאי השימוש שלנו
        </Link>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox onChange={() => setAgreedToTermsOfUse(!agreedToTermsOfUse)} />}
            label={t('onboarding.otp.agree')}
            sx={{ marginRight: '0' }}
          ></FormControlLabel>
        </FormGroup>
      </Box>
      <CenterAbsoluteStyledGridBottom item xs={12}>
        {finishButton}
      </CenterAbsoluteStyledGridBottom>
      <Modal
        open={openTermsOfUsModal}
        onClose={handleCloseTermsOfUsModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '95vw',
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: 2,
            overflow: 'hidden',
            maxHeight: '80vh',
            direction: 'rtl',
          }}
        >
          <Box
            ref={contentRef}
            onScroll={handleScroll}
            sx={{
              height: '80vh',
              overflowY: 'auto',
              padding: '1em',
            }}
          >
            <IconButton
              aria-label="close"
              onClick={handleCloseTermsOfUsModal}
              sx={{
                position: 'absolute',
                left: 8,
                top: 8,
                color: 'black',
              }}
            >
              <CloseIcon />
            </IconButton>
            <OnBoardingText />
            <Box sx={{ textAlign: 'center' }}>
              <Button
                variant="contained"
                onClick={handleCloseTermsOfUsModal}
                sx={{ margin: '1em 0', width: isMobile ? '100%' : '50%' }}
              >
                לסגירה
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              bgcolor: 'background.paper',
            }}
          >
            <LinearProgress variant="determinate" value={scrollProgress} sx={{ color: '#FFAB48', height: '1vh' }} />
          </Box>
        </Box>
      </Modal>
    </Grid>
  )
}
