import { Box, CircularProgress, Typography } from '@mui/material'
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { MenteeDto, MenteesDto, User } from '../../api/dtos'
import {
  listMenteesAsync,
  listTasksOfUsersOfMentorAsync,
  pendingInvitesAsync,
  selectUserState,
  useAppDispatch,
  useAppSelector,
} from '../../store'
import { MenteeCard } from './MenteeCard'
import { MenteeCardMobile } from './MenteeCardMobile'
import { MenteeDetailedInfoNew } from './MenteeDetailedInfoNew'

interface MentorDashboardProps {
  setSelectedMentee: Dispatch<SetStateAction<MenteeDto | undefined>>
  selectedMentee: MenteeDto | undefined
  handleMenteeSelection: (user: MenteeDto | undefined) => void
  user: User
}

export const MentorDashBoardMenteesList = (props: MentorDashboardProps) => {
  const dispatch = useAppDispatch()
  const isMobile = useAppSelector(state => state.layout.isMobileView)
  const { status } = useAppSelector(selectUserState)
  const [localMentees, setLocalMentees] = useState<MenteesDto | null>(null)
  const [showRemovalMessage, setShowRemovalMessage] = useState(false)

  const fetchMentees = useCallback(async () => {
    const result = await dispatch(listMenteesAsync(props.user.id)).unwrap()
    setLocalMentees(result)
    dispatch(listTasksOfUsersOfMentorAsync(props.user.id))
  }, [dispatch, props.user.id])

  useEffect(() => {
    fetchMentees()
    dispatch(pendingInvitesAsync(props.user.id))
  }, [fetchMentees, props.user.id, dispatch])

  const handleMenteeRemoved = (removedMenteeId: number) => {
    if (localMentees) {
      const updatedMentees = {
        ...localMentees,
        Mentees: localMentees.Mentees.filter(m => m.id !== removedMenteeId),
      }
      setLocalMentees(updatedMentees)
    }
    props.setSelectedMentee(undefined)
    setShowRemovalMessage(true)
    setTimeout(() => setShowRemovalMessage(false), 5000)
  }

  if (status === 'loading' || !localMentees) {
    return <CircularProgress />
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '84vh',
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          padding: isMobile ? '1em' : '0 3em 0 3em',
          marginBottom: '1em',
        }}
      >
        {props.selectedMentee ? (
          <MenteeDetailedInfoNew
            mentee={props.selectedMentee}
            handleMenteeSelection={props.handleMenteeSelection}
            mentees={localMentees}
            user={props.user}
            onMenteeRemoved={handleMenteeRemoved}
          />
        ) : (
          localMentees.Mentees.map(mentee =>
            isMobile ? (
              <MenteeCardMobile mentee={mentee} key={mentee.id} handleMenteeSelection={props.handleMenteeSelection} />
            ) : (
              <MenteeCard mentee={mentee} key={mentee.id} handleMenteeSelection={props.handleMenteeSelection} />
            ),
          )
        )}
      </Box>

      <Box
        sx={{
          padding: '1em',
        }}
      >
        {showRemovalMessage && (
          <Typography
            sx={{
              textAlign: 'center',
              marginBottom: '1em',
              backgroundColor: '#F9F9F9',
              border: '1px solid #d5d4d4',
              borderRadius: '0.5em',
              padding: '1em',
            }}
          >
            ההסרה של המתאמנ/ת בוצעה בהצלחה
          </Typography>
        )}
      </Box>
    </Box>
  )
}
