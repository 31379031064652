import { CreateReinforcementRequest, Reinforcement, UpdateReinforcementRequest } from '../dtos'
import { axiosInstance } from '../network'
import { ApiResponse } from '../types'
import { RequestConfig } from '../types/request-config'

const getReinforcement = async (id: number, config?: RequestConfig): Promise<Reinforcement> => {
  const axiosResponse = await axiosInstance.get<never, ApiResponse<Reinforcement>>('/reinforcement/' + id, config)
  return axiosResponse.data!
}

const listReinforcements = async (userId: number, config?: RequestConfig): Promise<Reinforcement[]> => {
  const axiosResponse = await axiosInstance.get<never, ApiResponse<Reinforcement[]>>(
    '/reinforcement/list/' + userId,
    config,
  )
  return axiosResponse.data!
}

const listAllReinforcements = async (config?: RequestConfig): Promise<Reinforcement[]> => {
  const axiosResponse = await axiosInstance.get<never, ApiResponse<Reinforcement[]>>(
    '/reinforcement/list',
    config,
  )
  return axiosResponse.data!
}

const deleteReinforcement = async (id: number, config?: RequestConfig): Promise<Reinforcement[]> => {
  const axiosResponse = await axiosInstance.delete<never, ApiResponse<Reinforcement[]>>('/reinforcement/' + id, config)
  return axiosResponse.data!
}

const createReinforcement = async (
  createReinforcementRequest: CreateReinforcementRequest,
  config?: RequestConfig,
): Promise<Reinforcement[]> => {
  const apiResponse = await axiosInstance.post<CreateReinforcementRequest, ApiResponse<Reinforcement[]>>(
    '/reinforcement/create',
    createReinforcementRequest,
    config,
  )
  return apiResponse.data!
}

const listUsersReinforcements = async (userId: number, config?: RequestConfig): Promise<Reinforcement[]> => {
  const axiosResponse = await axiosInstance.get<never, ApiResponse<Reinforcement[]>>(
    '/reinforcement/list/user/' + userId,
    config,
  )
  return axiosResponse.data!
}
const updateReinforcement = async (
  updateReinforcementRequest: UpdateReinforcementRequest,
  config?: RequestConfig,
): Promise<Reinforcement> => {
  const axiosResponse = await axiosInstance.put<UpdateReinforcementRequest, ApiResponse<Reinforcement>>(
    '/reinforcement/' + updateReinforcementRequest.id,
    updateReinforcementRequest,
    config,
  )
  return axiosResponse.data!
}

const randomReinforcement = async (userId: number, config?: RequestConfig): Promise<Reinforcement> => {
  const axiosResponse = await axiosInstance.get<never, ApiResponse<Reinforcement>>(
    '/reinforcement/random/' + userId,
    config,
  )
  return axiosResponse.data!
}

export const reinforcementsApiRequest = {
  listReinforcements,
  listAllReinforcements,
  randomReinforcement,
  getReinforcement,
  deleteReinforcement,
  createReinforcement,
  listUsersReinforcements,
  updateReinforcement,
}
