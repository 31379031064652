import { OrganizedTask, Subtask } from 'api/dtos'
import { useSharedSubtaskContext } from '../UseSharedSubtaskContext'
import { TaskCard } from './TaskCard'

export interface CardItem {
  text: string
  icon: JSX.Element
  warningLevel: CardItemWarningLevel
  method?: (subtasks: Subtask[]) => number | undefined
}

export enum CardItemWarningLevel {
  Info,
  Warning,
  Critical,
}

export const Task = ({ task }: { task: OrganizedTask }) => {
  const sharedSubtaskContext = useSharedSubtaskContext()
  return (
    <TaskCard
      taskId={task.id}
      title={task.title}
      subtasks={task.subtasks}
      taskProgress={task.progress}
      sharedSubtaskContext={sharedSubtaskContext}
    />
  )
}
