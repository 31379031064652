import MailOutlineIcon from '@mui/icons-material/MailOutline'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import { Box, IconButton, Paper, Typography } from '@mui/material'
import { MenteeDto, Status, Task } from 'api/dtos'
import { Buffer } from 'buffer'
import { DEFAULT_AVATAR_BASE } from 'common/consts'
import OnABreakIndicator from 'components/OnABreakIndicator'

function countStatusSubtasks(tasks: Task[], status: Status) {
  let count = 0
  tasks.forEach(task => {
    count += task.subtasks.filter(subtask => subtask.status === status).length
  })
  return count
}
function countSubtasksDueWithinAWeek(tasks: Task[]) {
  const today = new Date()
  const oneWeekFromToday = new Date(today)
  oneWeekFromToday.setDate(today.getDate() + 7)

  let count = 0
  tasks.forEach(task => {
    count += task.subtasks.filter(subtask => {
      const deadline = new Date(subtask.deadline)
      return subtask.status === Status.INPROGRESS && deadline > today && deadline <= oneWeekFromToday
    }).length
  })
  return count
}

export const MenteeCardMobile = ({
  mentee,
  handleMenteeSelection,
}: {
  mentee: MenteeDto
  handleMenteeSelection: (mentee: MenteeDto) => void
}) => {
  let base64String = DEFAULT_AVATAR_BASE

  if (mentee.profilePictureBytea != null) {
    base64String = Buffer.from(mentee.profilePictureBytea).toString('base64')
  }
  const menteePhone = `https://wa.me/972${mentee.phoneNumber}?text=`
  const subtasksOnTime = countStatusSubtasks(mentee.tasks, Status.DONE)
  const subtasksInProgress = countSubtasksDueWithinAWeek(mentee.tasks)
  return (
    <Paper
      onClick={() => handleMenteeSelection(mentee)}
      elevation={3}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        margin: '0 0 1em',
        border: '1px solid #00000040',
        borderRadius: '10px',
        padding: '0.8em',
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '100%',
          gap: '0.5em',
        }}
      >
        {mentee.stopAllTimeStamp != null && <OnABreakIndicator />}
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'flex-end',
            height: '100%',
            width: '100%',
          }}
        >
          <IconButton
            onClick={e => {
              e.stopPropagation()
              window.open(menteePhone, '_blank')
            }}
            sx={{ border: '2px solid #FFAB48', borderRadius: '20%', height: '32px', width: '32px', color: 'black' }}
          >
            <WhatsAppIcon />
          </IconButton>
          <IconButton
            onClick={e => {
              e.stopPropagation()
              window.open(`mailto:${mentee.email}`, '_blank')
            }}
            sx={{ border: '2px solid #FFAB48', borderRadius: '20%', height: '32px', width: '32px', color: 'black' }}
          >
            <MailOutlineIcon />
          </IconButton>
        </Box>
      </Box>

      <Box
        sx={{
          flexGrow: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'right',
          direction: 'rtl',
          maxWidth: '50%',
        }}
      >
        <Typography
          variant={'h5'}
          fontSize={'1.2rem'}
          marginBottom={'0.3em'}
          sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {mentee.name}
        </Typography>
        <Typography variant={'body2'} color={'#666666'} fontSize={'0.8rem'}>
          {subtasksOnTime} בוצעו בשבוע האחרון
        </Typography>
        <Typography variant={'body2'} color={'#666666'} fontSize={'0.8rem'}>
          {subtasksInProgress} לביצוע בשבוע הקרוב
        </Typography>
      </Box>

      <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
        <img
          alt={'avatar'}
          src={`data:image/jpeg;base64,${base64String}`}
          style={{ borderRadius: '50%', width: '52px', height: '52px' }}
        />
      </Box>
    </Paper>
  )
}
