import { Button, Card, IconButton } from '@mui/material'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import { Xicon } from 'pages/TasksPage/TaskCreation/icons/Xicon'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { randomReinforcementsAsync } from 'store/reinforcements-slice'
import { listMentorsAsync, selectUserState, useAppDispatch, useAppSelector } from '../store'
import { ShowReinforcementModal } from './ShowReinforcementModal'

export function NeedHelpModal({
  openHelpModal,
  setOpenHelpModal,
}: {
  openHelpModal: boolean
  setOpenHelpModal: Dispatch<SetStateAction<boolean>>
}) {
  const { user, mentors } = useAppSelector(selectUserState) // eslint-disable-line @typescript-eslint/no-unused-vars
  const helpMessage = `היי , אשמח לעזרה 🙏`
  const quickMessageToMentor = `https://wa.me/972${
    mentors?.find(value => value.id === user.mentorId)?.phoneNumber || ''
  }?text=${helpMessage}`

  const [openReinforcementModal, setOpenReinforcementModal] = useState(false)

  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(listMentorsAsync())
  }, [dispatch, user.id])

  const closeHelpModal = () => setOpenHelpModal(false)
  const { t } = useTranslation()

  return (
    <>
      <Modal
        open={openHelpModal}
        keepMounted
        onClose={closeHelpModal}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '0.5em',
          textAlign: 'center',
        }}
      >
        <Card
          dir="rtl"
          style={{
            width: '100%',
            padding: '2em',
            paddingBottom: '2em',
            position: 'relative',
          }}
        >
          <IconButton onClick={closeHelpModal} style={{ position: 'absolute', top: '0.5em', right: '0.5em' }}>
            <Xicon />
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6" style={{ fontWeight: 600, marginBottom: '1em' }}>
              {t('getHelp.WhatReinforcement')}
            </Typography>
            <Typography variant="body1" style={{ marginBottom: '1em' }}>
              {t('getHelp.getHelpText')}
            </Typography>

            <Box
              sx={{
                marginTop: '1em',
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
              }}
            >
              <Button variant="contained" onClick={() => (window.location.href = quickMessageToMentor)}>
                {t('getHelp.ChatWithMentor')}
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  closeHelpModal()
                  setOpenReinforcementModal(true)
                  dispatch(randomReinforcementsAsync(user.id))
                }}
              >
                {t('getHelp.reinforceMe')}
              </Button>
            </Box>
          </Box>
        </Card>
      </Modal>
      {openReinforcementModal ? (
        <ShowReinforcementModal
          openReinforcementModal={openReinforcementModal}
          setOpenReinforcementModal={setOpenReinforcementModal}
        />
      ) : null}
    </>
  )
}
