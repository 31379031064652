import { Box, Typography } from '@mui/material'
import { MenteeDto, Role } from 'api/dtos'
import AddNewMentee from 'components/AddNewMentee'
import { TopMenu } from 'pages/Home/MobileHome/Top/TopMenu'
import { useState } from 'react'
import { selectUserState, useAppSelector } from 'store'
import packageJson from '../../../package.json'
import { MentorDashboardMenteesContent } from './MentorDashboardMenteesContent'
import { MentorDashBoardNotificationCenter } from './MentorDashBoardNotificationCenter'
import { MentorDashBoardSettings } from './MentorDashBoardSettings'
import { MentorDashBoardSideMenu } from './MentorDashBoardSideMenu'
import { MentorDashBoardTopBar } from './MentorDashBoardTopBar'
import MentorSideDrawerContent from './MentorSideDrawerContent'

export enum MentorFragments {
  Summary,
  Notifications,
  Settings,
  InviteMentee,
  PendingInvites,
}

export const MentorDashboard = () => {
  const isMobile = useAppSelector(state => state.layout.isMobileView)
  const [addNewMenteeModal, setAddNewMenteeModal] = useState(false)
  const [mentorNotificationModal, setMentorNotificationModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(MentorFragments.Summary)
  const { pendingInvite } = useAppSelector(selectUserState)
  const [selectedMentee, setSelectedMentee] = useState<MenteeDto | undefined>(undefined)
  const { user } = useAppSelector(selectUserState)
  const [selectedTab, setSelectedTab] = useState<'mentees' | 'invitations'>('mentees')

  const handleMenteeSelection = (mentee: MenteeDto | undefined) => {
    setSelectedMentee(mentee)
  }

  const renderContent = () => {
    switch (currentPage) {
      case MentorFragments.Summary:
      case MentorFragments.PendingInvites:
        return (
          <MentorDashboardMenteesContent
            setAddNewMenteeModal={setAddNewMenteeModal}
            setCurrentPage={setCurrentPage}
            setSelectedMentee={setSelectedMentee}
            selectedMentee={selectedMentee}
            handleMenteeSelection={handleMenteeSelection}
            pendingInvite={pendingInvite}
            user={user}
            currentPage={currentPage}
            isMobile={isMobile}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        )
      case MentorFragments.Notifications:
        return (
          <MentorDashBoardNotificationCenter
            mentorNotificationModal={mentorNotificationModal}
            setMentorNotificationModal={setMentorNotificationModal}
            setCurrentPage={setCurrentPage}
          />
        )
      case MentorFragments.Settings:
        return <MentorDashBoardSettings />
      case MentorFragments.InviteMentee:
        return <AddNewMentee AddNewMenteeModalOpen={addNewMenteeModal} setAddNewMenteeModal={setAddNewMenteeModal} />
      default:
        return null
    }
  }

  const topMenu = isMobile ? (
    <TopMenu
      setCurrentPage={setCurrentPage}
      setMentorNotificationModal={setMentorNotificationModal}
      userRole={Role.Mentor}
      setSelectedTab={setSelectedTab}
    >
      <MentorSideDrawerContent
        setCurrentPage={setCurrentPage}
        setAddNewMenteeModalOpen={setAddNewMenteeModal}
        setSelectedTab={setSelectedTab}
      />
    </TopMenu>
  ) : null

  const mentorDashoardTopBar = isMobile ? null : (
    <MentorDashBoardTopBar
      setCurrentPage={setCurrentPage}
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        flexGrow: 1,
      }}
    />
  )

  const mentorSideMenu = isMobile ? null : (
    <MentorDashBoardSideMenu
      setCurrentPage={setCurrentPage}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
        justifyContent: 'center',
        padding: '1em',
        flexGrow: 1,
        height: 'auto',
        minWidth: 'fit-content',
      }}
    />
  )

  const addNewMenteePage =
    isMobile && addNewMenteeModal ? (
      <AddNewMentee AddNewMenteeModalOpen={addNewMenteeModal} setAddNewMenteeModal={setAddNewMenteeModal} />
    ) : null

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100dwh',
        height: '100dvh',
      }}
    >
      {topMenu}

      {mentorDashoardTopBar}

      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'start',
          alignItems: 'top',
          flexGrow: 25,
          height: '100%',
          padding: isMobile ? '0em' : '2em 0 0 0',
        }}
      >
        {renderContent()}
        {addNewMenteePage}
        {mentorSideMenu}
      </Box>
      {!isMobile && (
        <Typography variant={'body1'} style={{ alignSelf: 'flex-end', padding: '0.5em' }}>
          {'גרסה ' + packageJson.version}
        </Typography>
      )}
    </Box>
  )
}
