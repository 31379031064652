export const paths = {
  LOGIN: '/login',
  HOME: '/home',
  TASKS: '/tasks',
  SUBTASKS: '/subtasks',
  PROFILE: '/profile',
  QUESTIONS: '/questions',
  QUESTIONNAIRE_EFFICIENCY: '/questionnaire_efficiency',
  STORIES: '/stories',
  ACHIEVEMENTS: '/users_achievements',
  MOBILE_HOME: '/mobilehome',
  MENTOR_HOME: '/mentor',
  ADMIN_PANEL: '/admin_panel',
  FORBIDDEN: '/forbidden',
  ONBOARDING_OTP: '/onboarding_otp',
  ONBOARDING_QUESTIONS: '/onboarding_questions',
  REINFORCEMENTS: '/reinforcements',
  AddLinkToHomePage: '/AddLinkToHomePage',
} as const
