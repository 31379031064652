import { CreateQuestionRequest, Question, UpdateQuestionRequest } from '../dtos'
import { axiosInstance } from '../network'
import { ApiResponse } from '../types'
import { RequestConfig } from '../types/request-config'

const getQuestion = async (id: number, config?: RequestConfig): Promise<Question> => {
  const axiosResponse = await axiosInstance.get<never, ApiResponse<Question>>('/questions/' + id, config)
  return axiosResponse.data!
}

const listQuestions = async (config?: RequestConfig): Promise<Question[]> => {
  const axiosResponse = await axiosInstance.get<never, ApiResponse<Question[]>>('/questions/list', config)
  return axiosResponse.data!
}

const deleteQuestion = async (id: number, config?: RequestConfig): Promise<Question[]> => {
  const axiosResponse = await axiosInstance.delete<never, ApiResponse<Question[]>>('/questions/' + id, config)
  return axiosResponse.data!
}

const createQuestion = async (
  createQuestionRequest: CreateQuestionRequest,
  config?: RequestConfig,
): Promise<Question[]> => {
  const apiResponse = await axiosInstance.post<CreateQuestionRequest, ApiResponse<Question[]>>(
    '/questions/create',
    createQuestionRequest,
    config,
  )
  return apiResponse.data!
}

const updateQuestion = async (
  updateQuestionRequest: UpdateQuestionRequest,
  config?: RequestConfig,
): Promise<Question> => {
  const axiosResponse = await axiosInstance.put<UpdateQuestionRequest, ApiResponse<Question>>(
    '/questions/' + updateQuestionRequest.id,
    updateQuestionRequest,
    config,
  )
  return axiosResponse.data!
}
export const questionApiRequest = {
  getQuestion,
  listQuestions,
  createQuestion,
  deleteQuestion,
  updateQuestion,
}
