import { CloseOutlined, SaveOutlined } from '@mui/icons-material'
import { IconButton, Typography } from '@mui/material'
import { Buffer } from 'buffer'
import { useState } from 'react'
import Avatar from 'react-avatar-edit'
import { DEFAULT_AVATAR_BASE } from '../../common/consts'
import { selectUserState, updateUserAvatarAsync, useAppDispatch, useAppSelector } from '../../store'

export const AvatarUploader = ({ setEditState }: { setEditState: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const { user } = useAppSelector(selectUserState)
  const dispatch = useAppDispatch()
  const [preview, setPreview] = useState('')
  const [enableButtons, setEnableButtons] = useState(false)

  const onFinishCropClick = () => {
    const base64result = preview.split(',')[1]
    setEnableButtons(false)
    setEditState(false)
    dispatch(
      updateUserAvatarAsync({
        id: user.id,
        profilePictureBytea: base64result,
      }),
    )
  }

  const onExitEditor = () => {
    setEditState(false)
  }

  const onClose = () => {
    setPreview('')
  }

  const onCrop = (preview: any) => {
    setPreview(preview)
    setEnableButtons(true)
  }

  const MAX_SIZE = 1572864

  const onBeforeFileLoad = (elem: any) => {
    if (elem.target.files && elem.target.files[0]) {
      const file = elem.target.files[0]

      // Check if the file size is greater than 1.5MB
      if (file.size > MAX_SIZE) {
        const reader = new FileReader()
        reader.onload = (e: any) => {
          const img = new Image()
          img.src = e.target.result
          img.onload = () => {
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d', { willReadFrequently: true })
            const MAX_WIDTH = 800
            const MAX_HEIGHT = 800
            let width = img.width
            let height = img.height

            if (width > height) {
              if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width
                width = MAX_WIDTH
              }
            } else {
              if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height
                height = MAX_HEIGHT
              }
            }

            canvas.width = width
            canvas.height = height
            ctx?.drawImage(img, 0, 0, width, height)

            const base64Image = canvas.toDataURL('image/jpeg', 0.8)
            setPreview(base64Image)
          }
        }
        reader.readAsDataURL(file)
      } else {
        // If the file size is within the limit, proceed as usual
        const reader = new FileReader()
        reader.onload = (e: any) => {
          setPreview(e.target.result)
        }
        reader.readAsDataURL(file)
      }
    }
  }

  let base64String = DEFAULT_AVATAR_BASE
  const AVATAR_QUALITY = 0.8

  if (user.profilePictureBytea != null) {
    base64String = Buffer.from(user.profilePictureBytea.data).toString('base64')
  }

  return (
    <div style={{ marginBottom: '2rem', position: 'relative' }}>
      <Avatar
        width={350}
        height={295}
        onCrop={onCrop}
        onClose={onClose}
        onBeforeFileLoad={onBeforeFileLoad}
        cropColor={'#434343'}
        lineWidth={6}
        label={'בחרו תמונת פרופיל'}
        src={`data:image/jpeg;base64,${base64String}`}
        exportQuality={AVATAR_QUALITY}
        exportMimeType="image/jpeg"
      />
      <div style={{ position: 'absolute', top: '0.7em', left: '2.3em', display: 'flex', alignItems: 'center' }}>
        <Typography variant="body2" color={'white'}>
          מחיקה
        </Typography>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem', marginTop: '0.5rem' }}>
        <IconButton onClick={onExitEditor}>
          <Typography variant={'body2'}>ביטול</Typography>
          <CloseOutlined />
        </IconButton>
        <IconButton disabled={!enableButtons} onClick={onFinishCropClick}>
          <Typography variant={'body2'}>שמירה</Typography>
          <SaveOutlined />
        </IconButton>
      </div>
    </div>
  )
}
export default AvatarUploader
