import { CreateTaskRequest, Task, UpdateSubtaskRequest, UpdateTaskRequest } from '../dtos'
import { axiosInstance } from '../network'
import { ApiResponse } from '../types'
import { RequestConfig } from '../types/request-config'

const getTask = async (id: number, config?: RequestConfig): Promise<Task> => {
  const axiosResponse = await axiosInstance.get<never, ApiResponse<Task>>('/tasks/' + id, config)
  return axiosResponse.data!
}

const listTasks = async (userId: number, config?: RequestConfig): Promise<Task[]> => {
  const axiosResponse = await axiosInstance.get<never, ApiResponse<Task[]>>('/tasks/list/' + userId, config)
  return axiosResponse.data!
}

const deleteTask = async (id: number, config?: RequestConfig): Promise<Task[]> => {
  const axiosResponse = await axiosInstance.delete<never, ApiResponse<Task[]>>('/tasks/' + id, config)
  return axiosResponse.data!
}

const undeleteTask = async (id: number, config?: RequestConfig): Promise<Task[]> => {
  const axiosResponse = await axiosInstance.put<never, ApiResponse<Task[]>>('/tasks/undelete/' + id, config)
  return axiosResponse.data!
}

const createTask = async (createTaskRequest: CreateTaskRequest, config?: RequestConfig): Promise<Task[]> => {
  const apiResponse = await axiosInstance.post<CreateTaskRequest, ApiResponse<Task[]>>(
    '/tasks/create',
    createTaskRequest,
    config,
  )
  return apiResponse.data!
}

const updateTask = async (updateTaskRequest: UpdateTaskRequest, config?: RequestConfig): Promise<Task> => {
  const axiosResponse = await axiosInstance.put<UpdateTaskRequest, ApiResponse<Task>>(
    '/tasks/' + updateTaskRequest.id,
    updateTaskRequest,
    config,
  )
  return axiosResponse.data!
}

const closeTask = async (updateTaskRequest: UpdateSubtaskRequest, config?: RequestConfig): Promise<Task> => {
  const axiosResponse = await axiosInstance.put<UpdateSubtaskRequest, ApiResponse<Task>>(
    '/subtasks/done/' + updateTaskRequest.id,
    updateTaskRequest,
    config,
  )
  return axiosResponse.data!
}

const listTasksOfUser = async (id: number, config?: RequestConfig): Promise<Task[]> => {
  const axiosResponse = await axiosInstance.get<never, ApiResponse<Task[]>>('/tasks/list/' + id, config)
  return axiosResponse.data!
}

const listTasksOfUsersOfMentorAsync = async (mentorId: number, config?: RequestConfig): Promise<Task[]> => {
  const axiosResponse = await axiosInstance.get<never, ApiResponse<Task[]>>('/tasks/listMentor/' + mentorId, config)
  return axiosResponse.data!
}

export const taskApiRequest = {
  getTask,
  listTasks,
  createTask,
  deleteTask,
  updateTask,
  undeleteTask,
  closeTask,
  listTasksOfUser,
  listTasksOfUsersOfMentorAsync,
}
