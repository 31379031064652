import NotificationsIcon from '@mui/icons-material/Notifications'
import { Badge, Box, Button, Typography } from '@mui/material'
import { Buffer } from 'buffer'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { NotificationStatus } from '../../api/dtos'
import { DEFAULT_AVATAR_BASE } from '../../common/consts'
import logo from '../../icons/AniAlZeLogo.png'
import {
  listNotificationsAsync,
  selectNotificationListState,
  selectUserState,
  useAppDispatch,
  useAppSelector,
} from '../../store'
import { MentorFragments } from './MentorDashboard'

export const MentorDashBoardTopBar = ({
  style,
  setCurrentPage,
}: {
  style: React.CSSProperties
  setCurrentPage: Dispatch<SetStateAction<number>>
}) => {
  const { user } = useAppSelector(selectUserState)
  const { notifications } = useAppSelector(selectNotificationListState)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(listNotificationsAsync())
  }, [dispatch, user.id])

  let base64String = DEFAULT_AVATAR_BASE

  if (user.profilePictureBytea != null) {
    base64String = Buffer.from(user.profilePictureBytea.data).toString('base64')
  }

  return (
    <Box style={style}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          flexGrow: 1,
        }}
      >
        <img alt={'avatar'} src={`data:image/jpeg;base64,${base64String}`} width={75} height={75} />
        <Button color={'inherit'} onClick={() => setCurrentPage(MentorFragments.Notifications)}>
          <Badge
            badgeContent={
              notifications?.filter(notification => NotificationStatus.Created === notification.status).length
            }
            color="primary"
            max={99}
          >
            <NotificationsIcon fontSize={'large'} />
          </Badge>
        </Button>
      </Box>

      <Box
        sx={{
          direction: 'rtl',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography variant={'h4'}>ברוך הבא,&nbsp;</Typography>
        <Typography variant={'h4'} sx={{ fontWeight: 600 }}>
          {user.name}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1,
        }}
      >
        <img src={logo} alt={logo} style={{ width: '8em', height: '8em' }} />
      </Box>
    </Box>
  )
}
