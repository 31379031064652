import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiRequests } from '../api'
import { RequestStatus } from '../common/types'
import { config } from '../config'
import { storageUtil } from '../utils'
import { RootState } from './store'
import { getUserAsync } from './user-slice'

export interface AuthState {
  error?: string
  status: RequestStatus
}

const initialState: AuthState = {
  status: RequestStatus.Idle,
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const loginAsync = createAsyncThunk(
  'auth/login',
  async (
    {
      email,
      password,
    }: {
      email: string
      password: string
    },
    { dispatch },
  ) => {
    const response = await apiRequests.login({ email, password })
    // Persist the token we received for future requests
    storageUtil.set(config.storage.TOKEN_KEY, response.token)
    storageUtil.set(config.storage.REFRESH_TOKEN_KEY, response.refreshToken)
    dispatch(getUserAsync())
  },
)

export const googleLoginAsync = createAsyncThunk(
  'auth/gooogle-login',
  async (
    {
      idToken,
    }: {
      idToken: string | undefined
    },
    { dispatch },
  ) => {
    const response = await apiRequests.googleLogin({ idToken })
    // Persist the token we received for future requests
    storageUtil.set(config.storage.TOKEN_KEY, response.token)
    storageUtil.set(config.storage.REFRESH_TOKEN_KEY, response.refreshToken)
    dispatch(getUserAsync())
  },
)

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    logout: state => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.status = RequestStatus.Idle
      state.error = undefined
      storageUtil.clear()
      window.location.href = '/'
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: builder => {
    builder
      // loginAsync
      .addCase(loginAsync.pending, state => {
        state.status = RequestStatus.Loading
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.error = action.error.message
        state.status = RequestStatus.Failed
      })
      .addCase(googleLoginAsync.pending, state => {
        state.status = RequestStatus.Loading
      })
      .addCase(googleLoginAsync.rejected, (state, action) => {
        state.error = action.error.message
        state.status = RequestStatus.Failed
      })
  },
})

export const { logout } = authSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAuthState = (state: RootState) => state.auth

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState())
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount))
//     }
//   }

export const authReducer = authSlice.reducer
