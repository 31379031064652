import { Button, FormControlLabel, FormGroup, Grid, TextField } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import { ReinforcementType } from 'api/dtos'
import { useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { createReinforcementAsync } from 'store/reinforcements-slice'
import { selectUserState, useAppDispatch, useAppSelector } from '../../store'

const ReinforcementsSentenceForm = () => {
  const { user } = useAppSelector(selectUserState)
  const dispatch = useAppDispatch()
  const { handleSubmit, control } = useForm<{
    sentence: string
  }>()
  const onSubmit = useCallback(
    (data: { sentence: string }) => {
      dispatch(
        createReinforcementAsync({
          userId: user.id,
          text: data.sentence,
          type: ReinforcementType.Text,
        }),
      )
    },
    [dispatch, user.id],
  )

  return (
    <Grid container>
      <Grid item>
        <FormGroup>
          <FormControlLabel control={<Checkbox disabled />} label={''} />
        </FormGroup>
      </Grid>
      <Grid item paddingRight={'1em'}>
        <Controller
          name={`sentence`}
          control={control}
          defaultValue=""
          render={({ field, fieldState: { error } }) => (
            <TextField
              variant="standard"
              {...field}
              required
              fullWidth
              autoFocus
              margin="none"
              error={!!error}
              helperText={error ? error.message : null}
              type="text"
            />
          )}
          rules={{ required: { value: true, message: 'required' } }}
        />
      </Grid>
      <Grid item paddingRight={'1em'}>
        <Button onClick={handleSubmit(onSubmit)}>{'הוסף'}</Button>
      </Grid>
    </Grid>
  )
}

export default ReinforcementsSentenceForm
