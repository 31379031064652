import { Button, Grid, IconButton, TextField, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useContext, useEffect } from 'react'
// @ts-ignore
import DeleteIcon from '@mui/icons-material/Delete'
import { DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { heIL } from '@mui/x-date-pickers/locales'
import { PartialCreateSubtaskRequest } from 'api/dtos'
import 'react-datepicker/dist/react-datepicker.css'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { difficultyToColor } from '../Task/SubtaskSection'
import { SharedSubtaskContext } from '../UseSharedSubtaskContext'

export const SubtaskFormContents = ({ i, uuid, onDelete }: { i: number; uuid: string; onDelete: () => void }) => {
  const { t } = useTranslation()
  const { taskControl, setSubTasksToCreate, subtasksToCreate, setStepTextFieldValues, stepTextFieldValues } =
    useContext(SharedSubtaskContext)

  const handleDifficultyChange = (value: 'EASY' | 'MEDIUM' | 'HARD') => {
    const updatedSubtasks = [...subtasksToCreate]
    if (updatedSubtasks[i] && (value === 'EASY' || value === 'MEDIUM' || value === 'HARD')) {
      updatedSubtasks[i].difficulty = value
    }
    setSubTasksToCreate!(updatedSubtasks)
  }

  const areAllRequiredFieldsFilled = () => {
    return subtasksToCreate[i].description && subtasksToCreate[i].deadline && subtasksToCreate[i].difficulty
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      // Submit the form only when all required fields are filled
      if (!areAllRequiredFieldsFilled()) {
        e.preventDefault()
      }
    }
  }

  useEffect(() => {
    if (subtasksToCreate[i] && subtasksToCreate[i].deadline === undefined) {
      setSubTasksToCreate!(prev => {
        const updatedSubtasks = [...prev]
        updatedSubtasks[i] = { ...updatedSubtasks[i], deadline: null }
        return updatedSubtasks
      })
    }
  }, [i, setSubTasksToCreate, subtasksToCreate])

  return (
    <Grid
      container
      direction="column"
      spacing="2"
      alignItems="center"
      key={i}
      padding="1em 0"
      style={{
        direction: 'rtl',
        flexWrap: 'nowrap',
        marginTop: '-1em',
      }}
    >
      <Grid item xs={12}>
        <Controller
          name={`subtasks.${i}.description`}
          control={taskControl!.control}
          defaultValue=""
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              sx={{
                textAlign: 'right',
                direction: 'rtl',
                border: error ? '1px solid #B00020' : '1px solid #ccc',
                borderRadius: '4px',
                padding: '0.5em',
                width: '80vw',
              }}
              placeholder="שם הצעד"
              value={stepTextFieldValues[i] || ''}
              required
              fullWidth
              autoComplete="description"
              autoFocus
              margin="normal"
              error={!!error} // show error if there is an error or the field is empty
              helperText={field.value ? null : t('auth.nameIsRequired')} // show helper text only if the field is empty
              type="text"
              InputProps={{
                disableUnderline: true,
              }}
              variant="standard"
              onKeyDown={handleKeyDown}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                field.onChange(e)
                setStepTextFieldValues!(prev => ({
                  ...prev,
                  [i]: e.target.value,
                }))
                setSubTasksToCreate!(prev => {
                  const updatedSubtasks = [...prev]
                  updatedSubtasks[i].description = e.target.value
                  return updatedSubtasks
                })
              }}
            />
          )}
          rules={{ required: { value: true, message: t('auth.nameIsRequired') } }}
        />
      </Grid>
      <Grid item xs={12}>
        <Box>
          <Controller
            name={`subtasks.${i}.deadline`}
            control={taskControl!.control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <LocalizationProvider
                  localeText={heIL.components.MuiLocalizationProvider.defaultProps.localeText}
                  dateAdapter={AdapterDateFns}
                >
                  <DatePicker
                    label="בחר תאריך"
                    disablePast
                    format="dd/MM/yyyy"
                    defaultValue={null}
                    onChange={(date: Date | null) => {
                      onChange(date)
                      setSubTasksToCreate!((prev: PartialCreateSubtaskRequest[]) => {
                        const updatedSubtasks = [...prev]
                        updatedSubtasks[i] = { ...updatedSubtasks[i], deadline: date }
                        return updatedSubtasks
                      })
                    }}
                    slotProps={{
                      textField: {
                        helperText: error ? t('auth.dateRequired') : null,
                        error: !!error,
                        inputProps: {
                          'aria-label': 'בחר תאריך',
                        },
                      },
                    }}
                    sx={{
                      width: '80vw',
                      mt: '0.5em',
                      mb: '0.5em',
                      '& .MuiInputLabel-root': {
                        '&[data-shrink="false"]': {
                          right: 0,
                          marginRight: '1.5em',
                        },
                      },
                    }}
                  />
                </LocalizationProvider>
              )
            }}
            rules={{ required: { value: true, message: t('auth.dateRequired') } }}
          />
        </Box>
      </Grid>
      <Grid item marginTop="0.3em" xs={12} key={i}>
        <Typography variant="body1" sx={{ textAlign: 'right', mb: '0.5em' }}>
          דרגת קושי
        </Typography>
        <Controller
          name={`subtasks.${i}.difficulty`}
          control={taskControl!.control}
          render={({ field, fieldState: { error } }) => (
            <>
              <Box display="flex" justifyContent="space-between" sx={{ width: '80vw' }}>
                {['EASY', 'MEDIUM', 'HARD'].map(difficulty => (
                  <Button
                    key={difficulty + field.value}
                    variant={field.value === difficulty ? 'contained' : 'outlined'}
                    color="primary"
                    sx={{
                      backgroundColor: field.value === difficulty ? difficultyToColor[difficulty] : 'transparent',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      margin: '0 5px',
                      padding: '0.5em',
                      width: '25%',
                    }}
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      e.stopPropagation()
                      field.onChange(difficulty)
                      handleDifficultyChange(difficulty as 'EASY' | 'MEDIUM' | 'HARD')
                    }}
                  >
                    {difficulty === 'EASY' && 'קל'}
                    {difficulty === 'MEDIUM' && 'בינוני'}
                    {difficulty === 'HARD' && 'קשה'}
                  </Button>
                ))}
              </Box>
              {!field.value && !error && (
                <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
                  {t('auth.difficultyRequired')}
                </Typography>
              )}
              {error && (
                <Typography variant="caption" color="error" sx={{ mt: '0.5em' }}>
                  {error.message}
                </Typography>
              )}
            </>
          )}
          rules={{ required: { value: true, message: t('auth.difficultyRequired') } }}
        />
      </Grid>
      <Grid item xs={12} sx={{ mt: '1em', mb: '1em' }}></Grid>
      <Grid item xs={12}>
        <Tooltip title="מחיקת משימה" arrow>
          <IconButton onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  )
}
