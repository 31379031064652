import { Box, Button, MenuItem, Paper, Typography } from '@mui/material'
import { Xicon } from 'pages/TasksPage/TaskCreation/icons/Xicon'
import { NotificationStatus } from '../../api/dtos'
import { updateNotificationAsync, useAppDispatch } from '../../store'
import { NotificationStatusIcon } from './NotificationStatusIcon'

export const NotificationItem = ({
  status,
  message,
  key,
  notifictionId,
  updated,
}: {
  status: string
  message: string
  key: number
  notifictionId: string
  updated: Date
}) => {
  const dispatch = useAppDispatch()
  return (
    <MenuItem
      key={key}
      onMouseOver={() => {
        if (status === NotificationStatus.Created)
          dispatch(updateNotificationAsync({ notificationIds: [notifictionId], status: NotificationStatus.Seen }))
      }}
    >
      <Paper
        elevation={3}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'right',
          width: '100%',
          padding: '1em',
          borderRadius: '10px',
          direction: 'rtl',
        }}
      >
        <Box
          sx={{
            flexGrow: 5,
            flexBasis: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            direction: 'rtl',
          }}
        >
          <NotificationStatusIcon status={status} />
          <Typography variant={'h6'}>{message}</Typography>
          <Typography variant={'body1'}>{new Date(updated).toLocaleDateString('en-GB')}</Typography>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            flexBasis: 0,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Button
            variant={'contained'}
            onClick={() => {
              dispatch(
                updateNotificationAsync({ notificationIds: [notifictionId], status: NotificationStatus.Acknowledged }),
              )
            }}
          >
            <Xicon />
          </Button>
        </Box>
      </Paper>
    </MenuItem>
  )
}
