import { Grid } from '@mui/material'
import { Role } from 'api/dtos'
import { SetState } from 'common/types/types'
import MenteeSideDrawerContent from '../MenteeSideDrawerContent'
import { ViewMode } from '../MobileHome'
import { QuickAccessBar } from '../QuickAccessBar/QuickAccessBar'
import { TopMenu } from './TopMenu'

export enum Page {
  Tasks,
  Successes,
}

export const MenteeTopBar = ({
  currentPage,
  setCurrentPage,
  userRole,
  setViewMode,
  viewMode,
}: {
  currentPage: Page
  setCurrentPage: SetState<Page>
  userRole: Role
  setViewMode: SetState<ViewMode>
  viewMode: ViewMode
}) => {
  return (
    <Grid container>
      <TopMenu userRole={userRole}>
        <MenteeSideDrawerContent />
      </TopMenu>
      <Grid item xs={12}>
        <QuickAccessBar
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setViewMode={setViewMode}
          viewMode={viewMode}
        />
      </Grid>
    </Grid>
  )
}
