import PauseCircleIcon from '@mui/icons-material/PauseCircle'
import { Box, Typography } from '@mui/material'

export default function OnABreakIndicator() {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '0.1em',
        backgroundColor: '#FFE8CC',
        p: '0.2em 0.3em',
        borderRadius: '5px',
      }}
    >
      <Typography variant="body1" sx={{ color: '#995300' }}>
        בהפסקה
      </Typography>
      <PauseCircleIcon sx={{ color: '#995300' }} />
    </Box>
  )
}
